var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { forwardRef, useImperativeHandle, useState } from 'react';
import { Popover } from 'react-bootstrap';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import styled from 'styled-components';
var StyledPopover = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  margin-top: 0;\n  max-width: ", ";\n  background-color: ", ";\n  color: ", "!important;\n  margin-left: ", ";\n  transform: inherit;\n  @media (max-width: 480px) {\n    margin-left: 0;\n  }\n  border-radius: 1px;\n  * {\n    font-family: Roboto, Helvetica Neue, Helvetica, Arial, sans-serif;\n  }\n"], ["\n  margin-top: 0;\n  max-width: ", ";\n  background-color: ", ";\n  color: ", "!important;\n  margin-left: ", ";\n  transform: inherit;\n  @media (max-width: 480px) {\n    margin-left: 0;\n  }\n  border-radius: 1px;\n  * {\n    font-family: Roboto, Helvetica Neue, Helvetica, Arial, sans-serif;\n  }\n"])), function (props) { return (props.maxWidth ? props.maxWidth : '272px'); }, function (props) { return (props.background ? props.background : 'white'); }, function (props) { return (props.textColor ? props.textColor : 'black'); }, function (props) { return (props.isReviewPopover ? '3%' : '0'); });
var StyledArrow = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  left: 50%;\n  transform: translateX(-50%);\n  margin: 0 !important;\n  @media (max-width: 767px) {\n    left: ", ";\n  }\n  &::after {\n    border-bottom-color: ", "!important;\n  }\n"], ["\n  left: 50%;\n  transform: translateX(-50%);\n  margin: 0 !important;\n  @media (max-width: 767px) {\n    left: ", ";\n  }\n  &::after {\n    border-bottom-color: ", "!important;\n  }\n"])), function (props) { return (props.isProtectionPopover ? '50%' : '70px'); }, function (props) { return (props.background ? props.background : 'white'); });
var StyledBody = styled(Popover.Body)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  font-size: 0.775rem;\n  font-weight: 400;\n  max-width: ", "!important;\n  line-height: 1.5;\n  text-align: start;\n  word-break: normal;\n  line-break: auto;\n  color: ", "!important;\n"], ["\n  font-size: 0.775rem;\n  font-weight: 400;\n  max-width: ", "!important;\n  line-height: 1.5;\n  text-align: start;\n  word-break: normal;\n  line-break: auto;\n  color: ", "!important;\n"])), function (props) { return (props.isSubscription ? '100%' : '276px'); }, function (props) { return (props.textColor ? props.textColor : 'black'); });
var StyledAnchor = styled.span(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  cursor: pointer;\n"], ["\n  cursor: pointer;\n"])));
var CustomPopover = function (props, ref) {
    var _a = __read(useState(false), 2), show = _a[0], setShow = _a[1];
    useImperativeHandle(ref, function () { return ({
        hide: function () {
            setShow(false);
        }
    }); });
    var children = props.children, _b = props.trigger, trigger = _b === void 0 ? 'click' : _b, _c = props.dismiss, dismiss = _c === void 0 ? 'blur' : _c, _d = props.placement, placement = _d === void 0 ? 'bottom' : _d, _e = props.data, data = _e === void 0 ? '' : _e, _f = props.maxWidth, maxWidth = _f === void 0 ? '272px' : _f, _g = props.background, background = _g === void 0 ? 'white' : _g, _h = props.rootClose, rootClose = _h === void 0 ? true : _h, _j = props.isSubscription, isSubscription = _j === void 0 ? false : _j, _k = props.isProtectionPopover, isProtectionPopover = _k === void 0 ? false : _k, _l = props.isReviewPopover, isReviewPopover = _l === void 0 ? false : _l, _m = props.textColor, textColor = _m === void 0 ? 'black' : _m;
    return (React.createElement(OverlayTrigger, { trigger: 'click', placement: placement, show: show, rootClose: rootClose, onToggle: function (nextShow) {
            if (nextShow === false) {
                setShow(false);
            }
        }, 
        // rootCloseEvent="click"
        overlay: React.createElement(StyledPopover, { maxWidth: maxWidth, textColor: textColor, background: background, isReviewPopover: isReviewPopover, className: "popover bs-popover-bottom", isSubscription: isSubscription, id: "popover-positioned-bottom" },
            React.createElement(StyledArrow, { className: "arrow", background: background, isProtectionPopover: isProtectionPopover, textColor: textColor }),
            React.createElement(StyledBody, { className: "popover-body", textColor: textColor, isSubscription: isSubscription, onMouseLeave: function () {
                    if (dismiss === 'blur')
                        setShow(function (_prev) { return false; });
                }, onBlur: function () {
                    if (dismiss === 'blur')
                        setShow(function (_prev) { return false; });
                } }, data)) }, trigger === 'hover' ? (React.createElement(StyledAnchor, { onMouseEnter: function () { return setShow(function (_prev) { return true; }); }, onMouseOver: function () { return setShow(function (_prev) { return true; }); }, onFocus: function () { return setShow(function (_prev) { return true; }); } }, children)) : (React.createElement(StyledAnchor, { role: "button", onClick: function () { return setShow(function (prev) { return !prev; }); }, tabIndex: 0 }, children))));
};
export default forwardRef(CustomPopover);
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
