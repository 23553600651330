var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Rating } from 'react-simple-star-rating';
import { forceVisible } from 'react-lazyload';
import { environmentConst } from '../../config/environments/constants';
import { getReviewFromPowerReview, getRatingsFromPowerReview } from '../../services/pdpClient';
import useMediaQuery from '../../hooks/useMediaQuery';
import CustomPopover from '../shared/CustomPopover';
import { ReviewDropdown } from '../MainLayout/ReviewDropdown';
var StyledModalWrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  padding-right: 22px;\n  display: flex;\n  align-items: center;\n  position: relative;\n  @media (max-width: 767px) {\n    padding-left: 5px;\n  }\n  .cls-reviewNumber {\n    font-size: 12px;\n    color: #303030;\n  }\n"], ["\n  padding-right: 22px;\n  display: flex;\n  align-items: center;\n  position: relative;\n  @media (max-width: 767px) {\n    padding-left: 5px;\n  }\n  .cls-reviewNumber {\n    font-size: 12px;\n    color: #303030;\n  }\n"])));
var StyledRatingWrapper = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  padding-right: 22px;\n  display: flex;\n  align-items: center;\n  position: relative;\n  @media (max-width: 767px) {\n    padding-left: 5px;\n  }\n  &:after {\n    content: '\f107';\n    position: absolute;\n    right: 12px;\n    top: 6px;\n    font-family: fontAwesome;\n    font-size: 12px;\n  }\n  &.link {\n    &:after {\n      top: 11px !important;\n    }\n  }\n\n  .cls-reviewNumber {\n    font-size: 12px;\n    color: #303030;\n  }\n  .empty-icons,\n  .filled-icons {\n    .star-svg:first-child {\n      margin-left: 0;\n    }\n  }\n"], ["\n  padding-right: 22px;\n  display: flex;\n  align-items: center;\n  position: relative;\n  @media (max-width: 767px) {\n    padding-left: 5px;\n  }\n  &:after {\n    content: '\\f107';\n    position: absolute;\n    right: 12px;\n    top: 6px;\n    font-family: fontAwesome;\n    font-size: 12px;\n  }\n  &.link {\n    &:after {\n      top: 11px !important;\n    }\n  }\n\n  .cls-reviewNumber {\n    font-size: 12px;\n    color: #303030;\n  }\n  .empty-icons,\n  .filled-icons {\n    .star-svg:first-child {\n      margin-left: 0;\n    }\n  }\n"])));
var RevStarWrap = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  @media (max-width: 767px) {\n    justify-content: space-between;\n  }\n"], ["\n  @media (max-width: 767px) {\n    justify-content: space-between;\n  }\n"])));
var StyledQnAWrapper = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  font-size: 12px;\n  padding-right: 22px;\n  position: relative;\n  color: #303030;\n  margin-top: 3px;\n  &:after {\n    content: '\f107';\n    position: absolute;\n    right: 11px;\n    top: 1px;\n    font-family: fontAwesome;\n    font-size: 12px;\n  }\n  @media (max-width: 767px) {\n    padding-right: 15px;\n    &:after {\n      right: 0;\n    }\n  }\n  a {\n    color: #303030;\n  }\n"], ["\n  font-size: 12px;\n  padding-right: 22px;\n  position: relative;\n  color: #303030;\n  margin-top: 3px;\n  &:after {\n    content: '\\f107';\n    position: absolute;\n    right: 11px;\n    top: 1px;\n    font-family: fontAwesome;\n    font-size: 12px;\n  }\n  @media (max-width: 767px) {\n    padding-right: 15px;\n    &:after {\n      right: 0;\n    }\n  }\n  a {\n    color: #303030;\n  }\n"])));
var StyledRating = styled(Rating)(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  svg {\n    margin: 0 2px;\n  }\n"], ["\n  svg {\n    margin: 0 2px;\n  }\n"])));
var StyledWriteReviewLink = styled.button.attrs(function () { return ({
    className: 'btn btn-link'
}); })(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  color: #303030;\n  text-decoration: underline;\n  font-size: 12px;\n  padding-left: 0;\n  padding-right: 0;\n  padding-top: 0;\n  outline: none;\n  &:hover {\n    color: #303030;\n  }\n  &:focus {\n    box-shadow: none;\n  }\n"], ["\n  color: #303030;\n  text-decoration: underline;\n  font-size: 12px;\n  padding-left: 0;\n  padding-right: 0;\n  padding-top: 0;\n  outline: none;\n  &:hover {\n    color: #303030;\n  }\n  &:focus {\n    box-shadow: none;\n  }\n"])));
export var ReviewStar = function (_a) {
    var _b, _c, _d, _e, _f, _g, _h, _j, _k;
    var productDetails = _a.productDetails, currentVariant = _a.currentVariant;
    var avgOvrlRating = (_b = productDetails === null || productDetails === void 0 ? void 0 : productDetails.bjsrating) === null || _b === void 0 ? void 0 : _b.avgOvrlRating;
    var avgRatings = Array.from({ length: avgOvrlRating }, function (_, i) { return i + 1; });
    var fullReviews = (_c = productDetails === null || productDetails === void 0 ? void 0 : productDetails.bjsrating) === null || _c === void 0 ? void 0 : _c.fullReviews;
    var itemId = (currentVariant === null || currentVariant === void 0 ? void 0 : currentVariant.itemId)
        ? currentVariant === null || currentVariant === void 0 ? void 0 : currentVariant.itemId
        : (_e = (_d = productDetails === null || productDetails === void 0 ? void 0 : productDetails.bjsitems) === null || _d === void 0 ? void 0 : _d[0]) === null || _e === void 0 ? void 0 : _e.itemId;
    var displayPrice = ((_g = (_f = productDetails === null || productDetails === void 0 ? void 0 : productDetails.itemPrices) === null || _f === void 0 ? void 0 : _f[itemId]) === null || _g === void 0 ? void 0 : _g.offerPrice)
        ? (_j = (_h = productDetails === null || productDetails === void 0 ? void 0 : productDetails.itemPrices) === null || _h === void 0 ? void 0 : _h[itemId]) === null || _j === void 0 ? void 0 : _j.offerPrice
        : null;
    // const QACount = productDetails?.bjsrating?.quickReviews;
    var _l = __read(useState(0), 2), QACount = _l[0], setQACount = _l[1];
    var _m = __read(useState([]), 2), StarRating = _m[0], setStarRating = _m[1];
    var matches = useMediaQuery('(min-width: 769px)');
    useEffect(function () {
        if (productDetails === null || productDetails === void 0 ? void 0 : productDetails.partNumber) {
            getReviewFromPowerReview(productDetails === null || productDetails === void 0 ? void 0 : productDetails.partNumber, environmentConst.REVIEW_APIKEY).then(function (res) {
                var _a;
                var Count = (_a = res === null || res === void 0 ? void 0 : res.paging) === null || _a === void 0 ? void 0 : _a.total_results;
                setQACount(Count);
            });
            getRatingsFromPowerReview(productDetails === null || productDetails === void 0 ? void 0 : productDetails.partNumber, environmentConst.REVIEW_APIKEY).then(function (res) {
                var _a, _b, _c;
                var star = (_c = (_b = (_a = res === null || res === void 0 ? void 0 : res.results) === null || _a === void 0 ? void 0 : _a[0]) === null || _b === void 0 ? void 0 : _b.rollup) === null || _c === void 0 ? void 0 : _c.rating_histogram;
                setStarRating(star === null || star === void 0 ? void 0 : star.reverse());
            });
        }
    }, []);
    var variantEntitledItem = (currentVariant &&
        ((_k = productDetails === null || productDetails === void 0 ? void 0 : productDetails.entitledItems) === null || _k === void 0 ? void 0 : _k.find(function (d) { return d.itemID === (currentVariant === null || currentVariant === void 0 ? void 0 : currentVariant.itemId); }))) ||
        null;
    var handleWriteReview = function () {
        var _a, _b;
        var pathArray = (_b = (_a = window === null || window === void 0 ? void 0 : window.location) === null || _a === void 0 ? void 0 : _a.pathname) === null || _b === void 0 ? void 0 : _b.split('/');
        if (Array.isArray(pathArray) && (pathArray === null || pathArray === void 0 ? void 0 : pathArray.length)) {
            var productId = pathArray === null || pathArray === void 0 ? void 0 : pathArray[(pathArray === null || pathArray === void 0 ? void 0 : pathArray.length) - 1];
            var pageId = productDetails === null || productDetails === void 0 ? void 0 : productDetails.partNumber;
            var url = "/writeReviewView?storeId=10201&catalogId=10201&langId=-1&productId=" + productId + "&pr_page_id=" + pageId;
            window.location.href = url;
        }
    };
    var getProductItemNumber = function () {
        var _a, _b, _c, _d;
        var productItemNumber = '';
        if (variantEntitledItem) {
            return variantEntitledItem === null || variantEntitledItem === void 0 ? void 0 : variantEntitledItem.articleId;
        }
        if ((productDetails === null || productDetails === void 0 ? void 0 : productDetails.definingAttributes) && ((_a = productDetails === null || productDetails === void 0 ? void 0 : productDetails.definingAttributes) === null || _a === void 0 ? void 0 : _a.length) > 0) {
            var currentProduct = (_b = productDetails === null || productDetails === void 0 ? void 0 : productDetails.entitledItems) === null || _b === void 0 ? void 0 : _b.find(function (p) { var _a; return p.itemID === ((_a = productDetails === null || productDetails === void 0 ? void 0 : productDetails.itemPrices) === null || _a === void 0 ? void 0 : _a.defaultItemId); });
            if (currentProduct) {
                productItemNumber = currentProduct === null || currentProduct === void 0 ? void 0 : currentProduct.articleId;
            }
        }
        else {
            productItemNumber = ((_d = (_c = productDetails === null || productDetails === void 0 ? void 0 : productDetails.entitledItems) === null || _c === void 0 ? void 0 : _c[0]) === null || _d === void 0 ? void 0 : _d.articleId) || '';
        }
        return productItemNumber;
    };
    var getProductModelNumber = function () {
        var _a, _b, _c, _d;
        var productModelNumber = '';
        if (variantEntitledItem) {
            return variantEntitledItem === null || variantEntitledItem === void 0 ? void 0 : variantEntitledItem.manufacturerPartNumber;
        }
        if ((productDetails === null || productDetails === void 0 ? void 0 : productDetails.definingAttributes) && ((_a = productDetails === null || productDetails === void 0 ? void 0 : productDetails.definingAttributes) === null || _a === void 0 ? void 0 : _a.length) > 0) {
            var currentProduct = (_b = productDetails === null || productDetails === void 0 ? void 0 : productDetails.entitledItems) === null || _b === void 0 ? void 0 : _b.find(function (p) { var _a; return p.itemID === ((_a = productDetails === null || productDetails === void 0 ? void 0 : productDetails.itemPrices) === null || _a === void 0 ? void 0 : _a.defaultItemId); });
            if (currentProduct) {
                productModelNumber = currentProduct === null || currentProduct === void 0 ? void 0 : currentProduct.manufacturerPartNumber;
            }
        }
        else {
            productModelNumber = ((_d = (_c = productDetails === null || productDetails === void 0 ? void 0 : productDetails.entitledItems) === null || _c === void 0 ? void 0 : _c[0]) === null || _d === void 0 ? void 0 : _d.manufacturerPartNumber) || '';
        }
        return productModelNumber;
    };
    var itemPartNumber = getProductItemNumber();
    var modelNumber = getProductModelNumber();
    var scrollToReview = function () {
        var id = 'reviews';
        var yOffset = -80;
        var element = document.getElementById(id);
        if (element) {
            var y = (element === null || element === void 0 ? void 0 : element.getBoundingClientRect().top) + window.pageYOffset + yOffset;
            window.scrollTo({ top: y, behavior: 'smooth' });
        }
        else {
            scrollToVisible(id, yOffset);
        }
    };
    var scrollToVisible = function (id, yOffset) {
        forceVisible();
        var interval = setInterval(function () {
            var element = document.getElementById(id);
            if (element) {
                var y = (element === null || element === void 0 ? void 0 : element.getBoundingClientRect().top) + window.pageYOffset + yOffset;
                window.scrollTo({ top: y, behavior: 'smooth' });
                clearInterval(interval);
            }
        }, 500);
        setTimeout(function () {
            clearInterval(interval);
        }, 5000);
    };
    var scrollToQA = function () {
        var id = 'qa';
        var yOffset = -300;
        var element = document.getElementById(id);
        if (element) {
            var y = (element === null || element === void 0 ? void 0 : element.getBoundingClientRect().top) + window.pageYOffset + yOffset;
            window.scrollTo({ top: y, behavior: 'smooth' });
        }
        else {
            scrollToVisible(id, yOffset);
        }
    };
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: "row" },
            React.createElement("div", { className: "col d-flex align-items-center" },
                React.createElement(StyledModalWrapper, null,
                    React.createElement("div", { className: "cls-item-number" },
                        itemPartNumber && (React.createElement("span", { "auto-data": "product_ItemId", className: "cls-item", "data-testid": true },
                            ' ',
                            "Item: ",
                            itemPartNumber,
                            ' ')),
                        Number(displayPrice) > 400 && modelNumber && (React.createElement("span", { "auto-data": "product_modelNo", className: "cls-delimiter" },
                            ' ',
                            "Model: ",
                            modelNumber,
                            ' ')))))),
        React.createElement("div", { className: "row" },
            React.createElement(RevStarWrap, { className: "col d-flex align-items-center" },
                React.createElement(StyledRatingWrapper, { className: " " + (!avgOvrlRating && !fullReviews ? 'link' : '') },
                    avgOvrlRating && fullReviews ? (React.createElement(CustomPopover, { trigger: matches ? 'hover' : 'click', isProtectionPopover: true, isSubscription: true, isReviewPopover: true, maxWidth: "100%", data: React.createElement(ReviewDropdown, { avgOvrlRating: avgOvrlRating, fullReviews: fullReviews, StarRating: StarRating, scrollToReview: scrollToReview, matches: matches }) },
                        avgOvrlRating ? (React.createElement("span", { style: { cursor: 'pointer' }, "auto-data": "product_ratings", "data-testid": "avg-rating", onClick: matches ? scrollToReview : null },
                            React.createElement(StyledRating, { ratingValue: Number(avgOvrlRating) * (100 / 5), readonly: true, size: 14, fillColor: "#e0a237", allowHalfIcon: true }))) : (''),
                        fullReviews ? (React.createElement("span", { onClick: matches ? scrollToReview : null, className: "cls-reviewNumber", "data-testid": "review-count" },
                            ' ',
                            "(",
                            React.createElement("span", null, fullReviews),
                            ")",
                            ' ')) : (''))) : (''),
                    !avgOvrlRating && !fullReviews && (React.createElement("div", { className: "mt-2" },
                        React.createElement(StyledWriteReviewLink, { onClick: handleWriteReview }, "Be the first to write a review")))),
                React.createElement(StyledQnAWrapper, null,
                    React.createElement("span", { onClick: scrollToQA, "auto-data": "product_qAndA" },
                        React.createElement("span", { className: "cls-qa" }, "Q & A ("),
                        React.createElement("span", { className: "cls-qacount" }, QACount),
                        ")"))))));
};
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6;
