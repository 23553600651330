var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from 'react';
import styled from 'styled-components';
import { Rating } from 'react-simple-star-rating';
var UserContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  position: relative;\n  display: block;\n  align-items: center;\n  padding: ", ";\n  width: 100%;\n\n  .reviewcount {\n    margin: 12px 0 16px 0;\n    font-weight: 700;\n    font-size: 14px;\n  }\n"], ["\n  position: relative;\n  display: block;\n  align-items: center;\n  padding: ", ";\n  width: 100%;\n\n  .reviewcount {\n    margin: 12px 0 16px 0;\n    font-weight: 700;\n    font-size: 14px;\n  }\n"])), function (props) { return (!props.matches ? '12px 20px' : '8px 4px'); });
var StyledContainer = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  grid-area: progress;\n  progress {\n    -webkit-appearance: none;\n  }\n\n  progress[value] {\n    height: 11px;\n    width: 100%;\n  }\n  progress[value]::-webkit-progress-bar {\n    border-radius: 5px;\n    background-color: #dddddd;\n  }\n  progress[value]::-webkit-progress-value {\n    border-radius: 5px;\n    background-color: #e0a237;\n  }\n"], ["\n  grid-area: progress;\n  progress {\n    -webkit-appearance: none;\n  }\n\n  progress[value] {\n    height: 11px;\n    width: 100%;\n  }\n  progress[value]::-webkit-progress-bar {\n    border-radius: 5px;\n    background-color: #dddddd;\n  }\n  progress[value]::-webkit-progress-value {\n    border-radius: 5px;\n    background-color: #e0a237;\n  }\n"])));
var SeeAllReviewButton = styled.button(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  width: 240px;\n  height: 40px;\n  color: #fff;\n  box-shadow: none;\n  font-size: 14px;\n  font-weight: 700;\n  border: 1px solid #cc0033;\n  background: #cc0033;\n  border-radius: 5px;\n  padding-top: 12px;\n  padding-bottom: 12px;\n  line-height: 16px;\n  justify-content: inherit;\n  align-items: center;\n  text-transform: uppercase;\n\n  &:hover {\n    background-color: #a30028;\n  }\n"], ["\n  width: 240px;\n  height: 40px;\n  color: #fff;\n  box-shadow: none;\n  font-size: 14px;\n  font-weight: 700;\n  border: 1px solid #cc0033;\n  background: #cc0033;\n  border-radius: 5px;\n  padding-top: 12px;\n  padding-bottom: 12px;\n  line-height: 16px;\n  justify-content: inherit;\n  align-items: center;\n  text-transform: uppercase;\n\n  &:hover {\n    background-color: #a30028;\n  }\n"])));
var StyledReviewRating = styled.span(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  display: inline-block;\n  height: auto;\n  margin-left: 5px;\n  padding: 7px 8px 5px;\n  text-align: center;\n  backgrond: #fff;\n  border: 1px solid #ccc;\n  border-radius: 2px;\n"], ["\n  display: inline-block;\n  height: auto;\n  margin-left: 5px;\n  padding: 7px 8px 5px;\n  text-align: center;\n  backgrond: #fff;\n  border: 1px solid #ccc;\n  border-radius: 2px;\n"])));
var StyledRating = styled(Rating)(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  svg {\n    margin: 0 2px;\n  }\n"], ["\n  svg {\n    margin: 0 2px;\n  }\n"])));
var Container = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  display: grid;\n  height: auto;\n  color: black;\n  padding-bottom: 16px;\n  grid-template-columns: 0.5fr 2fr 0.2fr;\n  grid-template-areas: 'content progress nav';\n  text-align: left;\n  grid-gap: 0.5rem;\n  white-space: nowrap;\n  font-size: 14px;\n"], ["\n  display: grid;\n  height: auto;\n  color: black;\n  padding-bottom: 16px;\n  grid-template-columns: 0.5fr 2fr 0.2fr;\n  grid-template-areas: 'content progress nav';\n  text-align: left;\n  grid-gap: 0.5rem;\n  white-space: nowrap;\n  font-size: 14px;\n"])));
var StyledRightContent = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  grid-area: content;\n"], ["\n  grid-area: content;\n"])));
var StyledLeftContent = styled.div(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  grid-area: nav;\n  text-align: center;\n"], ["\n  grid-area: nav;\n  text-align: center;\n"])));
export var ReviewDropdown = function (_a) {
    var avgOvrlRating = _a.avgOvrlRating, fullReviews = _a.fullReviews, StarRating = _a.StarRating, scrollToReview = _a.scrollToReview, matches = _a.matches;
    return (React.createElement(UserContainer, { matches: matches },
        React.createElement("span", { "auto-data": "product_ratings", "data-testid": "avg-rating" },
            React.createElement(StyledRating, { ratingValue: Number(avgOvrlRating) * (100 / 5), readonly: true, size: 28, fillColor: "#e0a237", allowHalfIcon: true }),
            React.createElement(StyledReviewRating, null, avgOvrlRating)),
        React.createElement("div", { className: "reviewcount" },
            fullReviews,
            " Reviews"),
        (StarRating === null || StarRating === void 0 ? void 0 : StarRating.length) > 0 &&
            StarRating.map(function (item, index) {
                return (React.createElement(Container, null,
                    React.createElement(StyledRightContent, null,
                        5 - index,
                        " ",
                        5 - index === 1 ? 'star' : 'stars'),
                    React.createElement(StyledContainer, null,
                        React.createElement("progress", { value: item, max: fullReviews })),
                    React.createElement(StyledLeftContent, null, item)));
            }),
        React.createElement(SeeAllReviewButton, { onClick: scrollToReview }, "See All Reviews")));
};
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8;
