var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { useState, useEffect } from 'react';
import { NonBjsGcStyles } from './NonBjsGiftCard.styled';
import TickICO from '../../../assets/images/icons/tick-green.svg';
import CustomPopover from '@components/shared/CustomPopover';
import useMediaQuery from '../../../hooks/useMediaQuery';
import format from 'date-fns/format';
import { checkInventoryForOnlineProduct } from '../../../utils/format/product';
import { atcDisableEvent } from '../../MainLayout/AddToCart';
import { PdpContext } from '../../../store/pdp/PdpContext';
import { isBrowser } from '../../../utils/helper';
/** This Component Renders only when Product has
 * definingAttributes[i].name == NonBJsDelMethodGC */
var NonBjsGiftCard = function (_a) {
    var product = _a.product, nonBjsGcDeliveryMethod = _a.nonBjsGcDeliveryMethod, userSignedInCookie = _a.userSignedInCookie, setBtnAddToCartName = _a.setBtnAddToCartName, isInClubItem = _a.isInClubItem;
    //Variables
    var _b = __read(useState('Digital'), 2), deliveryType = _b[0], setDeliveryType = _b[1];
    var _c = __read(useState(true), 2), isInventoryAvailable = _c[0], setIsInventoryAvailable = _c[1];
    var primaryEmailId = userSignedInCookie === null || userSignedInCookie === void 0 ? void 0 : userSignedInCookie.PrimaryEmailId;
    var matches = useMediaQuery('(min-width: 768px)');
    var freePickUpDate = format(new Date(), 'MMM d, y, h:mm:ss a');
    var freePickupInfo = "We do our best to update club pricing and inventory amounts as they change. However, there may be slight differences in actual club pricing and inventory levels. Therefore, the price reflected and the inventory is estimated as of -dateTime- and does not include taxes or application of coupons. Coupons are subject to valid dates and the terms of the coupon offer.".replace('-dateTime-', "" + freePickUpDate);
    var pdpContext = React.useContext(PdpContext);
    var _d = __read(pdpContext.helpers, 1), setCurrentVariant = _d[0];
    var nonBjsGcVariants = function (variants) {
        var gcVariants = [];
        if (variants && variants.length > 0) {
            var sortedVariantsByName = variants.sort(function (a, b) { return (a.name > b.name ? 1 : -1); });
            sortedVariantsByName.forEach(function (variant) {
                if ((variant === null || variant === void 0 ? void 0 : variant.itemId) != 0) {
                    gcVariants.push(variant);
                }
            });
            return gcVariants;
        }
        else {
            return gcVariants;
        }
    };
    var variants = nonBjsGcVariants(product === null || product === void 0 ? void 0 : product.variant);
    //Effects
    useEffect(function () {
        selectedDeliveryMethod('Digital');
    }, []);
    //Functions
    var selectedDeliveryMethod = function (deliveryType) {
        setDeliveryType(deliveryType);
        setBtnAddToCartName('ADD TO CART');
        atcDisableEvent('disableATC', false);
        if (variants && variants.length > 0) {
            variants.forEach(function (variant) {
                if ((variant === null || variant === void 0 ? void 0 : variant.name) == deliveryType) {
                    nonBjsGcDeliveryMethod(variant);
                    setCurrentVariant(variant);
                    var isInventoryAvailable_1 = checkInventoryForOnlineProduct(product, variant === null || variant === void 0 ? void 0 : variant.itemId);
                    setIsInventoryAvailable(isInventoryAvailable_1);
                    if (!isInventoryAvailable_1) {
                        setBtnAddToCartName('SOLD OUT');
                        atcDisableEvent('disableATC', true);
                    }
                }
            });
        }
        else {
            setBtnAddToCartName('SOLD OUT');
            atcDisableEvent('disableATC', true);
        }
    };
    return (!isInClubItem && (React.createElement(React.Fragment, null,
        React.createElement(NonBjsGcStyles, null,
            React.createElement("div", { className: "delivery-label" },
                "Select Free Delivery Method:",
                React.createElement("span", { className: "delivery-label-value" },
                    ' ',
                    deliveryType === 'Digital' ? 'Digital Email' : 'Physical Mail')),
            React.createElement("div", { className: "row delivery-option" }, variants.map(function (variant) { return (React.createElement("div", { className: "col-lg-6 col-md-12 col-sm-12" },
                React.createElement("span", { className: "" + (deliveryType === variant.name ? "radio-btn" : "radio-btn inactive"), onClick: function () { return selectedDeliveryMethod(variant.name); } }),
                React.createElement("span", { className: "digital-email" }, variant.name === 'Digital' ? 'Digital Email' : 'Physical Mail'),
                React.createElement("div", { className: "delivery-time" },
                    "Estimated Delivery:",
                    ' ',
                    React.createElement("strong", null, variant.name === 'Digital' ? (React.createElement(React.Fragment, null,
                        "Emailed within 1-2 hours to",
                        ' ',
                        primaryEmailId && isBrowser()
                            ? primaryEmailId
                            : "your BJ's sign-in email address")) : (React.createElement(React.Fragment, null, "3-5 Business Days")))))); })),
            isInventoryAvailable && (React.createElement("div", { className: "inventory-available" },
                React.createElement("ul", { className: "list-unstyled mb-0" },
                    React.createElement("li", null,
                        React.createElement("img", { src: TickICO }),
                        React.createElement("span", { className: "inStock" }, "In Stock"),
                        ' ',
                        React.createElement("span", { className: "cls-round-icon-header cls-round-underline pl-1", style: { position: 'relative', top: -'1px' } },
                            React.createElement(CustomPopover, { trigger: matches ? 'hover' : 'click', maxWidth: "172px", data: freePickupInfo },
                                React.createElement("img", { src: "https://www.bjs.com/assets/images/icons/Information.svg", alt: "" }))))))),
            !isInventoryAvailable && (React.createElement("div", { className: "inventory-na" },
                React.createElement("ul", { className: "list-unstyled mb-0" },
                    React.createElement("li", null,
                        React.createElement("i", { className: "fa fa-ban fa-red pr-8", "aria-hidden": "true" }),
                        React.createElement("span", { className: "soldout" },
                            React.createElement("b", null, "Sold Out"),
                            ' ')))))))));
};
export default NonBjsGiftCard;
