var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React from 'react';
import styled from 'styled-components';
import { Modal } from 'react-bootstrap';
import { SharedStyledButton } from '@components/shared/SharedStyledButton';
var StyleModal = styled(Modal)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  .modal-dialog {\n    max-width: 498px;\n    margin-top: 4%;\n  }\n  .body-styling,\n  .shop-now-content {\n    text-align: center;\n    letter-spacing: 0;\n  }\n  .header {\n    text-align: center;\n  }\n  .modal-header {\n    padding-top: 0px;\n    border-bottom: none;\n  }\n  .shop-now-content {\n    text-decoration: underline;\n    font-family: roboto;\n  }\n  .body-styling {\n    text-align: center;\n    margin-bottom: 0px;\n    font-weight: 400px;\n    font-family: roboto;\n  }\n  .modal-title {\n    font-size: 16px;\n    font-weight: 400;\n    flex: 0 0 100%;\n    max-width: initial;\n  }\n  .cursor-pointer {\n    cursor: pointer;\n    width: 10px;\n    height: 20px;\n  }\n  .btn-block {\n    width: 40%;\n    margin-bottom: 16px;\n  }\n"], ["\n  .modal-dialog {\n    max-width: 498px;\n    margin-top: 4%;\n  }\n  .body-styling,\n  .shop-now-content {\n    text-align: center;\n    letter-spacing: 0;\n  }\n  .header {\n    text-align: center;\n  }\n  .modal-header {\n    padding-top: 0px;\n    border-bottom: none;\n  }\n  .shop-now-content {\n    text-decoration: underline;\n    font-family: roboto;\n  }\n  .body-styling {\n    text-align: center;\n    margin-bottom: 0px;\n    font-weight: 400px;\n    font-family: roboto;\n  }\n  .modal-title {\n    font-size: 16px;\n    font-weight: 400;\n    flex: 0 0 100%;\n    max-width: initial;\n  }\n  .cursor-pointer {\n    cursor: pointer;\n    width: 10px;\n    height: 20px;\n  }\n  .btn-block {\n    width: 40%;\n    margin-bottom: 16px;\n  }\n"])));
var ItemMovedModal = function (_a) {
    var show = _a.show, onHide = _a.onHide;
    return (React.createElement(React.Fragment, null,
        React.createElement(StyleModal, { show: show, onHide: onHide, backdrop: "static", keyboard: false },
            React.createElement(Modal.Header, null,
                React.createElement(Modal.Title, { className: "col-10 pr-0 modal-title h4 mt-4 header" }, "Item moved to your wishlist"),
                React.createElement("img", { src: "https://www.bjs.com/assets/images/icons/X.svg", className: "mt-1 mr-2 cursor-pointer", onClick: onHide, alt: "" })),
            React.createElement(Modal.Body, { className: "pt-0" },
                React.createElement(SharedStyledButton, { variant: "primary", className: "btn-block mx-auto", onClick: onHide }, "Continue")))));
};
export default ItemMovedModal;
var templateObject_1;
