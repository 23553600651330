export var urlConstants = {
    PENCIL_BANNER: 'v1.0/espot?emsName=BJsHeaderBannerEspot&pageName=HomePage&pageGroup=Content&catalogId=10201&storeId=10201&langId=-1',
    // pdp
    PRODUCT_DETAILS: 'v1.2/pdp/10201',
    //powerreviewsurl
    POWER_REVIEWS_URL: 'https://display.powerreviews.com/m/9794/l/en_US/product/',
    // shipping and returns
    SHIPPING_AND_RETURNS: 'v1.0/espot?emsName=ShippingReturns_ReturnPolicy_B2C&pageName=&pageGroup=Content&catalogId=10201&storeId=10201&langId=-1',
    // // add to cart
    ADD_TO_CART: 'v1.2/storeId/10201/shoppingCartItem',
    // Quantity Update
    QUANTITYCHANGE: 'v1.0/storeId/10201/shoppingcart/qtyupdate',
    // Fulfillment Change
    FULLFILLMENTCHANGE: 'v1.0/storeId/10201/fulfillment/optionchange',
    MINI_CART_STORE_ID: 'v1.0/minicart',
    //PDP PRICE
    PDP_CLUB_PRICE: 'v1.0/product/price/10201',
    PRODUCT_INVENTORY_CLUB: 'v1.2/inventory/club',
    ZIPCODE_VALIDATION: '/v1.0/storeId/10201/zipCodeValidation',
    CLUB_SEARCH: 'v1.2/club/search/10201',
    CLUB_INVENTORY: 'v1.3/store/10201/clubInventory',
    SHIPPING_RESTRICTIONS: '/v1.0/shipping-restriction',
    SDD_MEMBER_SHIPPING_ADDRESS: 'v1.0/store/10201/sdd/member/shipaddress',
    // post coupon
    COUPON_POST: 'v1.1/display/coupons/product',
    CLIP_COUPON: 'v1.0/store/10201/coupons/activate',
    COUPON_APPLY: 'v1.0/storeId/10201/clipassign',
    // shopping list / wishlist display
    SHOPPING_LIST_DISPLAY: 'v1.1/store/10201/shoppingListDisplay',
    // add to default wishlist
    ADD_TO_DEFAULT_WISHLIST: 'v1.1/storeId/10201/wishlist',
    // create new wishlist
    CREATE_NEW_WISHLIST: 'v1.0/store/10201/fromPage/myLists/newList',
    // add to specific wishlist
    ADD_TO_SPECIFIC_WISHLIST: 'v1.0/store/10201/fromPage/pdp/newList?listId=',
    SDD_MEMBER_ADDRESS_BOOK: 'v1.0/store/10201/sdd/checkout/addressbook?addressType=SB&startIndex=1&endIndex=6',
    SDD_VALIDATE_ADDRESS: 'v2.0/sdd/validateaddress',
    SDD_ITEM_UPDATE: 'v1.0/store/10201/sdd/itemupdate',
    PROMOTIONS_API: "v1.0/storeId/10201/allofferpromotion",
    MEMBER_PRODUCT_LOCATION: "v1.0/clublocation/club"
};
