import React from 'react';
import PdpProvider from './store/pdp/PdpProvider';
import SSRProvider from 'react-bootstrap/SSRProvider';
import MainLayout from '@components/MainLayout/MainLayout';
import SeoContainer from '@components/seo/SeoContainer';
import { PriceContextProvider } from './store/cart/CartProvider';
import { initialState, PriceReducer } from './store/cart/CartContext';
import { FulfillmentContextProvider } from './store/fulfillment/FulfillmentProvider';
import { initialState as fulfillmentInitialState, FulfillmentReducer } from './store/fulfillment/FulfillmentContext';
var isBrowser = function () { return typeof window !== 'undefined'; };
export default function Root(props) {
    var inputRef = React.useRef(null);
    var initialData = props.data;
    var RootChildren = function () { return (React.createElement(SSRProvider, null,
        React.createElement(PdpProvider, { data: initialData },
            React.createElement(PriceContextProvider, { initialState: initialState, reducer: PriceReducer },
                React.createElement(SeoContainer, { requestObj: props }),
                React.createElement("main", null,
                    React.createElement(FulfillmentContextProvider, { initialState: fulfillmentInitialState, reducer: FulfillmentReducer },
                        React.createElement(MainLayout, null))))))); };
    return React.createElement(RootChildren, null);
}
