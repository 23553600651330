var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { useContext, useRef } from 'react';
import styled from 'styled-components';
import { useCookies } from 'react-cookie';
import CustomPopover from '../../../shared/CustomPopover';
import { PdpContext } from '../../../../store/pdp/PdpContext';
import { environmentConst } from '../../../../config/environments/constants';
import ZipCodeForm from './ZipCodeForm';
import { useFulfillmentStore } from '../../../../store/fulfillment/FulfillmentProvider';
import { setOnlineZipCode } from '../../../../store/fulfillment/FulfillmentContext';
import SubscriptionCheck from './SubscriptionCheck';
import { useStore } from '../../../../store/cart/CartProvider';
import { getKillSwitchSubscription } from '../../../../utils/storeConfHelper';
import { FlatrateShippingModal } from '../../../modals/FlatrateShippingModal';
import { checkRestrictedZipcodes, fetchUpdatedCookie } from '../../../../utils/helper';
import { isNullOrUndefinedOrEmpty } from '../../../../utils/pdpUtil';
import { setFrequency } from '../../../../store/cart/CartContext';
import useMediaQuery from '../../../../hooks/useMediaQuery';
import format from 'date-fns/format';
import TickICO from '../../../../assets/images/icons/tick-green.svg';
import LocationICO from '../../../../assets/images/icons/Location.svg';
var StyledDeliveryNotAvailable = styled.div.attrs(function () { return ({
    className: 'ml-4'
}); })(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  color: #d0021b;\n  padding-top: 5px;\n  font-size: 12px;\n  font-style: italic;\n  line-height: 14px;\n  margin: 5px 0;\n  font-weight: 400;\n"], ["\n  color: #d0021b;\n  padding-top: 5px;\n  font-size: 12px;\n  font-style: italic;\n  line-height: 14px;\n  margin: 5px 0;\n  font-weight: 400;\n"])));
export var OnlineFulFillment = function (_a) {
    var _b, _c, _d, _e;
    var isChecked = _a.isChecked;
    var fullfillmentStore = useFulfillmentStore();
    var _f = __read(fullfillmentStore, 2), fullfillmentState = _f[0], fullfillmentDispatch = _f[1];
    var onlineFulfillmentData = fullfillmentState.fulfillmentOnline;
    var _g = __read(useStore(), 2), state = _g[0], dispatch = _g[1];
    // const { priceData, fullFillmentData } = state;
    var productData = useContext(PdpContext);
    var productDetails = (_b = productData === null || productData === void 0 ? void 0 : productData.data) === null || _b === void 0 ? void 0 : _b.productDetailsData;
    var productFormattedData = productData === null || productData === void 0 ? void 0 : productData.productData;
    var isSubscriptionEligible = (_c = productDetails === null || productDetails === void 0 ? void 0 : productDetails.bjsitems[0]) === null || _c === void 0 ? void 0 : _c.isSubscriptionEligible;
    var _h = __read(React.useState(false), 2), isSubscriptionChecked = _h[0], setIsSubscriptionChecked = _h[1];
    var _j = __read(React.useState(1), 2), fulfillmentFrequency = _j[0], setFulfillmentFrequency = _j[1];
    var _k = __read(React.useState({
        flatRate: false
    }), 2), modalShow = _k[0], setModalShow = _k[1];
    var isEligible = onlineFulfillmentData.isEligible, displayItem = onlineFulfillmentData.displayItem, disabled = onlineFulfillmentData.disabled, isFreeShipping = onlineFulfillmentData.isFreeShipping, shippingText = onlineFulfillmentData.shippingText, isFlatRate = onlineFulfillmentData.isFlatRate, estimatedDelivery = onlineFulfillmentData.estimatedDelivery, onlineZipCode = onlineFulfillmentData.onlineZipCode, isInventoryAvailable = onlineFulfillmentData.isInventoryAvailable;
    // Ref to hide the popover on zip code update
    var zipCodeRef = useRef(null);
    var _l = __read(useCookies(), 3), cookies = _l[0], setCookie = _l[1], removeCookie = _l[2];
    var storeConfContext = useContext(PdpContext);
    var killSwitchSubscription = getKillSwitchSubscription(storeConfContext);
    var updateZipCode = function (value) {
        fullfillmentDispatch(setOnlineZipCode(value));
        setCookie(environmentConst.UPDATED_ZIP, value, {
            path: '/',
            expires: new Date(new Date().setDate(new Date().getDate() + 1))
        });
        if (cookies[environmentConst.USER_SIGNIN_RESPONSE_COOKIE]) {
            setCookie(environmentConst.ZIP_COOKIE, value, {
                path: '/',
                expires: new Date(new Date().setDate(new Date().getDate() + 1))
            });
        }
        if (zipCodeRef === null || zipCodeRef === void 0 ? void 0 : zipCodeRef.current) {
            zipCodeRef.current.hide();
        }
        if (isChecked == 3) {
            sessionStorage.setItem('iszipcodeUpdate', 'true');
        }
    };
    function getZipCode() {
        if (typeof window !== 'undefined') {
            var zipCode_1 = cookies[environmentConst.UPDATED_ZIP]
                ? cookies[environmentConst.UPDATED_ZIP]
                : cookies[environmentConst.USER_SIGNIN_RESPONSE_COOKIE] &&
                    !isNullOrUndefinedOrEmpty(fetchUpdatedCookie(environmentConst.ZIP_COOKIE))
                    ? cookies[environmentConst.ZIP_COOKIE]
                    : cookies[environmentConst.CLUB_ZIP_CODE_COOKIE];
            if (zipCode_1) {
                return zipCode_1;
            }
        }
        return null;
    }
    var zipCode = !isNullOrUndefinedOrEmpty((_d = fullfillmentState === null || fullfillmentState === void 0 ? void 0 : fullfillmentState.fulfillmentOnline) === null || _d === void 0 ? void 0 : _d.onlineZipCode)
        ? (_e = fullfillmentState === null || fullfillmentState === void 0 ? void 0 : fullfillmentState.fulfillmentOnline) === null || _e === void 0 ? void 0 : _e.onlineZipCode
        : getZipCode();
    React.useEffect(function () {
        document.addEventListener('cookie-event', function (e) {
            //@ts-ignore
            var _a = e.detail, name = _a.name, value = _a.value;
            setCookie(name, value, {
                path: '/',
                expires: new Date(new Date().setDate(new Date().getDate() + 1))
            });
        });
    }, []);
    var matches = useMediaQuery('(min-width: 768px)');
    var freePickUpDate = format(new Date(), 'MMM d, y, h:mm:ss a');
    var freePickupInfo = "We do our best to update club pricing and inventory amounts as they change. However, there may be slight differences in actual club pricing and inventory levels. Therefore, the price reflected and the inventory is estimated as of -dateTime- and does not include taxes or application of coupons. Coupons are subject to valid dates and the terms of the coupon offer.".replace('-dateTime-', "" + freePickUpDate);
    var renderShippingText = function () {
        var _a;
        if (checkRestrictedZipcodes((_a = productData === null || productData === void 0 ? void 0 : productData.data) === null || _a === void 0 ? void 0 : _a.shippingRestriction)) {
            return (React.createElement(React.Fragment, null,
                React.createElement("span", { style: { color: '#9C0228' } }, "Unavailable for Shipping"),
                " \u00A0",
                React.createElement("span", { className: "reg-font" }, "to\u00A0")));
        }
        if (isFreeShipping) {
            return (React.createElement(React.Fragment, null,
                "Free Shipping \u00A0",
                React.createElement("span", { className: "reg-font" }, "to\u00A0")));
        }
        return (React.createElement("span", { "data-testid": "ship-to" },
            "Shipping\u00A0",
            React.createElement("span", { className: "reg-font" }, "to\u00A0")));
    };
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: "fullfillment-shipping" },
            React.createElement("div", { className: "ffs-ship-to", "auto-data": "product_shippingFF_shippingMessagewithZipCode" },
                renderShippingText(),
                " ",
                zipCode,
                React.createElement("img", { src: LocationICO }),
                React.createElement(CustomPopover, { ref: zipCodeRef, trigger: "click", dismiss: "click", rootClose: true, maxWidth: "260px", data: React.createElement(ZipCodeForm, { zipCode: zipCode, updateZipCode: function (value) { return updateZipCode(value); } }) },
                    React.createElement("a", { onClick: function (e) { return e.preventDefault(); }, className: "location-edit-link d-block", "auto-data": "product_shippingFF_zipcode" }, "Check Eligibility"))),
            isSubscriptionEligible && (React.createElement("div", { className: "stdshipping d-flex align-items-center" },
                React.createElement("input", { type: "radio", id: "stdshipping", name: "standardshipping", onClick: function () {
                        dispatch(setFrequency({ fulfillmentFrequency: null, fulfillmentFrequencyUOM: null }));
                        setIsSubscriptionChecked(false);
                        setFulfillmentFrequency(1);
                    }, checked: !isSubscriptionChecked }),
                React.createElement("span", { className: "indicator" }),
                React.createElement("span", { className: !isSubscriptionChecked ? 'radio-text radio-text-active' : 'radio-text' }, "One-Time Shipping"))),
            estimatedDelivery && isInventoryAvailable && (React.createElement("div", { className: "ffs-eligibility" },
                React.createElement("span", null,
                    "Estimated Delivery: ",
                    React.createElement("em", null, estimatedDelivery)))),
            isFlatRate && (React.createElement("div", { className: "ffs-eligibility" },
                React.createElement("span", { className: isSubscriptionEligible ? 'flatratesubtext' : 'shipping-text' },
                    "$8.99 flat rate shipping on 3+ eligible items",
                    ' ',
                    React.createElement("a", { href: "#", className: "learn-more", onClick: function (e) {
                            e.preventDefault();
                            setModalShow(__assign(__assign({}, modalShow), { flatRate: true }));
                        } }, "Learn More")))),
            isSubscriptionEligible && killSwitchSubscription === 'OFF' && (React.createElement(SubscriptionCheck, { isSubscriptionChecked: isSubscriptionChecked, setIsSubscriptionChecked: setIsSubscriptionChecked, fulfillmentFrequency: fulfillmentFrequency, setFulfillmentFrequency: setFulfillmentFrequency })),
            isInventoryAvailable && (React.createElement("div", { className: "inventory-available" },
                React.createElement("ul", { className: "list-unstyled mb-0" },
                    React.createElement("li", null,
                        React.createElement("img", { src: TickICO }),
                        React.createElement("span", { className: "inStock" }, "In Stock"),
                        ' ',
                        React.createElement("span", { className: "cls-round-icon-header cls-round-underline pl-1", style: { position: 'relative', top: -'1px' } },
                            React.createElement(CustomPopover, { trigger: matches ? 'hover' : 'click', maxWidth: "172px", data: freePickupInfo },
                                React.createElement("img", { src: "https://www.bjs.com/assets/images/icons/Information.svg", alt: "" }))))))),
            !isInventoryAvailable && (React.createElement("div", { className: "inventory-na" },
                React.createElement("ul", { className: "list-unstyled mb-0" },
                    React.createElement("li", null,
                        React.createElement("i", { className: "fa fa-ban fa-red pr-8", "aria-hidden": "true" }),
                        React.createElement("span", { className: "soldout" },
                            React.createElement("b", null, "Sold Out"),
                            ' ')))))),
        React.createElement(FlatrateShippingModal, { show: modalShow.flatRate, onHide: function () { return setModalShow(__assign(__assign({}, modalShow), { flatRate: false })); } })));
};
var templateObject_1;
