var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from) {
    for (var i = 0, il = from.length, j = to.length; i < il; i++, j++)
        to[j] = from[i];
    return to;
};
import React, { useCallback, useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import Card from 'react-bootstrap/Card';
import { PdpContext } from '../../store/pdp/PdpContext';
var StyledTable = styled.table(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  th {\n    font-weight: normal;\n    width: 50%;\n  }\n  th,\n  td {\n    padding: 0.75rem;\n    border: none;\n  }\n  tr:nth-of-type(even) {\n    background-color: #f1f1f1;\n  }\n"], ["\n  th {\n    font-weight: normal;\n    width: 50%;\n  }\n  th,\n  td {\n    padding: 0.75rem;\n    border: none;\n  }\n  tr:nth-of-type(even) {\n    background-color: #f1f1f1;\n  }\n"])));
var ProductSpecifications = function () {
    var _a;
    var productData = useContext(PdpContext);
    var productDetailsData = (_a = productData === null || productData === void 0 ? void 0 : productData.data) === null || _a === void 0 ? void 0 : _a.productDetailsData;
    var descriptiveAttributes = (productDetailsData || {}).descriptiveAttributes;
    var _b = __read(useState([]), 2), sortedDescriptiveAttributes = _b[0], setSortedDescriptiveAttributes = _b[1];
    var memoizedSortDescriptiveAttributes = useCallback(function () {
        var _a;
        var sorted = (_a = __spreadArray([], __read(descriptiveAttributes))) === null || _a === void 0 ? void 0 : _a.sort(function (x, y) { return x.sequenceNumber - y.sequenceNumber; });
        setSortedDescriptiveAttributes(sorted);
    }, [descriptiveAttributes]);
    useEffect(function () {
        memoizedSortDescriptiveAttributes();
    }, [memoizedSortDescriptiveAttributes]);
    return (React.createElement(Card.Body, null,
        React.createElement(StyledTable, { className: "table table-bordered" },
            React.createElement("tbody", { "auot-data": "product_specifiations_heading", className: "specs-table-body" }, sortedDescriptiveAttributes.map(function (item, i) {
                var _a;
                return (React.createElement("tr", { className: "specs-table-row", key: "dA" + (item === null || item === void 0 ? void 0 : item.sequenceNumber) },
                    React.createElement("th", { className: "specs-table-heading" }, item === null || item === void 0 ? void 0 : item.name),
                    React.createElement("td", { className: "specs-table-data" }, (_a = item === null || item === void 0 ? void 0 : item.attributeValueDataBeans[0]) === null || _a === void 0 ? void 0 : _a.value)));
            })))));
};
export default ProductSpecifications;
var templateObject_1;
