var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import Api, { axiosCache } from './api';
import { urlConstants } from './urlConstants';
import { logInfo } from '@bjs/shared-micro-frontend';
export var getPencilBanner = function () { return __awaiter(void 0, void 0, void 0, function () {
    var response, err_1;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                return [4 /*yield*/, Api.get(urlConstants.PENCIL_BANNER)];
            case 1:
                response = _a.sent();
                return [2 /*return*/, response.data];
            case 2:
                err_1 = _a.sent();
                logInfo && logInfo('error', 'pencil_banner_api_failure', err_1);
                return [3 /*break*/, 3];
            case 3: return [2 /*return*/];
        }
    });
}); };
export var getProductDetails = function (productId) { return __awaiter(void 0, void 0, void 0, function () {
    var numberPattern, response, err_2;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                numberPattern = new RegExp(/^\d+$/gm);
                if (!productId || !numberPattern.test(productId)) {
                    logInfo && logInfo('error', 'Bad Product Id: ', productId);
                    return [2 /*return*/, null];
                }
                _a.label = 1;
            case 1:
                _a.trys.push([1, 3, , 4]);
                return [4 /*yield*/, Api.get(urlConstants.PRODUCT_DETAILS, {
                        params: { productId: productId, pageName: 'PDP' }
                    })];
            case 2:
                response = _a.sent();
                return [2 /*return*/, response.data];
            case 3:
                err_2 = _a.sent();
                logInfo && logInfo('error', 'product_details_api_failure', err_2);
                return [3 /*break*/, 4];
            case 4: return [2 /*return*/];
        }
    });
}); };
export var getBRConfigData = function (url) { return __awaiter(void 0, void 0, void 0, function () {
    var response, err_3;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                return [4 /*yield*/, Api.get(url)];
            case 1:
                response = _a.sent();
                return [2 /*return*/, response.data];
            case 2:
                err_3 = _a.sent();
                logInfo && logInfo('error', 'product_details_api_failure', err_3);
                return [3 /*break*/, 3];
            case 3: return [2 /*return*/];
        }
    });
}); };
export var getShippingAndReturns = function () { return __awaiter(void 0, void 0, void 0, function () {
    var response, err_4;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                return [4 /*yield*/, Api.get(urlConstants.SHIPPING_AND_RETURNS)];
            case 1:
                response = _a.sent();
                return [2 /*return*/, response.data];
            case 2:
                err_4 = _a.sent();
                logInfo && logInfo('error', 'shippingandreturns_api_failure', err_4);
                return [3 /*break*/, 3];
            case 3: return [2 /*return*/];
        }
    });
}); };
export var getShippingRestriction = function (articleId, zip, merchcat4) { return __awaiter(void 0, void 0, void 0, function () {
    var response, err_5;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                return [4 /*yield*/, Api.get(urlConstants.SHIPPING_RESTRICTIONS, {
                        params: {
                            articleId: articleId,
                            zip: zip,
                            merchcat4: merchcat4
                        }
                    })];
            case 1:
                response = _a.sent();
                return [2 /*return*/, response.data];
            case 2:
                err_5 = _a.sent();
                logInfo && logInfo('error', 'shipping_restriction_api_failure', err_5);
                return [3 /*break*/, 3];
            case 3: return [2 /*return*/];
        }
    });
}); };
export var getPDPClubPrice = function (productId, clubId) { return __awaiter(void 0, void 0, void 0, function () {
    var response, err_6;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (!clubId) return [3 /*break*/, 4];
                _a.label = 1;
            case 1:
                _a.trys.push([1, 3, , 4]);
                return [4 /*yield*/, axiosCache.get(urlConstants.PDP_CLUB_PRICE, {
                        params: { productId: productId, pageName: 'PDP', clubId: clubId },
                        //@ts-ignore
                        cache: {
                            maxAge: 2 * 60 * 1000,
                            exclude: { query: false }
                        }
                    })];
            case 2:
                response = _a.sent();
                return [2 /*return*/, response.data];
            case 3:
                err_6 = _a.sent();
                logInfo && logInfo('error', 'pdpclubprice_api_failure', err_6);
                return [3 /*break*/, 4];
            case 4: return [2 /*return*/];
        }
    });
}); };
export var getInventoryClub = function (partNumber, productUOM, clubId) { return __awaiter(void 0, void 0, void 0, function () {
    var postData, response, err_7;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                postData = {
                    InventoryMicroServiceEnableSwitch: 'ON',
                    Body: {
                        GetInventoryAvailability: {
                            ApplicationArea: {
                                BusinessContext: {
                                    ContextData: [
                                        { name: 'storeId', text: 10201 },
                                        { name: 'langId', text: '-1' }
                                    ]
                                }
                            },
                            PartNumber: partNumber,
                            uom: productUOM,
                            ExternalIdentifier: clubId
                        }
                    }
                };
                return [4 /*yield*/, Api.post(urlConstants.PRODUCT_INVENTORY_CLUB, postData)];
            case 1:
                response = _a.sent();
                return [2 /*return*/, response.data];
            case 2:
                err_7 = _a.sent();
                logInfo && logInfo('error', 'product_inventory_club_api_failure', err_7);
                return [3 /*break*/, 3];
            case 3: return [2 /*return*/];
        }
    });
}); };
export var getZipCodeValdiation = function (zipCode) { return __awaiter(void 0, void 0, void 0, function () {
    var response, err_8;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                return [4 /*yield*/, Api.get(urlConstants.ZIPCODE_VALIDATION, {
                        params: {
                            zipCode: zipCode
                        }
                    })];
            case 1:
                response = _a.sent();
                return [2 /*return*/, response.data];
            case 2:
                err_8 = _a.sent();
                logInfo && logInfo('error', 'zipcode_validation_api_failure', err_8);
                return [3 /*break*/, 3];
            case 3: return [2 /*return*/];
        }
    });
}); };
export var getSddMemberShippingAddress = function (addressId) { return __awaiter(void 0, void 0, void 0, function () {
    var response, err_9;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                return [4 /*yield*/, Api.get(urlConstants.SDD_MEMBER_SHIPPING_ADDRESS, {
                        params: {
                            addressId: addressId
                        },
                        withCredentials: true
                    })];
            case 1:
                response = _a.sent();
                return [2 /*return*/, response.data];
            case 2:
                err_9 = _a.sent();
                logInfo && logInfo('error', 'sddmembershippingaddress_api_failure', err_9);
                return [3 /*break*/, 3];
            case 3: return [2 /*return*/];
        }
    });
}); };
export var postAddToCart = function (payload) { return __awaiter(void 0, void 0, void 0, function () {
    var response, err_10;
    var _a, _b;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                _c.trys.push([0, 2, , 3]);
                return [4 /*yield*/, Api(urlConstants.ADD_TO_CART, {
                        method: 'post',
                        data: payload,
                        withCredentials: true,
                        headers: {
                            default_club_id: (_b = (_a = payload === null || payload === void 0 ? void 0 : payload.itemList) === null || _a === void 0 ? void 0 : _a[0]) === null || _b === void 0 ? void 0 : _b.physicalStoreId
                        }
                    })];
            case 1:
                response = _c.sent();
                return [2 /*return*/, response.data];
            case 2:
                err_10 = _c.sent();
                logInfo && logInfo('error', 'postaddtocart_api_failure', err_10.message);
                return [2 /*return*/, err_10];
            case 3: return [2 /*return*/];
        }
    });
}); };
export var postCouponList = function (payload) { return __awaiter(void 0, void 0, void 0, function () {
    var response, err_11;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                return [4 /*yield*/, Api(urlConstants.COUPON_POST, {
                        method: 'post',
                        data: payload,
                        withCredentials: true
                    })];
            case 1:
                response = _a.sent();
                return [2 /*return*/, response.data];
            case 2:
                err_11 = _a.sent();
                logInfo && logInfo('error', 'couponspost_api_failure', err_11.message);
                return [3 /*break*/, 3];
            case 3: return [2 /*return*/];
        }
    });
}); };
export var clipCoupon = function (params) { return __awaiter(void 0, void 0, void 0, function () {
    var response, responseData, err_12;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                return [4 /*yield*/, Api.get(urlConstants.CLIP_COUPON, {
                        params: params,
                        withCredentials: true
                    })];
            case 1:
                response = _a.sent();
                responseData = response.data;
                return [2 /*return*/, responseData];
            case 2:
                err_12 = _a.sent();
                logInfo && logInfo('error', 'clipcoupon_api_failure', err_12);
                return [3 /*break*/, 3];
            case 3: return [2 /*return*/];
        }
    });
}); };
export var applyCoupon = function (payload) { return __awaiter(void 0, void 0, void 0, function () {
    var response, err_13;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                return [4 /*yield*/, Api(urlConstants.COUPON_APPLY, {
                        method: 'post',
                        data: payload,
                        withCredentials: true
                    })];
            case 1:
                response = _a.sent();
                return [2 /*return*/, response.data];
            case 2:
                err_13 = _a.sent();
                logInfo && logInfo('error', 'applycoupon_api_failure', err_13.message);
                return [3 /*break*/, 3];
            case 3: return [2 /*return*/];
        }
    });
}); };
export var getSddAddressBook = function (startIndex, endIndex) {
    if (startIndex === void 0) { startIndex = 1; }
    if (endIndex === void 0) { endIndex = 6; }
    return __awaiter(void 0, void 0, void 0, function () {
        var response, err_14;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, Api.get(urlConstants.SDD_MEMBER_ADDRESS_BOOK + "&startIndex=" + startIndex + "&endIndex=" + endIndex, {
                            withCredentials: true
                        })];
                case 1:
                    response = _a.sent();
                    return [2 /*return*/, response.data];
                case 2:
                    err_14 = _a.sent();
                    throw err_14;
                case 3: return [2 /*return*/];
            }
        });
    });
};
export var miniCartStoreId = function () { return __awaiter(void 0, void 0, void 0, function () {
    var response, err_15;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                return [4 /*yield*/, Api.get(urlConstants.MINI_CART_STORE_ID, {
                        params: {
                            storeId: 10201
                        },
                        withCredentials: true
                    })];
            case 1:
                response = _a.sent();
                return [2 /*return*/, response.data];
            case 2:
                err_15 = _a.sent();
                throw err_15;
            case 3: return [2 /*return*/];
        }
    });
}); };
export var getPromotionDetails = function (_a) {
    var articleId = _a.articleId;
    return __awaiter(void 0, void 0, void 0, function () {
        var response, err_16;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    _b.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, Api.get(urlConstants.PROMOTIONS_API, {
                            params: {
                                articleId: articleId
                            },
                            withCredentials: true
                        })];
                case 1:
                    response = _b.sent();
                    return [2 /*return*/, response.data];
                case 2:
                    err_16 = _b.sent();
                    throw err_16;
                case 3: return [2 /*return*/];
            }
        });
    });
};
export var fullfillmentChange = function (payload) { return __awaiter(void 0, void 0, void 0, function () {
    var response, err_17;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                return [4 /*yield*/, Api(urlConstants.FULLFILLMENTCHANGE, {
                        method: 'post',
                        data: payload,
                        withCredentials: true
                    })];
            case 1:
                response = _a.sent();
                return [2 /*return*/, response.data];
            case 2:
                err_17 = _a.sent();
                logInfo && logInfo('error', 'fullfillmentchange_api_failure', err_17.message);
                return [2 /*return*/, err_17];
            case 3: return [2 /*return*/];
        }
    });
}); };
export var quantityUpdate = function (payload) { return __awaiter(void 0, void 0, void 0, function () {
    var response, err_18;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                return [4 /*yield*/, Api(urlConstants.QUANTITYCHANGE, {
                        method: 'post',
                        data: payload,
                        withCredentials: true
                    })];
            case 1:
                response = _a.sent();
                return [2 /*return*/, response.data];
            case 2:
                err_18 = _a.sent();
                logInfo && logInfo('error', 'quantityupdate_api_failure', err_18.message);
                return [2 /*return*/, err_18];
            case 3: return [2 /*return*/];
        }
    });
}); };
export var getReviewFromPowerReview = function (productId, apiKey) { return __awaiter(void 0, void 0, void 0, function () {
    var response, err_19;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                return [4 /*yield*/, Api.get(urlConstants.POWER_REVIEWS_URL + (productId + "/questions?_noconfig=true&apikey=" + apiKey))];
            case 1:
                response = _a.sent();
                return [2 /*return*/, response.data];
            case 2:
                err_19 = _a.sent();
                logInfo && logInfo('error', 'reviewpowerreview_api_failure', err_19);
                return [3 /*break*/, 3];
            case 3: return [2 /*return*/];
        }
    });
}); };
export var getRatingsFromPowerReview = function (productId, apiKey) { return __awaiter(void 0, void 0, void 0, function () {
    var response, err_20;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                return [4 /*yield*/, Api.get(urlConstants.POWER_REVIEWS_URL + (productId + "/reviews?apikey=" + apiKey + "&_noconfig=true"))];
            case 1:
                response = _a.sent();
                return [2 /*return*/, response.data];
            case 2:
                err_20 = _a.sent();
                logInfo && logInfo('error', 'ratingpowerreview_api_failure', err_20);
                return [3 /*break*/, 3];
            case 3: return [2 /*return*/];
        }
    });
}); };
export var getBopicProductLocations = function (articleId, storeId) { return __awaiter(void 0, void 0, void 0, function () {
    var response, err_21;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                return [4 /*yield*/, Api.get(urlConstants.MEMBER_PRODUCT_LOCATION + ("/" + storeId + "/" + articleId))];
            case 1:
                response = _a.sent();
                return [2 /*return*/, response.data];
            case 2:
                err_21 = _a.sent();
                logInfo && logInfo('error', 'bopicmemberproductlocation_api_failure', err_21);
                return [3 /*break*/, 3];
            case 3: return [2 /*return*/];
        }
    });
}); };
