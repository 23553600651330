var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { sendDataToDataLayer } from '@bjs/shared-micro-frontend';
import { environmentConst } from '../config/environments/constants';
import { parseCookie } from './helper';
var getItemFulfillmentType = function (cartType) {
    var fulfillmentType = '';
    switch (cartType) {
        case 'BOPIC':
            fulfillmentType = 'Free Pickup';
            break;
        case 'SDD':
            fulfillmentType = 'Same Day Delivery';
            break;
        case 'ONLINE':
            fulfillmentType = 'Ship It';
            break;
        default:
            fulfillmentType = '';
            break;
    }
    return fulfillmentType;
};
//Method used to push all events data to adobedatalayer
export var addToCartAnalyticsEvent = function (fulfillmentType, quantity, type, from, carousel, onlineCartInfoCookie, productData) {
    var _a;
    // SP-16810 - Push AddToCart event to AdobeDataLayer and datalayer
    // @ts-ignore
    var prodViewEvent = getProductArray();
    var updartedProductData = productData ? productData : (_a = prodViewEvent === null || prodViewEvent === void 0 ? void 0 : prodViewEvent.products) === null || _a === void 0 ? void 0 : _a[0];
    // @ts-ignore
    if (window === null || window === void 0 ? void 0 : window.adobeDataLayer) {
        var productsAdded = __assign({}, updartedProductData);
        var prodName = productsAdded === null || productsAdded === void 0 ? void 0 : productsAdded.itemName;
        if (from == undefined) {
            productsAdded.itemAddedAs = fulfillmentType === 'SDD' ? 'sdd' : '';
            productsAdded.itemFulfillmentType = getItemFulfillmentType(fulfillmentType);
            productsAdded.quantity = quantity;
            productsAdded.type = type;
            productsAdded.action = 'add';
        }
        else {
            productsAdded = from;
        }
        var modifiedProductsAdded = void 0;
        if (!onlineCartInfoCookie || onlineCartInfoCookie == '0') {
            if (from !== undefined) {
                modifiedProductsAdded = productsAdded === null || productsAdded === void 0 ? void 0 : productsAdded.map(function (product) {
                    return __assign(__assign({}, product), { firstCart: true });
                });
            }
            else {
                modifiedProductsAdded = __assign(__assign({}, productsAdded), { firstCart: true });
            }
        }
        else {
            if (from !== undefined) {
                modifiedProductsAdded = productsAdded === null || productsAdded === void 0 ? void 0 : productsAdded.map(function (product) {
                    return __assign({}, product);
                });
            }
            else {
                modifiedProductsAdded = __assign({}, productsAdded);
            }
        }
        var dataForDataLayer = {
            event: carousel == 'carousel' ? 'pdpProductClick' : 'AddToCart',
            page: { page: 'PDP', prodName: prodName },
            products: from !== undefined ? modifiedProductsAdded : [modifiedProductsAdded],
            user: prodViewEvent === null || prodViewEvent === void 0 ? void 0 : prodViewEvent.user
        };
        sendDataToDataLayer && sendDataToDataLayer(dataForDataLayer);
    }
};
//Add to wishlist product data
export var addToWishListAnalyticsEvent = function (productData) {
    var _a;
    var prodViewEvent = getProductArray();
    var updartedProductData = productData ? productData : (_a = prodViewEvent === null || prodViewEvent === void 0 ? void 0 : prodViewEvent.products) === null || _a === void 0 ? void 0 : _a[0];
    // @ts-ignore
    if (window === null || window === void 0 ? void 0 : window.adobeDataLayer) {
        var productsAdded = __assign({}, updartedProductData);
        var prodName = productsAdded === null || productsAdded === void 0 ? void 0 : productsAdded.itemName;
        var dataForDataLayer = {
            event: 'AddToWishList',
            page: { page: 'PDP', prodName: prodName },
            products: productsAdded,
            user: prodViewEvent === null || prodViewEvent === void 0 ? void 0 : prodViewEvent.user
        };
        sendDataToDataLayer && sendDataToDataLayer(dataForDataLayer);
    }
};
//To send products data of sponsored products to adobedatalayer
export var SendSponsoredProductEvent = function (pData, ecid) {
    var prodName = pData === null || pData === void 0 ? void 0 : pData.ProductName;
    var productData = getProductArray();
    var products = [];
    pData.forEach(function (data) {
        var prodObj = getProductObj(data, 'Sponsored Products');
        prodObj['location'] = 'Sponsored Products Carousel';
        prodObj['ECID'] = ecid;
        prodObj['specialPrograms'] = 'sponsored';
        prodObj['productFindingMethod'] = 'Carousal';
        prodObj['productFindingSubMethod'] = 'Sponsored Products';
        products.push(prodObj);
    });
    var dataForDataLayer = {
        event: 'sponsoredProductImpression',
        page: { page: 'PDP', prodName: prodName },
        sponsoredProducts: products,
        user: productData === null || productData === void 0 ? void 0 : productData.user
    };
    sendDataToDataLayer && sendDataToDataLayer(dataForDataLayer);
};
//Method used to send
//Frequently bought carousel ATC data
//Sponsored Carousel ATC data
//Sponsored & Recommended carousel Product click data
export var ATCFreqBoughtItems = function (atcData, fullFillmentType, from, action) {
    var onlineCartInfoCookie = parseCookie(environmentConst.CART_INFO_COOKIE);
    var products = [];
    var prodObj;
    if (atcData.length > 0) {
        atcData.forEach(function (data) {
            var prodObj = getProductObj(data);
            (prodObj['action'] = 'add'), (prodObj['location'] = 'Frequently Bought Carousel');
            prodObj['itemFulfillmentType'] = fullFillmentType;
            prodObj['quantity'] = 1;
            prodObj['itemAddedAs'] = undefined;
            prodObj['type'] = undefined;
            prodObj['productFindingMethod'] = 'Carousal';
            prodObj['productFindingSubMethod'] = 'Frequently Bought Together';
            products.push(prodObj);
        });
        if (products != undefined && (products === null || products === void 0 ? void 0 : products.length) > 0) {
            addToCartAnalyticsEvent('', '', '', products, undefined, onlineCartInfoCookie);
        }
    }
    else {
        prodObj = getProductObj(atcData, from);
        prodObj['action'] = action == 'addToCart' ? 'add' : 'productClick';
        prodObj['location'] = from + ' Carousel';
        if (from == 'Sponsored Products') {
            prodObj['ECID'] = atcData === null || atcData === void 0 ? void 0 : atcData.ECID;
            prodObj['specialPrograms'] = 'sponsored';
            prodObj['productFindingMethod'] = 'Carousal';
            prodObj['productFindingSubMethod'] = 'Sponsored Products';
        }
        else {
            prodObj['productFindingMethod'] = 'Carousal';
            prodObj['productFindingSubMethod'] = from;
        }
        if (action != 'addToCart') {
            sessionStorage.setItem(environmentConst.PRODUCT_FINDING_METHOD_SESSION_COOKIE, prodObj === null || prodObj === void 0 ? void 0 : prodObj.productFindingMethod);
            sessionStorage.setItem(environmentConst.PRODUCT_FINDING_SUB_METHOD_SESSION_COOKIE, prodObj === null || prodObj === void 0 ? void 0 : prodObj.productFindingSubMethod);
        }
        products.push(prodObj);
        addToCartAnalyticsEvent('', '', '', products, action === 'addToCart' ? '' : 'carousel', onlineCartInfoCookie);
    }
};
//Method used to create a product object
var getProductObj = function (item, from) {
    var _a;
    var id;
    if (from == 'Sponsored Products') {
        var data = (_a = item === null || item === void 0 ? void 0 : item.DetailURL) === null || _a === void 0 ? void 0 : _a.split('/');
        id = data[data.length - 1];
    }
    var productObj = {
        articleId: (item === null || item === void 0 ? void 0 : item.ArticleId) || (item === null || item === void 0 ? void 0 : item.ID),
        availability: undefined,
        brand: undefined,
        breadcrumb: undefined,
        category: undefined,
        itemCatentryId: item === null || item === void 0 ? void 0 : item.ItemCatentryID,
        itemName: item === null || item === void 0 ? void 0 : item.ProductName,
        itemPartNumber: (item === null || item === void 0 ? void 0 : item.PartNumber) || undefined,
        orderType: undefined,
        position: 0,
        price: parseFloat(item === null || item === void 0 ? void 0 : item.displayPrice),
        productCatentryId: id || (item === null || item === void 0 ? void 0 : item.CatentryID),
        productImageURL: item === null || item === void 0 ? void 0 : item.ImageURL,
        productPageURL: window.location.href.replace(window.location.origin, ''),
        productPartNumber: undefined,
        rating: parseFloat(item === null || item === void 0 ? void 0 : item.RatingStars),
        salePrice: parseFloat(item === null || item === void 0 ? void 0 : item.displayPrice),
        advertiserId: (item === null || item === void 0 ? void 0 : item.advertiserId) || undefined,
        campaignId: (item === null || item === void 0 ? void 0 : item.campaignId) || undefined,
        partnerId: (item === null || item === void 0 ? void 0 : item.partnerId) || undefined,
        placementId: (item === null || item === void 0 ? void 0 : item.placementId) || undefined
    };
    return productObj;
};
//Method used to send product overview data
export var sendProdOVData = function () {
    var _a;
    var productData = getProductArray();
    var productsAdded = __assign({}, (_a = productData === null || productData === void 0 ? void 0 : productData.products) === null || _a === void 0 ? void 0 : _a[0]);
    var prodName = productsAdded === null || productsAdded === void 0 ? void 0 : productsAdded.itemName;
    var dataForDataLayer = {
        event: 'productDescriptionInteraction',
        page: { page: 'PDP', prodName: prodName },
        products: productData.products,
        user: productData === null || productData === void 0 ? void 0 : productData.user
    };
    sendDataToDataLayer && sendDataToDataLayer(dataForDataLayer);
};
//send fulfillment data with updated price value
export var sendFulfillmentData = function (type, price) {
    var _a, _b;
    var fulfillmentType = type === 1 ? 'Bopic' : type === 2 ? 'SDD' : 'Shipping';
    var productData = getProductArray();
    var productsAdded = __assign({}, (_a = productData === null || productData === void 0 ? void 0 : productData.products) === null || _a === void 0 ? void 0 : _a[0]);
    var prodName = productsAdded === null || productsAdded === void 0 ? void 0 : productsAdded.itemName;
    if (((_b = productData === null || productData === void 0 ? void 0 : productData.products) === null || _b === void 0 ? void 0 : _b.length) > 0) {
        productData === null || productData === void 0 ? void 0 : productData.products.forEach(function (product) {
            product.price = price;
            product['itemFulfillmentType'] = fulfillmentType;
        });
    }
    var dataForDataLayer = {
        event: 'updateFulfillmentOptionInPDP',
        page: { page: 'PDP', prodName: prodName },
        products: productData === null || productData === void 0 ? void 0 : productData.products,
        user: productData === null || productData === void 0 ? void 0 : productData.user
    };
    sendDataToDataLayer && sendDataToDataLayer(dataForDataLayer);
};
//Method used to fetch the product array from existing 'prodView' event in datalayer
var getProductArray = function () {
    var _a;
    // @ts-ignore
    if ((window === null || window === void 0 ? void 0 : window.adobeDataLayer) && ((_a = window === null || window === void 0 ? void 0 : window.adobeDataLayer) === null || _a === void 0 ? void 0 : _a.length) > 0) {
        // @ts-ignore
        var prodViewEvent = window === null || window === void 0 ? void 0 : window.adobeDataLayer.filter(function (e) { return e.event === 'prodView'; })[0];
        return prodViewEvent;
    }
};
export var sendBreadcrumbDetails = function (link) {
    var _a;
    var prodViewEvent = getProductArray();
    // @ts-ignore
    if (window === null || window === void 0 ? void 0 : window.adobeDataLayer) {
        var productsAdded = __assign({}, (_a = prodViewEvent === null || prodViewEvent === void 0 ? void 0 : prodViewEvent.products) === null || _a === void 0 ? void 0 : _a[0]);
        var prodName = productsAdded === null || productsAdded === void 0 ? void 0 : productsAdded.itemName;
        var dataForDataLayer = {
            event: 'pdpBreadcrumbClick',
            page: { page: 'PDP', prodName: prodName },
            user: prodViewEvent === null || prodViewEvent === void 0 ? void 0 : prodViewEvent.user,
            linkName: link,
            linkRegion: 'pdp breadcrumb'
        };
        sendDataToDataLayer && sendDataToDataLayer(dataForDataLayer);
    }
};
export var sendclipCouponData = function (coupon) {
    var _a, _b;
    var productData = getProductArray();
    var productsAdded = __assign({}, (_a = productData === null || productData === void 0 ? void 0 : productData.products) === null || _a === void 0 ? void 0 : _a[0]);
    var prodName = productsAdded === null || productsAdded === void 0 ? void 0 : productsAdded.itemName;
    var couponDetails = {
        brandName: coupon === null || coupon === void 0 ? void 0 : coupon.brandName,
        category: (_b = coupon === null || coupon === void 0 ? void 0 : coupon.offerRewards) === null || _b === void 0 ? void 0 : _b.categoryName,
        offerCode: coupon === null || coupon === void 0 ? void 0 : coupon.offerCode,
        offerDescription: coupon === null || coupon === void 0 ? void 0 : coupon.offerDescription,
        pageName: 'PDP'
    };
    var dataForDataLayer = {
        event: 'buttonClickClipCoupon',
        page: { page: 'PDP', prodName: prodName },
        products: productData.products,
        coupon: couponDetails,
        user: productData === null || productData === void 0 ? void 0 : productData.user
    };
    sendDataToDataLayer && sendDataToDataLayer(dataForDataLayer);
};
