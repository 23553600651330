import { environmentConst } from '../config/environments/constants';
import { parseCookie } from './helper';
export var getkillSwitchBNPL = function (conf) {
    var _a, _b, _c, _d;
    var killSwitchBNPL = (_d = (_c = (_b = (_a = conf === null || conf === void 0 ? void 0 : conf.storeConf) === null || _a === void 0 ? void 0 : _a.resultList) === null || _b === void 0 ? void 0 : _b[0]) === null || _c === void 0 ? void 0 : _c.userData) === null || _d === void 0 ? void 0 : _d.killSwitchBNPL;
    return killSwitchBNPL;
};
export var getKillSwitchSubscription = function (conf) {
    var _a, _b, _c, _d;
    var killSwitchSubscription = (_d = (_c = (_b = (_a = conf === null || conf === void 0 ? void 0 : conf.storeConf) === null || _a === void 0 ? void 0 : _a.resultList) === null || _b === void 0 ? void 0 : _b[0]) === null || _c === void 0 ? void 0 : _c.userData) === null || _d === void 0 ? void 0 : _d.killSwitchSubscription;
    return killSwitchSubscription;
};
export var getKillSwitchBOPIC = function (conf) {
    var _a, _b, _c, _d;
    var killSwitchBOPIC = (_d = (_c = (_b = (_a = conf === null || conf === void 0 ? void 0 : conf.storeConf) === null || _a === void 0 ? void 0 : _a.resultList) === null || _b === void 0 ? void 0 : _b[0]) === null || _c === void 0 ? void 0 : _c.userData) === null || _d === void 0 ? void 0 : _d.killSwitchBOPIC;
    return killSwitchBOPIC;
};
export var getKillSwitchSDD = function (conf) {
    var _a, _b, _c, _d;
    var killSwitchSDD = (_d = (_c = (_b = (_a = conf === null || conf === void 0 ? void 0 : conf.storeConf) === null || _a === void 0 ? void 0 : _a.resultList) === null || _b === void 0 ? void 0 : _b[0]) === null || _c === void 0 ? void 0 : _c.userData) === null || _d === void 0 ? void 0 : _d.killSwitchSDD;
    return killSwitchSDD;
};
export var getKillSwitchMaxWeightedItem = function (conf) {
    var _a, _b, _c, _d;
    var maxWeightedItem = (_d = (_c = (_b = (_a = conf === null || conf === void 0 ? void 0 : conf.storeConf) === null || _a === void 0 ? void 0 : _a.resultList) === null || _b === void 0 ? void 0 : _b[0]) === null || _c === void 0 ? void 0 : _c.userData) === null || _d === void 0 ? void 0 : _d.MAX_WEIGHTED_AMOUNT;
    return maxWeightedItem;
};
export var getKillSwitchSponsoredBanner = function (conf) {
    var _a, _b, _c, _d, _e, _f, _g, _h;
    return (((_d = (_c = (_b = (_a = conf === null || conf === void 0 ? void 0 : conf.storeConf) === null || _a === void 0 ? void 0 : _a.resultList) === null || _b === void 0 ? void 0 : _b[0]) === null || _c === void 0 ? void 0 : _c.userData) === null || _d === void 0 ? void 0 : _d.promoteIQWebPdpBanner) === 'OFF' &&
        ((_h = (_g = (_f = (_e = conf.storeConf) === null || _e === void 0 ? void 0 : _e.resultList) === null || _f === void 0 ? void 0 : _f[0]) === null || _g === void 0 ? void 0 : _g.userData) === null || _h === void 0 ? void 0 : _h.killSwitchWebRmp) === 'OFF');
};
export var getKillSwitchForBopicProductLocations = function (conf) {
    var _a, _b, _c, _d;
    var memberLocation = ((_d = (_c = (_b = (_a = conf === null || conf === void 0 ? void 0 : conf.storeConf) === null || _a === void 0 ? void 0 : _a.resultList) === null || _b === void 0 ? void 0 : _b[0]) === null || _c === void 0 ? void 0 : _c.userData) === null || _d === void 0 ? void 0 : _d.killSwitchProdlocSegCheck) === 'OFF';
    return memberLocation;
};
export var getProductLocationList = function (conf) {
    var _a, _b, _c, _d, _e, _f;
    var productLocationDetails = (_d = (_c = (_b = (_a = conf === null || conf === void 0 ? void 0 : conf.storeConf) === null || _a === void 0 ? void 0 : _a.resultList) === null || _b === void 0 ? void 0 : _b[0]) === null || _c === void 0 ? void 0 : _c.userData) === null || _d === void 0 ? void 0 : _d.productlocdisplayclublist;
    if (((_e = productLocationDetails === null || productLocationDetails === void 0 ? void 0 : productLocationDetails.toString()) === null || _e === void 0 ? void 0 : _e.includes(parseCookie(environmentConst.WC_PICKUP_STORE))) ||
        ((_f = productLocationDetails === null || productLocationDetails === void 0 ? void 0 : productLocationDetails.toString()) === null || _f === void 0 ? void 0 : _f.includes('all'))) {
        return true;
    }
    else {
        return false;
    }
};
export var getKillSwitchFbt = function (conf) {
    var _a, _b, _c, _d, _e, _f, _g, _h;
    return (((_d = (_c = (_b = (_a = conf === null || conf === void 0 ? void 0 : conf.storeConf) === null || _a === void 0 ? void 0 : _a.resultList) === null || _b === void 0 ? void 0 : _b[0]) === null || _c === void 0 ? void 0 : _c.userData) === null || _d === void 0 ? void 0 : _d.PromoteIQWebRecomendationCarousel) === 'OFF' &&
        ((_h = (_g = (_f = (_e = conf.storeConf) === null || _e === void 0 ? void 0 : _e.resultList) === null || _f === void 0 ? void 0 : _f[0]) === null || _g === void 0 ? void 0 : _g.userData) === null || _h === void 0 ? void 0 : _h.killSwitchWebRmp) === 'OFF');
};
