var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React, { useContext } from 'react';
import styled from 'styled-components';
import { PdpContext } from '../../store/pdp/PdpContext';
import { fetchUpdatedCookie, isBrowser } from '../../utils/helper';
import { environmentConst } from '../../config/environments/constants';
var ShopBrandStyle = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  .shopbrand {\n    color: #303030;\n    font-size: 12px;\n    font-weight: normal;\n    letter-spacing: 0;\n    text-decoration: underline;\n    line-height: 10px;\n    cursor: pointer;\n    margin: 0px 0px 10px;\n    font-family: 'Roboto', sans-serif;\n    display: block;\n    @media (max-width: 767px) {\n      font-size: 14px;\n      margin: 0px 0px 12px 5px;\n    }\n  }\n  .brand-name {\n    text-transform: capitalize;\n  }\n"], ["\n  .shopbrand {\n    color: #303030;\n    font-size: 12px;\n    font-weight: normal;\n    letter-spacing: 0;\n    text-decoration: underline;\n    line-height: 10px;\n    cursor: pointer;\n    margin: 0px 0px 10px;\n    font-family: 'Roboto', sans-serif;\n    display: block;\n    @media (max-width: 767px) {\n      font-size: 14px;\n      margin: 0px 0px 12px 5px;\n    }\n  }\n  .brand-name {\n    text-transform: capitalize;\n  }\n"])));
var ShopBrand = function () {
    var _a;
    var productData = useContext(PdpContext);
    var formattedProductData = productData === null || productData === void 0 ? void 0 : productData.productData;
    var shopBrand = formattedProductData === null || formattedProductData === void 0 ? void 0 : formattedProductData.shopBrand;
    var ShopBrandURL = fetchUpdatedCookie(environmentConst.PLP_COOKIE) !== 'B'
        ? (_a = shopBrand === null || shopBrand === void 0 ? void 0 : shopBrand.url) !== null && _a !== void 0 ? _a : ''
        : "/search/?facet=mfname:" + (shopBrand === null || shopBrand === void 0 ? void 0 : shopBrand.brandName) + "&rmp=no";
    return (React.createElement(React.Fragment, null,
        React.createElement(ShopBrandStyle, null, shopBrand && isBrowser() && (React.createElement("a", { className: "shopbrand", href: ShopBrandURL, "auto-data": "product_shop_brand" },
            "Shop ",
            React.createElement("span", { className: "brand-name" }, shopBrand === null || shopBrand === void 0 ? void 0 : shopBrand.brandName))))));
};
export default ShopBrand;
var templateObject_1;
