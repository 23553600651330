var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from 'react';
import { Col, Modal, Row } from 'react-bootstrap';
import styled from 'styled-components';
import { SharedStyledButton } from '../shared/SharedStyledButton';
import fbtError from '../../assets/images/icons/fbt-error.svg';
import fbtSuccess from '../../assets/images/icons/fbt-success.svg';
var StyledTitle = styled.h4(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  font-size: 24px;\n  text-align: center;\n  font-weight: 700;\n"], ["\n  font-size: 24px;\n  text-align: center;\n  font-weight: 700;\n"])));
var StyledCloseButton = styled.button(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  opacity: 1;\n"], ["\n  opacity: 1;\n"])));
var StyledModalHeader = styled(Modal.Header)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  display: block;\n  border-bottom: none;\n  padding-top: 8px;\n  padding-bottom: 0;\n"], ["\n  display: block;\n  border-bottom: none;\n  padding-top: 8px;\n  padding-bottom: 0;\n"])));
var StyledModal = styled(Modal)(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  font-family: Roboto, Helvetica Neue, Helvetica, Arial, sans-serif;\n  text-rendering: optimizeLegibility;\n  .modal-content {\n    border-radius: 0;\n    border: none;\n  }\n  .modal-dialog {\n    @media (min-width: 576px) {\n      max-width: 325px;\n    }\n  }\n  .modal-body {\n    padding: 0 1rem 2rem;\n  }\n  .msg-state {\n    font-weight: bold;\n    margin-left: 10px;\n    line-height: 24px;\n    .noItems {\n      font-size: 18px;\n    }\n    .message {\n      font-size: 14px;\n    }\n  }\n"], ["\n  font-family: Roboto, Helvetica Neue, Helvetica, Arial, sans-serif;\n  text-rendering: optimizeLegibility;\n  .modal-content {\n    border-radius: 0;\n    border: none;\n  }\n  .modal-dialog {\n    @media (min-width: 576px) {\n      max-width: 325px;\n    }\n  }\n  .modal-body {\n    padding: 0 1rem 2rem;\n  }\n  .msg-state {\n    font-weight: bold;\n    margin-left: 10px;\n    line-height: 24px;\n    .noItems {\n      font-size: 18px;\n    }\n    .message {\n      font-size: 14px;\n    }\n  }\n"])));
var StyledImage = styled.img(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  margin-left: -10px;\n  width: 43px;\n  height: 43px;\n  border-radius: 50%;\n  border: 0.1px solid #4b8510;\n  &.error-state-icon {\n    border: 0;\n  }\n  ~ p {\n    margin-bottom: 0;\n    color: #0a0a0a;\n    font-size: 14px;\n    font-weight: 500;\n    line-height: 16px;\n    margin-top: 0;\n  }\n"], ["\n  margin-left: -10px;\n  width: 43px;\n  height: 43px;\n  border-radius: 50%;\n  border: 0.1px solid #4b8510;\n  &.error-state-icon {\n    border: 0;\n  }\n  ~ p {\n    margin-bottom: 0;\n    color: #0a0a0a;\n    font-size: 14px;\n    font-weight: 500;\n    line-height: 16px;\n    margin-top: 0;\n  }\n"])));
var StyledButton = styled(SharedStyledButton)(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  width: 77%;\n  @media only screen and (min-width: 767px) {\n    width: 78%;\n  }\n"], ["\n  width: 77%;\n  @media only screen and (min-width: 767px) {\n    width: 78%;\n  }\n"])));
var StyledTextSm = styled.p(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  font-size: 12px;\n  line-height: 21px;\n  color: #000;\n  margin-bottom: 0;\n"], ["\n  font-size: 12px;\n  line-height: 21px;\n  color: #000;\n  margin-bottom: 0;\n"])));
var FBTModal = function (props) {
    var show = props.show, backdrop = props.backdrop, onHide = props.onHide, resultCount = props.resultCount;
    var navigateTo = function (url) {
        if (typeof window !== 'undefined') {
            window.location.href = url;
        }
    };
    return (React.createElement(StyledModal, { show: show, backdrop: backdrop, centered: true },
        React.createElement(StyledModalHeader, null,
            React.createElement(StyledCloseButton, { type: "button", className: "close", onClick: onHide },
                React.createElement("img", { src: "/assets/images/icons/X.svg", alt: "close" }))),
        React.createElement(Modal.Body, null,
            React.createElement(Row, null,
                React.createElement(Col, null,
                    React.createElement("div", { className: "d-flex justify-content-center align-items-start" },
                        resultCount > 0 ? (React.createElement(StyledImage, { src: fbtSuccess, alt: "Success" })) : (React.createElement(StyledImage, { src: fbtError, alt: "Error", className: "error-state-icon" })),
                        React.createElement("p", { className: "text-left msg-state" },
                            React.createElement("span", { className: "noItems " }, resultCount > 0
                                ? resultCount + " " + (resultCount > 1 ? 'Items' : 'Item')
                                : resultCount + " Items Added"),
                            React.createElement("br", null),
                            resultCount > 0 ? (React.createElement("span", { className: "message" }, "Successfully added to cart")) : (React.createElement("span", { className: "message", style: { marginRight: '40px' } },
                                "Unable to add to cart",
                                ' ')))))),
            React.createElement(Row, { className: "mt-3 text-center" },
                React.createElement(Col, null,
                    React.createElement(StyledButton, { "auto-data": "product_sddFF_SignINBtn", variant: "primary", className: "btn-block mt-2 mx-auto", onClick: onHide }, "Continue Shopping"),
                    React.createElement(StyledButton, { "auto-data": "product_sddFF_JoinNow", variant: "outline-secondary", className: "btn-block mt-3 mx-auto", onClick: function () { return (window.location.href = '/cart'); } }, "View cart"))))));
};
export default FBTModal;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7;
