import React, { useContext, useEffect } from 'react';
import { PdpContext } from '../../store/pdp/PdpContext';
import { environmentConst as environment } from '../../config/environments/constants';
var CnetContent = function () {
    var _a;
    var productData = useContext(PdpContext);
    var productDetailsData = (_a = productData === null || productData === void 0 ? void 0 : productData.data) === null || _a === void 0 ? void 0 : _a.productDetailsData;
    var descriptiveAttributes = (productDetailsData || null).descriptiveAttributes;
    useEffect(function () {
        var cnetContent_scriptTag = document.createElement('script');
        cnetContent_scriptTag.async = true;
        cnetContent_scriptTag.type = 'text/javascript';
        if ((productDetailsData.manufacturerName != null || undefined) &&
            (productDetailsData.manufacturerPartNumber != null || undefined)) {
            //let productManufacturerName  =  'Sony' ;// productDetailsData.manufacturerName.replace(/[,<>!;%$#*?@+&^=:.~'"\/\\\{\}\(\)\u00ae\u00a9\u2122]/g, '');
            //let productModel  =  'KD65X80CK'; // productDetailsData.manufacturerPartNumber.slice(7);
            var productManufacturerName = productDetailsData.manufacturerName.replace(/[,<>!;%$#*?@+&^=:.~'"\/\\\{\}\(\)\u00ae\u00a9\u2122]/g, '');
            var productModel = productDetailsData.manufacturerPartNumber;
            var appendingValue = environment.CNET_SCRIPT_CONTENT.partOne +
                productManufacturerName +
                environment.CNET_SCRIPT_CONTENT.partTwo +
                productModel +
                environment.CNET_SCRIPT_CONTENT.partThree;
            if (appendingValue != null) {
                cnetContent_scriptTag.innerText = appendingValue;
            }
        }
        document.getElementById('cnet-media-content').appendChild(cnetContent_scriptTag);
        return function () {
            document.getElementById('cnet-media-content').removeChild(cnetContent_scriptTag);
        };
    }, []);
    return (React.createElement("div", { id: "cnet-media-content" },
        React.createElement("div", { id: "ccs-cnet-reviews" }),
        React.createElement("div", { id: "ccs-inline-content" })));
};
export default CnetContent;
