var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { createContext, useReducer } from 'react';
var Store = createContext(null);
export var useFulfillmentStore = function () { return React.useContext(Store); };
export var FulfillmentContextProvider = function (_a) {
    var children = _a.children, initialState = _a.initialState, reducer = _a.reducer;
    var _b = __read(useReducer(reducer, initialState), 2), globalState = _b[0], dispatch = _b[1];
    return React.createElement(Store.Provider, { value: [globalState, dispatch] }, children);
};
