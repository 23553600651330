var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from 'react';
import FlixMedia from './FlixMedia';
import styled from 'styled-components';
import SyndigoContent from './SyndigoContent';
import CnetContent from './CnetContent';
import SalsifyContent from './SalsifyIntegrations';
var ProductSummaryInt = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  #enhanced-content {\n    display: block !important;\n    @media (max-width: 767px) {\n      display: none !important;\n    }\n  }\n"], ["\n  #enhanced-content {\n    display: block !important;\n    @media (max-width: 767px) {\n      display: none !important;\n    }\n  }\n"])));
var ProductSummaryIntegrations = function () {
    return (React.createElement(ProductSummaryInt, null,
        React.createElement("div", { id: "enhanced-content" },
            React.createElement(FlixMedia, null),
            React.createElement(SyndigoContent, null),
            React.createElement(CnetContent, null),
            React.createElement(SalsifyContent, null))));
};
export default ProductSummaryIntegrations;
var templateObject_1;
