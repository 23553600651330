import React, { useContext, useEffect } from 'react';
import { PdpContext } from '../../store/pdp/PdpContext';
import { environmentConst as environment } from '../../config/environments/constants';
import salsify from 'salsify-experiences-sdk';
import { logInfo } from '@bjs/shared-micro-frontend';
var SalsifyContent = function () {
    var _a;
    var productData = useContext(PdpContext);
    var productDetailsData = (_a = productData === null || productData === void 0 ? void 0 : productData.data) === null || _a === void 0 ? void 0 : _a.productDetailsData;
    var itemPartNumber = getProductItemNumber(productDetailsData);
    useEffect(function () {
        if (itemPartNumber != null || undefined) {
            salsify.init(environment.SALSIFY);
            var exists = salsify.enhancedContent.exists(itemPartNumber);
            if (exists) {
                salsify.enhancedContent.renderIframe = function (container, productId, idType) {
                    idType = this.checkAndGetIdType(idType);
                    attachIframeResizeListener();
                    var iframe = this.createIframe();
                    iframe.src = buildContentUrl(productId, idType);
                    iframe.title = 'Salsify Enhanced Content';
                    container.appendChild(iframe);
                    this.logger.log('ec_render_iframe', { idType: idType, productId: productId });
                };
                salsify.enhancedContent.renderIframe(document.querySelector('#ec-container'), itemPartNumber);
            }
            else {
                logInfo && logInfo('log', 'Salsify content does not exists for for product id :', +itemPartNumber);
            }
        }
    }, []);
    return React.createElement("div", { id: "ec-container" });
};
var iframeId = 'salsify-ec-iframe';
var prodCdnOrigin = 'https://salsify-ecdn.com';
var stagingCdnOrigin = 'https://staging.salsify-ecdn.com';
var prodCdnPrefix = '';
var stagingCdnPrefix = '';
var attachedIframeEventListener = false;
var attachIframeResizeListener = function () {
    if (attachedIframeEventListener) {
        return;
    }
    window.addEventListener('message', function (event) {
        if (event.origin !== prodCdnOrigin && event.origin !== stagingCdnOrigin) {
            return;
        }
        var iframe = document.querySelector("#" + iframeId);
        if (iframe) {
            iframe.height = event.data.height;
        }
    });
    attachedIframeEventListener = true;
};
var buildContentUrl = function (productId, idType) {
    var cdnOrigin = environment.SALSIFY.staging ? stagingCdnOrigin : prodCdnOrigin;
    return cdnOrigin + "/sdk/" + environment.SALSIFY.clientId + "/" + environment.SALSIFY.languageCode + "/BTF/" + idType + "/" + productId + "/index.html";
};
var getProductItemNumber = function (productDetails) {
    var productItemNumber = '';
    if ((productDetails === null || productDetails === void 0 ? void 0 : productDetails.definingAttributes) && (productDetails === null || productDetails === void 0 ? void 0 : productDetails.definingAttributes.length) > 0) {
        var currentProduct = productDetails === null || productDetails === void 0 ? void 0 : productDetails.entitledItems.find(function (p) { return p.itemID === productDetails.itemPrices.defaultItemId; });
        if (currentProduct) {
            productItemNumber = currentProduct.articleId;
        }
    }
    else {
        productItemNumber = (productDetails === null || productDetails === void 0 ? void 0 : productDetails.entitledItems[0].articleId) || '';
    }
    return productItemNumber;
};
export default SalsifyContent;
