import React, { useContext, useEffect } from 'react';
import { PdpContext } from '../../store/pdp/PdpContext';
import { environmentConst as environment } from '../../config/environments/constants';
var SyndigoContent = function () {
    var _a;
    var productData = useContext(PdpContext);
    var productDetailsData = (_a = productData === null || productData === void 0 ? void 0 : productData.data) === null || _a === void 0 ? void 0 : _a.productDetailsData;
    var itemPartNumber = getProductItemNumber(productDetailsData);
    useEffect(function () {
        var sellpoint_primary_scriptTag = document.createElement('script');
        var sellpoint_secondary_scriptTag = document.createElement('script');
        var sellpoint_syndigo_tag = document.createElement('syndigo-powerpage');
        var sellpoint_syndigo_hero_image_tag = document.createElement('syndigo-mosaic');
        sellpoint_primary_scriptTag.innerHTML =
            environment.SELLPOINT_SCRIPT_FUNCTION.desktopDetails.callFunctionDesktop;
        sellpoint_primary_scriptTag.async = true;
        sellpoint_primary_scriptTag.type = 'text/javascript';
        document.getElementById('sellpoint').appendChild(sellpoint_primary_scriptTag);
        var str = environment.SELLPOINT_SCRIPT_FUNCTION.commonSrc.partOne +
            itemPartNumber +
            environment.SELLPOINT_SCRIPT_FUNCTION.commonSrc.partTwo;
        sellpoint_secondary_scriptTag.innerHTML = str;
        sellpoint_secondary_scriptTag.async = true;
        sellpoint_secondary_scriptTag.type = 'text/javascript';
        document.getElementById('sellpoint').appendChild(sellpoint_secondary_scriptTag);
        sellpoint_syndigo_tag.setAttribute('pageid', itemPartNumber);
        sellpoint_syndigo_hero_image_tag.setAttribute('pageid', itemPartNumber);
        document.getElementById('sellpoint').appendChild(sellpoint_syndigo_tag);
        document.getElementById('syndigo-hero-image').appendChild(sellpoint_syndigo_hero_image_tag);
        return function () {
            document.getElementById('sellpoint').removeChild(sellpoint_primary_scriptTag);
            document.getElementById('sellpoint').removeChild(sellpoint_secondary_scriptTag);
            document.getElementById('sellpoint').removeChild(sellpoint_syndigo_tag);
            document.getElementById('sellpoint').removeChild(sellpoint_syndigo_tag);
            document.getElementById('syndigo-hero-image').removeChild(sellpoint_syndigo_hero_image_tag);
        };
    }, []);
    return (React.createElement("div", { id: "sellpoint" },
        React.createElement("div", null,
            React.createElement("div", { id: "sp_inline_product" }),
            React.createElement("div", { id: "SP_ProductImage" }),
            React.createElement("div", { id: "SP_ACPage" }),
            React.createElement("div", { id: "SP_Tour" }))));
};
var getProductItemNumber = function (productDetails) {
    var productItemNumber = '';
    if ((productDetails === null || productDetails === void 0 ? void 0 : productDetails.definingAttributes) && (productDetails === null || productDetails === void 0 ? void 0 : productDetails.definingAttributes.length) > 0) {
        var currentProduct = productDetails === null || productDetails === void 0 ? void 0 : productDetails.entitledItems.find(function (p) { return p.itemID === productDetails.itemPrices.defaultItemId; });
        if (currentProduct) {
            productItemNumber = currentProduct.articleId;
        }
    }
    else {
        productItemNumber = (productDetails === null || productDetails === void 0 ? void 0 : productDetails.entitledItems[0].articleId) || '';
    }
    return productItemNumber;
};
export default SyndigoContent;
