var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { useContext, useState } from 'react';
import styled from 'styled-components';
import { useCookies } from 'react-cookie';
import { PdpContext } from '../../../../store/pdp/PdpContext';
import { environmentConst } from '../../../../config/environments/constants';
import { useFulfillmentStore } from '../../../../store/fulfillment/FulfillmentProvider';
import { setFullFillmentData, setSddAddressNotSelected, setSddFromAddCart } from '../../../../store/cart/CartContext';
import { fetchUpdatedCookie } from '../../../../utils/helper';
import { useStore } from '../../../../store/cart/CartProvider';
import { setSddZipCode } from '../../../../store/fulfillment/FulfillmentContext';
import HowItWorksModal from '../../../modals/HowItWorks';
import CustomPopover from '../../../shared/CustomPopover';
import useMediaQuery from '../../../../hooks/useMediaQuery';
import format from 'date-fns/format';
import TickICO from '../../../../assets/images/icons/tick-green.svg';
import LocationICO from '../../../../assets/images/icons/Location.svg';
import { atcDisableEvent } from '../../../MainLayout/AddToCart';
import { SignInModal, SDDAddressModal } from '@bjs/shared-micro-frontend';
// import Cookies from 'react-cookie';
var triggerAddCart = function (value) {
    var event = new CustomEvent('add-cart-event', {
        detail: {
            value: value
        }
    });
    document.dispatchEvent(event);
};
var SddFullfillmentWrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  .service-fee-text {\n    font-size: 14px;\n  }\n  .hiw-text {\n    padding-left: 20px;\n  }\n  .hiw-link {\n    margin-right: 10px;\n    text-decoration: underline;\n  }\n  .fa-angle-up,\n  .fa-angle-down {\n    font-size: 20px;\n    color: rgb(48, 48, 48);\n    font-weight: bold;\n  }\n"], ["\n  .service-fee-text {\n    font-size: 14px;\n  }\n  .hiw-text {\n    padding-left: 20px;\n  }\n  .hiw-link {\n    margin-right: 10px;\n    text-decoration: underline;\n  }\n  .fa-angle-up,\n  .fa-angle-down {\n    font-size: 20px;\n    color: rgb(48, 48, 48);\n    font-weight: bold;\n  }\n"])));
var StyledSubPara = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  font-style: italic;\n  font-size: 10px;\n"], ["\n  font-style: italic;\n  font-size: 10px;\n"])));
export var SDDFulfillment = function (_a) {
    var _b;
    var handleNotServiceable = _a.handleNotServiceable;
    var _c = __read(React.useState({
        howItWork: false,
        signin: false,
        address: false
    }), 2), modalShow = _c[0], setModalShow = _c[1];
    var fullfillmentStore = useFulfillmentStore();
    var _d = __read(fullfillmentStore, 2), fullfillmentState = _d[0], fullfillmentDispatch = _d[1];
    var sddFulfillmentData = fullfillmentState.fulfillmentSdd;
    var _e = __read(useStore(), 2), state = _e[0], dispatch = _e[1];
    var isSddAddressNotSelected = state.isSddAddressNotSelected, isSddFromAddCart = state.isSddFromAddCart;
    var displayItem = sddFulfillmentData.displayItem, isInventoryAvailable = sddFulfillmentData.isInventoryAvailable;
    var _f = __read(useCookies(), 3), cookies = _f[0], setCookie = _f[1], removeCookie = _f[2];
    var pdpZipNotServiceable = fetchUpdatedCookie(environmentConst.PDP_ZIP_NOT_SERVICEABLE);
    var pdpContext = useContext(PdpContext);
    var isProductHeavyItem = (_b = pdpContext === null || pdpContext === void 0 ? void 0 : pdpContext.productData) === null || _b === void 0 ? void 0 : _b.isProductHeavyItem;
    var _g = __read(useState(false), 2), isAngleIconUp = _g[0], setIsAngleIconUp = _g[1];
    var toggleAngleIcon = function () {
        if (isAngleIconUp) {
            setIsAngleIconUp(false);
        }
        else {
            setIsAngleIconUp(true);
        }
    };
    var matches = useMediaQuery('(min-width: 768px)');
    var freePickUpDate = format(new Date(), 'MMM d, y, h:mm:ss a');
    var freePickupInfo = "We do our best to update club pricing and inventory amounts as they change. However, there may be slight differences in actual club pricing and inventory levels. Therefore, the price reflected and the inventory is estimated as of -dateTime- and does not include taxes or application of coupons. Coupons are subject to valid dates and the terms of the coupon offer.".replace('-dateTime-', "" + freePickUpDate);
    var getZipCode = function () {
        if (typeof window !== 'undefined') {
            var zipCode_1 = cookies[environmentConst.CLUB_ZIP_CODE_COOKIE];
            var userSigninResponse = fetchUpdatedCookie(environmentConst.USER_SIGNIN_RESPONSE_COOKIE);
            if (userSigninResponse === null || userSigninResponse === void 0 ? void 0 : userSigninResponse.sddZipCode) {
                zipCode_1 = userSigninResponse.sddZipCode;
            }
            if (zipCode_1) {
                return zipCode_1;
            }
        }
        return null;
    };
    var zipCode = getZipCode();
    var handleZipCodeClick = function () {
        var userSigninResponse = fetchUpdatedCookie(environmentConst.USER_SIGNIN_RESPONSE_COOKIE);
        if (userSigninResponse) {
            setModalShow(__assign(__assign({}, modalShow), { address: true }));
        }
        else {
            setModalShow(__assign(__assign({}, modalShow), { signin: true }));
        }
    };
    var setIsSddSuccess = function (sddVal) {
        if (sddVal) {
            var userSigninResponse = fetchUpdatedCookie(environmentConst.USER_SIGNIN_RESPONSE_COOKIE);
            if (userSigninResponse === null || userSigninResponse === void 0 ? void 0 : userSigninResponse.sddZipCode) {
                fullfillmentDispatch(setSddZipCode(userSigninResponse.sddZipCode));
            }
            if (isSddFromAddCart) {
                triggerAddCart(true);
            }
            dispatch(setSddFromAddCart(false));
            dispatch(setSddAddressNotSelected(false));
            setModalShow(__assign(__assign({}, modalShow), { address: false }));
        }
        else {
            pdpZipNotServiceable = fetchUpdatedCookie(environmentConst.PDP_ZIP_NOT_SERVICEABLE);
            if (pdpZipNotServiceable === 'true') {
                dispatch(setSddAddressNotSelected(false));
                dispatch(setSddFromAddCart(false));
            }
        }
        var data = { fullFillmentType: 'SDD', isUpdate: true };
        dispatch(setFullFillmentData(data));
    };
    React.useEffect(function () {
        if (isSddAddressNotSelected) {
            setModalShow(__assign(__assign({}, modalShow), { address: true }));
        }
    }, [isSddAddressNotSelected]);
    var HeavyItemHowItWorksToolTip = function () {
        return (React.createElement(React.Fragment, null,
            React.createElement("h5", null, "How It Works"),
            React.createElement("p", null, "Shop items for delivery, then choose when you\u2019d like to receive them at checkout. We\u2019ll deliver right to your door!"),
            React.createElement("p", null, "You may see increased delivery times and service fees because of the contents of your cart. Orders with large or high value items require additional care leading to an increase in fees and are not eligible for Same-Day Select. Service fees are calculated based on order size and distance from the club."),
            React.createElement(StyledSubPara, null,
                React.createElement("p", null, "Only credit or debit card payments are accepted for orders including Same-Day Delivery."),
                React.createElement("p", null, "Certain products are ineligible for Same-Day Select because of size and/or item value; including an ineligible item in your purchase transaction will make the entire transaction ineligible for Same-Day Select. \""))));
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(SddFullfillmentWrapper, null,
            displayItem && (React.createElement("div", { className: "fullfillment-sameday" },
                React.createElement("div", { className: "sd-pincode", "auto-data": "product_fulfillment_sddMsg" },
                    pdpZipNotServiceable && pdpZipNotServiceable === 'true' && isInventoryAvailable ? (React.createElement("span", { style: { color: '#9C0228' } }, "Unavailable for Same-Day Delivery\u00A0")) : (React.createElement("span", null, "Same-Day Delivery\u00A0")),
                    React.createElement("span", { className: "reg-font" }, "to\u00A0"),
                    zipCode,
                    React.createElement("img", { src: LocationICO }),
                    isProductHeavyItem && (React.createElement("a", { className: "location-edit-link", "auto-data": "product_sddFF_editAddress", onClick: handleZipCodeClick, style: {
                            cursor: 'pointer',
                            fontFamily: "'Roboto', sans-serif",
                            fontSize: '14px',
                            color: 'rgb(48, 48, 48)',
                            fontWeight: 400
                        } }, "Edit Address"))),
                React.createElement("div", { className: "sd-hw-t-works" },
                    isProductHeavyItem && (React.createElement(React.Fragment, null,
                        React.createElement("span", { className: "service-fee-text" }, "Service Fee Calculated in Cart."),
                        React.createElement("span", { className: "service-fee-text hiw-text" },
                            React.createElement("span", { className: "hiw-link", onClick: toggleAngleIcon },
                                React.createElement(CustomPopover, { trigger: "click", dismiss: "click", maxWidth: "460px", data: React.createElement(HeavyItemHowItWorksToolTip, null) }, "How It Works")),
                            isAngleIconUp ? (React.createElement("i", { className: "fa fa-angle-up" })) : (React.createElement("i", { className: "fa fa-angle-down" }))))),
                    !isProductHeavyItem && (React.createElement(React.Fragment, null,
                        React.createElement("a", { className: "hiw-link", "auto-data": "product_fulfillment_sddHowItWorks", style: {
                                paddingRight: "30px"
                            } },
                            React.createElement("span", { style: {
                                    cursor: 'pointer',
                                    fontFamily: "'Roboto', sans-serif"
                                }, onClick: function () { return setModalShow(__assign(__assign({}, modalShow), { howItWork: true })); } }, "How It Works")),
                        React.createElement("a", { className: "location-edit-link", "auto-data": "product_sddFF_editAddress" },
                            React.createElement("span", { onClick: handleZipCodeClick, style: {
                                    cursor: 'pointer',
                                    fontFamily: "'Roboto', sans-serif"
                                } }, "Edit Address"))))),
                isInventoryAvailable && (React.createElement("div", { className: "inventory-available" },
                    React.createElement("ul", { className: "list-unstyled mb-0" },
                        React.createElement("li", null,
                            React.createElement("img", { src: TickICO }),
                            React.createElement("span", { className: "inStock" }, "In Stock"),
                            ' ',
                            React.createElement("span", { className: "cls-round-icon-header cls-round-underline pl-1", style: { position: 'relative', top: -'1px' } },
                                React.createElement(CustomPopover, { trigger: matches ? 'hover' : 'click', maxWidth: "172px", data: freePickupInfo },
                                    React.createElement("img", { src: "https://www.bjs.com/assets/images/icons/Information.svg", alt: "" }))))))),
                !isInventoryAvailable && (React.createElement("div", { className: "inventory-na-sdd" },
                    React.createElement("ul", { className: "list-unstyled mb-0" },
                        React.createElement("li", null,
                            React.createElement("i", { className: "fa fa-ban pr-8", "aria-hidden": "true", style: { fontSize: '13px', color: '#CC0033' } }),
                            React.createElement("span", { className: "soldout", style: {
                                    fontSize: '14px',
                                    fontFamily: '"Roboto", sans-serif',
                                    fontWeight: 400
                                } },
                                React.createElement("b", { style: {
                                        fontSize: '14px',
                                        fontFamily: '"Roboto", sans-serif',
                                        fontWeight: 700
                                    } }, "Sold Out")))))))),
            React.createElement(HowItWorksModal, { show: modalShow === null || modalShow === void 0 ? void 0 : modalShow.howItWork, onHide: function () { return setModalShow(__assign(__assign({}, modalShow), { howItWork: false })); } }),
            SignInModal && (React.createElement(SignInModal, { isOpen: modalShow === null || modalShow === void 0 ? void 0 : modalShow.signin, setIsOpen: function () { return setModalShow(__assign(__assign({}, modalShow), { signin: false })); }, setIsLoginSuccess: function (val) {
                    return val
                        ? setModalShow(__assign(__assign({}, modalShow), { address: true, signin: false }))
                        : setModalShow(__assign(__assign({}, modalShow), { signin: false }));
                }, fromPage: 'SDD' })),
            SDDAddressModal && (React.createElement(SDDAddressModal, { isOpen: modalShow === null || modalShow === void 0 ? void 0 : modalShow.address, setIsOpen: function () {
                    setModalShow(__assign(__assign({}, modalShow), { address: false }));
                    atcDisableEvent('disableATC', false);
                    dispatch(setSddAddressNotSelected(false));
                }, setIsSuccess: function (val) { return setIsSddSuccess(val); } })))));
};
var templateObject_1, templateObject_2;
