import { sendDataToDataLayer, getuserInformation } from '@bjs/shared-micro-frontend';
import { environmentConst as environment } from '../config/environments/constants';
export var setDataLayer = function (data, userSigninResponse, personalizationID_live, clubName, uuID, eventName) {
    var _a, _b, _c, _d;
    var breadcrumb = document.getElementById('breadcrumb');
    var productFindingMethod = sessionStorage.getItem(environment.PRODUCT_FINDING_METHOD_SESSION_COOKIE);
    var productFindingSubMethod = sessionStorage.getItem(environment.PRODUCT_FINDING_SUB_METHOD_SESSION_COOKIE);
    var productObj = {
        action: 'detail',
        articleId: data === null || data === void 0 ? void 0 : data.articleId,
        availability: undefined,
        brand: (_a = data === null || data === void 0 ? void 0 : data.shopBrand) === null || _a === void 0 ? void 0 : _a.brandName,
        breadcrumb: (_b = breadcrumb === null || breadcrumb === void 0 ? void 0 : breadcrumb.innerText) === null || _b === void 0 ? void 0 : _b.replace(/\n/gi, '/'),
        category: data === null || data === void 0 ? void 0 : data.immediateParentName,
        itemCatentryId: data === null || data === void 0 ? void 0 : data.catEntryId,
        itemName: data === null || data === void 0 ? void 0 : data.productName,
        itemPartNumber: data === null || data === void 0 ? void 0 : data.itemPartNumber,
        location: 'PDP',
        orderType: data === null || data === void 0 ? void 0 : data.orderType,
        position: 0,
        price: data === null || data === void 0 ? void 0 : data.price,
        productCatentryId: data === null || data === void 0 ? void 0 : data.productCatentryId,
        productImageURL: data === null || data === void 0 ? void 0 : data.productImage,
        productPageURL: window.location.href.replace(window.location.origin, ''),
        productPartNumber: data === null || data === void 0 ? void 0 : data.partNumber,
        rating: data === null || data === void 0 ? void 0 : data.averageRating,
        salePrice: data === null || data === void 0 ? void 0 : data.price,
        variant: data === null || data === void 0 ? void 0 : data.variant,
        productFindingMethod: productFindingMethod,
        productFindingSubMethod: productFindingSubMethod
    };
    if (data === null || data === void 0 ? void 0 : data.itemFulfillmentType) {
        productObj['itemFulfillmentType'] = data === null || data === void 0 ? void 0 : data.itemFulfillmentType;
    }
    if (data === null || data === void 0 ? void 0 : data.itemFulfillmentOptions) {
        productObj['itemFulfillmentOptions'] = data === null || data === void 0 ? void 0 : data.itemFulfillmentOptions;
    }
    if (data === null || data === void 0 ? void 0 : data.inclubdetails) {
        productObj['inclubdetails'] = data === null || data === void 0 ? void 0 : data.inclubdetails;
    }
    var prodName = (_d = (_c = data === null || data === void 0 ? void 0 : data.productName) === null || _c === void 0 ? void 0 : _c.replace(/[^.A-Z0-9]/gi, '-')) === null || _d === void 0 ? void 0 : _d.toLowerCase();
    var dataForDataLayer = {
        event: eventName || 'prodView',
        page: { page: 'PDP', prodName: prodName },
        products: [productObj],
        user: getuserInformation &&
            getuserInformation(userSigninResponse, personalizationID_live, clubName, uuID)
    };
    sendDataToDataLayer && sendDataToDataLayer(dataForDataLayer);
    return null;
};
export var sponsoredBannerEvent = function (piqData, ECID, userDetails, event) {
    var userSigninResponse = userDetails.userSigninResponse, personalizationID_live = userDetails.personalizationID_live, clubName = userDetails.clubName, uuID = userDetails.uuID;
    // @ts-ignore
    if (window === null || window === void 0 ? void 0 : window.adobeDataLayer) {
        var dataForDataLayer = {
            event: event || 'sponsoredBannerImpression',
            page: { page: 'PDP' },
            user: getuserInformation &&
                getuserInformation(userSigninResponse, personalizationID_live, clubName, uuID)
        };
        if (piqData) {
            dataForDataLayer['sponsoredDetails'] = {
                campaign_id: piqData === null || piqData === void 0 ? void 0 : piqData.campaign_id,
                partner_id: piqData === null || piqData === void 0 ? void 0 : piqData.partner_id,
                placement_id: piqData === null || piqData === void 0 ? void 0 : piqData.placement_id,
                location: 'PDP'
            };
        }
        dataForDataLayer['ECID'] = ECID;
        sendDataToDataLayer && sendDataToDataLayer(dataForDataLayer);
    }
};
