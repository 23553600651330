import styled from 'styled-components';

export const NonBjsGcStyles = styled.div`
  .delivery-label {
    height: 19px;
    width: 350px;
    color: #303030;
    font-family: Roboto;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 19px;
  }

  .delivery-label-value {
    font-weight: bold;
  }

  .radio-btn {
    border: 1px solid #c03;
    background-color: #fff;
    position: absolute;
    height: 20px;
    width: 20px;
    border-radius: 50%;
    margin-top: 5px;
    cursor: pointer;
  }
  .radio-btn:after {
    top: 3px;
    left: 3px;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background: #c03;
    content: '';
    position: absolute;
  }
  .digital-email {
    margin-left: 25px;
    font-size: 14px;
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
  }

  .delivery-time {
    height: 57px;
    width: 248px;
    color: #303030 !important;
    font-family: Roboto;
    font-size: 12px;
    letter-spacing: 0;
    line-height: 21px;
    margin-left: 25px;
  }

  .delivery-option {
    margin-top: 20px !important;
  }

  .physical-email {
    margin-left: 25px;
    font-size: 14px;
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
  }

  .radio-btn.inactive:after {
    background: none;
  }
  .radio-btn.inactive {
    background: none;
    border: 1px solid #555;
  }

  .fa-red {
    color: rgb(204, 0, 51);
    font-size: 13px;
    padding-right: 8px;
  }

  .soldout {
    font-size: 14px;
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
  }

  .inStock {
    padding-left: 8px;
    font-weight: 700;
    font-size: 14px;
    font-family: 'Roboto', sans-serif;
  }
`;
