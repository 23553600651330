import React from 'react';
var useUpdateEffect = function (effect, deps) {
    var mounted = React.useRef(false);
    React.useEffect(function () {
        if (mounted.current) {
            return effect();
        }
        else {
            mounted.current = true;
        }
    }, deps);
};
export default useUpdateEffect;
