import React from 'react';
export var PdpContext = React.createContext({
    promotionData: [],
    data: {
        productDetailsData: null,
        colorVariant: null,
        currentGiftCardVariant: null
    },
    loading: false,
    productData: null,
    storeConf: null,
    helpers: []
});
