var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
// CouponSignInModal
import React from 'react';
import { Col, Modal, Row } from 'react-bootstrap';
import styled from 'styled-components';
import { SharedStyledButton } from '../shared/SharedStyledButton';
import { navigateTo } from '../../utils/helper';
var StyledTitle = styled.h4(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  font-size: 22px;\n  line-height: 28px;\n  text-align: center;\n  font-weight: 500;\n"], ["\n  font-size: 22px;\n  line-height: 28px;\n  text-align: center;\n  font-weight: 500;\n"])));
var StyledCloseButton = styled.button(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  opacity: 1;\n"], ["\n  opacity: 1;\n"])));
var StyledModalHeader = styled(Modal.Header)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  display: block;\n  border-bottom: none;\n  padding-top: 8px;\n  padding-bottom: 0;\n"], ["\n  display: block;\n  border-bottom: none;\n  padding-top: 8px;\n  padding-bottom: 0;\n"])));
var StyledModal = styled(Modal)(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  font-family: Roboto, Helvetica Neue, Helvetica, Arial, sans-serif;\n  text-rendering: optimizeLegibility;\n  .modal-content {\n    border-radius: 0;\n    border: none;\n  }\n  .modal-dialog {\n    @media (min-width: 576px) {\n      max-width: 280px;\n    }\n  }\n  .modal-body {\n    padding: 0 1rem 2rem;\n  }\n"], ["\n  font-family: Roboto, Helvetica Neue, Helvetica, Arial, sans-serif;\n  text-rendering: optimizeLegibility;\n  .modal-content {\n    border-radius: 0;\n    border: none;\n  }\n  .modal-dialog {\n    @media (min-width: 576px) {\n      max-width: 280px;\n    }\n  }\n  .modal-body {\n    padding: 0 1rem 2rem;\n  }\n"])));
var StyledImage = styled.img(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  height: 45px;\n  width: 60px;\n  ~ p {\n    margin-bottom: 0;\n    color: #0a0a0a;\n    font-size: 14px;\n    font-weight: 500;\n    line-height: 16px;\n    margin-top: 6px;\n  }\n"], ["\n  height: 45px;\n  width: 60px;\n  ~ p {\n    margin-bottom: 0;\n    color: #0a0a0a;\n    font-size: 14px;\n    font-weight: 500;\n    line-height: 16px;\n    margin-top: 6px;\n  }\n"])));
var StyledButton = styled(SharedStyledButton)(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  width: 90%;\n"], ["\n  width: 90%;\n"])));
var StyledText = styled.p(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  font-size: 14px;\n  line-height: 21px;\n  color: #000;\n  margin-bottom: 0;\n"], ["\n  font-size: 14px;\n  line-height: 21px;\n  color: #000;\n  margin-bottom: 0;\n"])));
var CouponSignInModal = function (props) {
    var show = props.show, backdrop = props.backdrop, onHide = props.onHide;
    return (React.createElement(StyledModal, { show: show, backdrop: backdrop, centered: true },
        React.createElement(StyledModalHeader, null,
            React.createElement(StyledCloseButton, { type: "button", className: "close", onClick: onHide },
                React.createElement("img", { src: "https://www.bjs.com/assets/images/icons/X.svg", alt: "close", "auto-data": "product_couponSignInCloseButton" }))),
        React.createElement(Modal.Body, { "auto-data": "product_couponSignInWindow" },
            React.createElement(StyledTitle, { className: "mb-3" },
                "Sign in for",
                React.createElement("br", null),
                "Coupon Savings"),
            React.createElement(Row, { className: "mt-3 text-center" },
                React.createElement(Col, null,
                    React.createElement(StyledText, null, "After signing in, we\u2019ll take you right back to shopping."),
                    React.createElement("span", { onClick: function () { return navigateTo('/signIn?source=coupon'); } },
                        React.createElement(StyledButton, { variant: "primary", className: "btn-block my-3 mx-auto", "auto-data": "product_couponSignInLink" }, "Sign In")),
                    React.createElement(StyledText, null,
                        "Join the Club",
                        React.createElement("br", null),
                        "so many Members already love."),
                    React.createElement("a", { href: "/membershipEnroll?source=signInModal" },
                        React.createElement(StyledButton, { variant: "outline-secondary", className: "btn-block my-3 mx-auto", "auto-data": "product_couponJoinNow" }, "JOIN NOW")),
                    React.createElement(StyledText, null,
                        "Use your BJ\u2019s Membership",
                        React.createElement("br", null),
                        "to create your online account."),
                    React.createElement("a", { href: "/createMembershipAccount" },
                        React.createElement(StyledButton, { variant: "dark", className: "btn-block mt-3 mx-auto", "auto-data": "product_couponCreateLogin" }, "CREATE A LOGIN")))))));
};
export default CouponSignInModal;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7;
