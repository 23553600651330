export var normalizeAddCartRequest = function (type, items, fulfillmentFrequency, warrantyCatId) {
    var _a, _b;
    if (fulfillmentFrequency === void 0) { fulfillmentFrequency = '1'; }
    if (warrantyCatId === void 0) { warrantyCatId = null; }
    var data = {
        URL: 'ViewMiniCart',
        activeChoice: '',
        catalogId: '',
        comment: '',
        errorView: '',
        isExpired: '',
        itemType: '',
        orderId: '.',
        purchaseFlow: '',
        itemList: type === 'WARRENTY' ? items : [items],
        itemsCheckInCartReq: warrantyCatId ? [warrantyCatId] : [],
        qofferId: []
    };
    if (type === 'SUBSCRIPTION') {
        data.fulfillmentFrequency = fulfillmentFrequency;
        data.fulfillmentFrequencyUOM = 'MON';
    }
    if (type === 'WARRENTY') {
        data.itemsCheckInCartReq = [(_a = items[1]) === null || _a === void 0 ? void 0 : _a.catEntryId];
    }
    if (type === 'WARRENTYCART') {
        data.itemsCheckInCartReq = [(_b = items[0]) === null || _b === void 0 ? void 0 : _b.catEntryId];
        data.itemList = items;
    }
    return data;
};
export var normalizeFulfillmentRequest = function (item, type, cartResponse, qty, headerClubId, sddCluBId, sddAddressId) {
    var articleId = item.ArticleId;
    var _a = cartResponse[0], unitOfMeasure = _a.unitOfMeasure, orderItemId_1 = _a.orderItemId_1;
    var data = {
        inventoryReq: {
            itemID: articleId,
            clubId: '',
            unitOfMeasure: unitOfMeasure
        },
        orderitemFFMUpdate: {
            orderId: '.',
            orderItem: [
                {
                    shipModeId: '',
                    quantity: qty,
                    orderItemId: orderItemId_1
                }
            ],
            x_calculateOrder: '1',
            shipAsComplete: 'true',
            x_calculationUsage: '-1,-2,-5,-6,-7',
            x_itemID: articleId
        }
    };
    if (type == 1) {
        //bopic
        data.inventoryReq.clubId = headerClubId;
        data.orderitemFFMUpdate.orderItem[0].shipModeId = '20000';
        data.orderitemFFMUpdate['x_physicalStoreId'] = headerClubId;
        data.orderitemFFMUpdate['x_changePickupStore'] = 'true';
    }
    else if (type == 2) {
        //sdd
        data.inventoryReq.clubId = sddCluBId;
        data.orderitemFFMUpdate.orderItem[0].shipModeId = '30000';
        data.orderitemFFMUpdate['x_physicalStoreId'] = sddCluBId;
        data.orderitemFFMUpdate['x_changeSameDayDelivery'] = 'true';
        data.orderitemFFMUpdate['x_sddAddressId'] = "" + sddAddressId;
    }
    else if (type == 3) {
        //online
        data.inventoryReq.clubId = '10201';
        data.orderitemFFMUpdate.orderItem[0].shipModeId = 'changeOnlinePLP';
        data.orderitemFFMUpdate['x_changeOnline'] = 'true';
    }
    return data;
};
export var normalizeQuantityRequest = function (item, type, cartResponse, qty, headerClubId, sddCluBId, isLoggedInUser, zipCode) {
    var articleId = item.ArticleId;
    var _a = cartResponse[0], unitOfMeasure = _a.unitOfMeasure, orderItemId_1 = _a.orderItemId_1;
    var data = {
        inventoryReq: {
            itemID: articleId,
            clubId: '',
            unitOfMeasure: unitOfMeasure
        },
        orderitemQTYUpdate: {
            orderId: '.',
            orderItem: [
                {
                    orderItemId: orderItemId_1,
                    quantity: qty === null || qty === void 0 ? void 0 : qty.toString(),
                    partNumber: articleId
                }
            ],
            x_calculateOrder: '1',
            x_calculationUsage: '-1,-2,-5,-6,-7'
        }
    };
    if (!isLoggedInUser) {
        data.orderitemQTYUpdate['x_zipCode'] = zipCode;
    }
    if (type == 1) {
        //bopic
        data.inventoryReq.clubId = headerClubId;
    }
    else if (type == 2) {
        //sdd
        data.inventoryReq.clubId = sddCluBId;
    }
    else if (type == 3) {
        //online
        data.inventoryReq.clubId = '10201';
    }
    return data;
};
