import React, { useContext, useEffect } from 'react';
import { PdpContext } from '../../store/pdp/PdpContext';
import { environmentConst as environment } from '../../config/environments/constants';
var FlixMedia = function () {
    var _a;
    var productData = useContext(PdpContext);
    var productDetailsData = (_a = productData === null || productData === void 0 ? void 0 : productData.data) === null || _a === void 0 ? void 0 : _a.productDetailsData;
    var descriptiveAttributes = (productDetailsData || null).descriptiveAttributes;
    useEffect(function () {
        var flixmedia_scriptTag = document.createElement('script');
        flixmedia_scriptTag.src = environment.FLIX_MEDIA_SRC;
        flixmedia_scriptTag.async = true;
        flixmedia_scriptTag.type = 'text/javascript';
        flixmedia_scriptTag.charset = environment.FLIX_MEDIA_CHARSET;
        if ((productDetailsData.manufacturerName != null || undefined) &&
            (productDetailsData.manufacturerPartNumber != null || undefined)) {
            flixmedia_scriptTag.setAttribute('data-flix-distributor', environment.FLIX_MEDIA_DISTRIBUTOR);
            flixmedia_scriptTag.setAttribute('data-flix-language', environment.FLIX_MEDIA_LANGUAGE);
            flixmedia_scriptTag.setAttribute('data-flix-brand', productDetailsData.manufacturerName);
            flixmedia_scriptTag.setAttribute('data-flix-mpn', productDetailsData.manufacturerPartNumber);
            flixmedia_scriptTag.setAttribute('data-flix-sku', ' ');
            flixmedia_scriptTag.setAttribute('data-flix-inpage', environment.FLIX_MEDIA_IN_PAGE);
            flixmedia_scriptTag.setAttribute('data-flix-button-image', ' ');
            flixmedia_scriptTag.setAttribute('data-flix-price', ' ');
        }
        document.getElementById('flix-media-content').appendChild(flixmedia_scriptTag);
        return function () {
            document.getElementById('flix-media-content').removeChild(flixmedia_scriptTag);
        };
    }, []);
    return (React.createElement("div", { id: "flix-media-content" },
        React.createElement("div", { id: "flix-inpage" }),
        React.createElement("div", { id: "flix-inpage" })));
};
export default FlixMedia;
