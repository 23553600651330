import { useEffect } from 'react';
var useStyle = function (url) {
    useEffect(function () {
        var style = document.createElement('link');
        style.href = url;
        style.rel = 'stylesheet';
        document.body.appendChild(style);
        return function () {
            document.body.removeChild(style);
        };
    }, [url]);
};
export default useStyle;
