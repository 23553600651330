var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from) {
    for (var i = 0, il = from.length, j = to.length; i < il; i++, j++)
        to[j] = from[i];
    return to;
};
import React, { useContext } from 'react';
import useScript from '../../hooks/useScript';
import useStyle from '../../hooks/useStyle';
import api from '../../services/api';
import { PdpContext } from '../../store/pdp/PdpContext';
import { videoConfig } from '../ProductGallery/video.config';
import ProductGallery from '../ProductGallery';
import { imageExists } from '../../utils/helper';
import { logInfo } from '@bjs/shared-micro-frontend';
var ImageWrapper = function (_a) {
    var _b, _c, _d;
    var partNumber = _a.partNumber, productDescription = _a.productDescription;
    var _e = __read(React.useState({
        href: "https://bjs.scene7.com/is/image/bjs/" + partNumber + "?$bjs-Zoom$",
        src: "https://bjs.scene7.com/is/image/bjs/" + partNumber + "?$bjs-Initial600$"
    }), 2), baseImage = _e[0], setBaseImage = _e[1];
    var _f = __read(React.useState({
        href: "https://bjs.scene7.com/is/image/bjs/" + partNumber + "?$bjs-Zoom$",
        src: "https://bjs.scene7.com/is/image/bjs/" + partNumber + "?$bjs-Initial600$"
    }), 2), currentImage = _f[0], setCurrentImage = _f[1];
    var _g = __read(React.useState([]), 2), videoImageIdsArray = _g[0], setVideoImageIdsArray = _g[1];
    var pdpContext = useContext(PdpContext);
    var productDetailsData = (_b = pdpContext === null || pdpContext === void 0 ? void 0 : pdpContext.data) === null || _b === void 0 ? void 0 : _b.productDetailsData;
    var currentVariant = (_c = pdpContext === null || pdpContext === void 0 ? void 0 : pdpContext.data) === null || _c === void 0 ? void 0 : _c.currentVariant;
    var isVariant = (_d = pdpContext === null || pdpContext === void 0 ? void 0 : pdpContext.productData) === null || _d === void 0 ? void 0 : _d.isVariant;
    var entitledItem = (currentVariant &&
        (productDetailsData === null || productDetailsData === void 0 ? void 0 : productDetailsData.entitledItems.find(function (d) { return d.itemID === (currentVariant === null || currentVariant === void 0 ? void 0 : currentVariant.itemId); }))) ||
        null;
    if (isVariant) {
        if (!entitledItem) {
            entitledItem = productDetailsData === null || productDetailsData === void 0 ? void 0 : productDetailsData.entitledItems[0];
        }
    }
    var _h = __read(React.useState(false), 2), noImage = _h[0], setNoImage = _h[1];
    var _j = __read(React.useState([]), 2), productImageArray = _j[0], setProductImageArray = _j[1];
    partNumber = currentVariant ? entitledItem.partNumber : partNumber;
    useStyle('https://www.bjs.com/assets/css/magicscroll.css');
    useStyle('https://www.bjs.com/assets/css/magiczoomplus.css');
    useScript('https://www.bjs.com/prod/static-assets/third-party/js/magicscroll.js');
    useScript('https://www.bjs.com/assets/js/magiczoomplus.js');
    // Start - add for video use story 15807
    var _k = __read(React.useState({
        vmAsset: 'image',
        vmIndx: 0
    }), 2), videoImageAsset = _k[0], setVideoImageAsset = _k[1];
    var videoAsset = 'video';
    // End
    // start s7viewer
    var videoScriptInterval;
    var _l = __read(React.useState(''), 2), videoViewerId = _l[0], setVideoViewerId = _l[1];
    useScript(videoConfig.pdpImageVideo.s7VideoViewerSrc);
    React.useEffect(function () {
        if (videoViewerId) {
            videoScriptInterval = setInterval(function () {
                creates7Viewer(videoViewerId);
            }, 400);
        }
    }, [videoViewerId]);
    var creates7Viewer = function (videoViewerId) {
        if (window.s7viewers) {
            var s7videoViewer = new window.s7viewers.VideoViewer({
                containerId: 's7videoviewer',
                params: {
                    asset: videoConfig.pdpImageVideo.s7AssetPrefix + videoViewerId,
                    serverurl: videoConfig.pdpImageVideo.s7ViewerServerUrl,
                    contenturl: videoConfig.pdpImageVideo.s7ContentUrl,
                    config: videoConfig.pdpImageVideo.s7VideoConfig_Universal_HTML5_Video,
                    videoserverurl: 'https://s7d1.scene7.com/is/content/'
                }
            }).init();
            clearInterval(videoScriptInterval);
        }
    };
    // end
    var getAlternateImages = function (itemPartNumber) {
        if (itemPartNumber === void 0) { itemPartNumber = null; }
        return __awaiter(void 0, void 0, void 0, function () {
            var imagePartNumber, response, imageData, imageArrays, resultArray, err_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        imagePartNumber = itemPartNumber || partNumber;
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, api.get("https://bjs.scene7.com/is/image/bjs/" + imagePartNumber + "-mmset?req=imageset,text ")];
                    case 2:
                        response = _a.sent();
                        imageData = response.data;
                        if ((!baseImage && (!imageData || imageData === '\r\n')) ||
                            imageData == ';;advanced_image;,;;advanced_image;\r\n') {
                            setNoImage(true);
                            return [2 /*return*/];
                        }
                        if (currentVariant) {
                        }
                        imageArrays = imageData === null || imageData === void 0 ? void 0 : imageData.split(',');
                        if (!imageData || imageData === '\r\n') {
                            imageArrays.unshift(baseImage);
                        }
                        resultArray = imageArrays.map(function (img1) {
                            if (typeof img1 === 'string' && img1.includes(';')) {
                                var _a = __read(img1 === null || img1 === void 0 ? void 0 : img1.split(';'), 3), assetName1 = _a[0], assetName2 = _a[1], assetType = _a[2];
                                assetName1 = assetName1.replace('bjs/', '');
                                return assetName1;
                            }
                        });
                        setVideoImageIdsArray(__spreadArray([], __read(resultArray)));
                        if (typeof imageArrays[0] === 'string' && imageArrays[0].includes(';')) {
                            return [2 /*return*/, imageArrays
                                    .filter(function (item) {
                                    var _a = __read(item === null || item === void 0 ? void 0 : item.split(';'), 1), assetName1 = _a[0];
                                    if (!!assetName1) {
                                        return item;
                                    }
                                })
                                    .map(function (d) {
                                    var _a = __read(d === null || d === void 0 ? void 0 : d.split(';'), 3), assetName1 = _a[0], assetName2 = _a[1], assetType = _a[2];
                                    assetName1 = assetName1 === null || assetName1 === void 0 ? void 0 : assetName1.replace('bjs/', '');
                                    var resultObject = {
                                        href: "https://bjs.scene7.com/is/image/bjs/" + assetName1 + "?$bjs-Zoom$",
                                        dataImage: "https://bjs.scene7.com/is/image/bjs/" + assetName1 + "?$bjs-Initial600$",
                                        srcSet: "https://bjs.scene7.com/is/image/bjs/" + assetName1 + "?$bjs-220$ 2x",
                                        src: "https://bjs.scene7.com/is/image/bjs/" + assetName1 + "?$bjs-201$",
                                        // start sp-15807 changes
                                        assetType: assetType
                                        // end sp-15807 changes
                                    };
                                    return resultObject;
                                })];
                        }
                        return [3 /*break*/, 4];
                    case 3:
                        err_1 = _a.sent();
                        logInfo && logInfo('error', 'image_partnumber_failure', err_1);
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    var setCurrentVariantImage = function () { return __awaiter(void 0, void 0, void 0, function () {
        var noImage, currentHref_1, currentSrc_1, result, magicZoomElements;
        var _a, _b, _c, _d, _e, _f, _g;
        return __generator(this, function (_h) {
            switch (_h.label) {
                case 0:
                    noImage = false;
                    if (!(isVariant && (currentVariant || entitledItem))) return [3 /*break*/, 3];
                    currentHref_1 = "https://bjs.scene7.com/is/image/bjs/" + entitledItem.partNumber + "?$bjs-Zoom$";
                    currentSrc_1 = "https://bjs.scene7.com/is/image/bjs/" + entitledItem.partNumber + "?$bjs-Initial600$";
                    if (!(entitledItem === null || entitledItem === void 0 ? void 0 : entitledItem.partNumber)) return [3 /*break*/, 2];
                    return [4 /*yield*/, getAlternateImages(entitledItem === null || entitledItem === void 0 ? void 0 : entitledItem.partNumber)];
                case 1:
                    result = _h.sent();
                    if (result) {
                        (_a = window['MagicScroll']) === null || _a === void 0 ? void 0 : _a.stop();
                        (_b = window['MagicZoom']) === null || _b === void 0 ? void 0 : _b.stop();
                        setProductImageArray(result);
                        magicZoomElements = document.querySelectorAll('.MagicZoom');
                        magicZoomElements.forEach(function (magicZoomElement) {
                            // Set the href attribute.
                            magicZoomElement['href'] = currentHref_1;
                            // Set the data-image attribute.
                            magicZoomElement['data-image'] = currentSrc_1;
                            // Set the src attribute.
                            magicZoomElement.querySelector('img').src = currentSrc_1;
                        });
                        (_c = window['MagicZoom']) === null || _c === void 0 ? void 0 : _c.refresh();
                        (_d = window['MagicScroll']) === null || _d === void 0 ? void 0 : _d.start();
                        (_e = window['MagicZoom']) === null || _e === void 0 ? void 0 : _e.start();
                    }
                    else {
                        noImage = true;
                    }
                    (_f = window['MagicZoom']) === null || _f === void 0 ? void 0 : _f.update('prodGallery', currentHref_1, currentSrc_1);
                    (_g = window['MagicZoom']) === null || _g === void 0 ? void 0 : _g.update('prodGallerym', currentHref_1, currentSrc_1);
                    _h.label = 2;
                case 2:
                    if (imageExists(currentHref_1)) {
                        setBaseImage({
                            href: currentHref_1,
                            src: currentSrc_1
                        });
                        setCurrentImage({
                            href: currentHref_1,
                            src: currentSrc_1
                        });
                        setNoImage(false);
                    }
                    else {
                        if (currentVariant) {
                            setNoImage(true);
                        }
                    }
                    _h.label = 3;
                case 3: return [2 /*return*/];
            }
        });
    }); };
    var fetchAlternateImages = function () { return __awaiter(void 0, void 0, void 0, function () {
        var result, err_2;
        var _a, _b, _c, _d, _e;
        return __generator(this, function (_f) {
            switch (_f.label) {
                case 0:
                    _f.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, getAlternateImages()];
                case 1:
                    result = _f.sent();
                    if (result) {
                        (_a = window['MagicScroll']) === null || _a === void 0 ? void 0 : _a.stop();
                        (_b = window['MagicZoom']) === null || _b === void 0 ? void 0 : _b.stop();
                        setProductImageArray(result);
                        (_c = window['MagicZoom']) === null || _c === void 0 ? void 0 : _c.refresh();
                        (_d = window['MagicScroll']) === null || _d === void 0 ? void 0 : _d.start();
                        (_e = window['MagicZoom']) === null || _e === void 0 ? void 0 : _e.start();
                        return [2 /*return*/];
                    }
                    if (currentVariant) {
                        setCurrentVariantImage();
                    }
                    return [3 /*break*/, 3];
                case 2:
                    err_2 = _f.sent();
                    logInfo && logInfo('error', 'alternateImages_failure', err_2);
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
    React.useEffect(function () {
        fetchAlternateImages();
    }, []);
    React.useEffect(function () {
        setCurrentVariantImage();
    }, [currentVariant]);
    return (React.createElement("div", { className: "product-gallery-wrapper " + (!productImageArray.length ? 'single-image-carousel' : '') + " " },
        React.createElement("div", { id: "syndigo-hero-image" }),
        partNumber && (React.createElement(ProductGallery, { partNumber: partNumber.replace('P_', ''), productDescription: productDescription, productImages: productImageArray, videoImages: videoImageIdsArray, currentVariant: currentVariant, noImage: noImage }))));
};
export default ImageWrapper;
