var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
import React, { useEffect } from 'react';
import loadable from '@loadable/component';
import styled from 'styled-components';
import salsify from 'salsify-experiences-sdk';
import { SharedStyledButton } from '../shared/SharedStyledButton';
import { normalizeAddCartRequest } from './normalizeAddCartRequest';
import { useCookies } from 'react-cookie';
import { environmentConst as environment } from '../../config/environments/constants';
import { postAddToCart, miniCartStoreId } from '../../services/pdpClient';
import { useStore } from '../../store/cart/CartProvider';
import { PdpContext } from '../../store/pdp/PdpContext';
import { setMiniCartData, setTotalPriceQuantity, setTotalQuantityInOrder, setVariantOptionsNotSelected, setSddAddressNotSelected, setMaxQtyReachedError, setQtyInCart, setItemMaxQuantity, setCartMaxItem, setSddFromAddCart, setNoWarranty } from '../../store/cart/CartContext';
import { parseCookie, fetchUpdatedCookie } from '../../utils/helper';
import { addToCartAnalyticsEvent, ATCFreqBoughtItems } from '../../utils/datalayerAction';
import { addMonetateCustomVariables, getZipCode } from '../../utils/pdpUtil';
import { useFulfillmentStore } from '../../store/fulfillment/FulfillmentProvider';
import { isNullOrUndefinedOrEmpty, getfulfillmentName } from '../../utils/pdpUtil';
import { logInfo } from '@bjs/shared-micro-frontend';
import { getItemPartNumberOrArticleId, getVariantArticleId } from '../../utils/format/product';
var FreePickupModal = loadable(function () { return import('../modals/FreePickup'); });
var Button = styled(SharedStyledButton)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  width: 289px;\n  @media (min-width: 768px) and (max-width: 992px) {\n    width: 220px;\n  }\n  @media screen and (max-width: 767px) {\n    width: 100%;\n  }\n"], ["\n  width: 289px;\n  @media (min-width: 768px) and (max-width: 992px) {\n    width: 220px;\n  }\n  @media screen and (max-width: 767px) {\n    width: 100%;\n  }\n"])));
var AddCartStyling = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  .red-color-button {\n    color: #fff;\n    font-size: 16px;\n    font-weight: 500;\n    border: 1px solid #c03 !important;\n    background-color: #cc0033;\n    border-radius: 5px;\n    padding-top: 12px;\n    padding-bottom: 12px;\n    // width: 220px;\n    height: 40px;\n    line-height: 16px;\n    font-weight: 700;\n  }\n\n  .button-part {\n    button {\n      @media (max-width: 482px) {\n        height: 40px;\n      }\n    }\n  }\n"], ["\n  .red-color-button {\n    color: #fff;\n    font-size: 16px;\n    font-weight: 500;\n    border: 1px solid #c03 !important;\n    background-color: #cc0033;\n    border-radius: 5px;\n    padding-top: 12px;\n    padding-bottom: 12px;\n    // width: 220px;\n    height: 40px;\n    line-height: 16px;\n    font-weight: 700;\n  }\n\n  .button-part {\n    button {\n      @media (max-width: 482px) {\n        height: 40px;\n      }\n    }\n  }\n"])));
var ButtonCarousel = styled(SharedStyledButton)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  height: 24px;\n  width: 100%;\n  font-size: ", ";\n  padding: 0 8px;\n  font-weight: 500;\n  @media (max-width: 482px) {\n    height: 30px !important;\n  }\n"], ["\n  height: 24px;\n  width: 100%;\n  font-size: ", ";\n  padding: 0 8px;\n  font-weight: 500;\n  @media (max-width: 482px) {\n    height: 30px !important;\n  }\n"])), function (props) {
    return props.inCartBtn == 'ADD TO CART' || props.inCartBtn == 'IN CART' ? '12px' : '8px';
});
var globalFulfillmentEvent = function (name, value) {
    var event = new CustomEvent('global-fulfillment', {
        detail: { name: name, value: value }
    });
    document.dispatchEvent(event);
};
export var atcDisableEvent = function (name, value) {
    var event = new CustomEvent('atc-disabled', {
        detail: { name: name, value: value }
    });
    document.dispatchEvent(event);
};
var AddToCart = function (_a) {
    var _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o;
    var btnAddToCartName = _a.btnAddToCartName, productDetails = _a.productDetails, count = _a.count, _p = _a.activeCoupons, activeCoupons = _p === void 0 ? [] : _p, location = _a.location, setSignInModalVisible = _a.setSignInModalVisible, setAddToCartVisible = _a.setAddToCartVisible, _q = _a.setAddCartResponse, setAddCartResponse = _q === void 0 ? function () { } : _q, _r = _a.isCarousel, isCarousel = _r === void 0 ? false : _r, addCartRef = _a.addCartRef, kiboClubId = _a.kiboClubId, _s = _a.setOpenSignInModal, setOpenSignInModal = _s === void 0 ? function (value) { return void false; } : _s, heading = _a.heading;
    var _t = __read(useStore(), 2), state = _t[0], dispatch = _t[1];
    var priceData = state.priceData, fullFillmentData = state.fullFillmentData, isWarrantyProduct = state.isWarrantyProduct, isVariantOptionsNotSelected = state.isVariantOptionsNotSelected, warrantyToCart = state.warrantyToCart, noWarranty = state.noWarranty, isViewCart = state.isViewCart, cartMaxItem = state.cartMaxItem;
    var _u = __read(useFulfillmentStore(), 2), fulfillmentState = _u[0], fulFillmentDispatch = _u[1];
    var fulfillmentBopic = fulfillmentState.fulfillmentBopic, fulfillmentOnline = fulfillmentState.fulfillmentOnline, fulfillmentSdd = fulfillmentState.fulfillmentSdd;
    var _v = __read(React.useState(fullFillmentData === null || fullFillmentData === void 0 ? void 0 : fullFillmentData.fullFillmentType), 2), cartType = _v[0], setCartType = _v[1];
    var _w = __read(useCookies(), 2), cookies = _w[0], setCookie = _w[1];
    var _x = __read(React.useState({
        freePickUp: false
    }), 2), modalShow = _x[0], setModalShow = _x[1];
    var numberOfItems = 0;
    var productData = React.useContext(PdpContext);
    var currentVariant = (_b = productData === null || productData === void 0 ? void 0 : productData.data) === null || _b === void 0 ? void 0 : _b.currentVariant;
    var formattedProductData = productData === null || productData === void 0 ? void 0 : productData.productData;
    var isNonBjsGiftCard = formattedProductData === null || formattedProductData === void 0 ? void 0 : formattedProductData.isNonBjsGiftCard;
    var itemId = ((_d = (_c = productData === null || productData === void 0 ? void 0 : productData.data) === null || _c === void 0 ? void 0 : _c.currentVariant) === null || _d === void 0 ? void 0 : _d.itemId)
        ? productData.data.currentVariant.itemId
        : (_f = (_e = productDetails === null || productDetails === void 0 ? void 0 : productDetails.bjsitems) === null || _e === void 0 ? void 0 : _e[0]) === null || _f === void 0 ? void 0 : _f.itemId;
    var bjsItem = itemId
        ? (_g = productDetails === null || productDetails === void 0 ? void 0 : productDetails.bjsitems) === null || _g === void 0 ? void 0 : _g.find(function (item) { return item.itemId === itemId; })
        : (_h = productDetails === null || productDetails === void 0 ? void 0 : productDetails.bjsitems) === null || _h === void 0 ? void 0 : _h[0];
    var buttonGreyedOut = (formattedProductData === null || formattedProductData === void 0 ? void 0 : formattedProductData.isGiftCardVariant) && (currentVariant === null || currentVariant === void 0 ? void 0 : currentVariant.giftCardRequest) === null
        ? true
        : false;
    buttonGreyedOut = count == 0 ? true : false;
    // 100% sold out
    if ((fulfillmentBopic === null || fulfillmentBopic === void 0 ? void 0 : fulfillmentBopic.disabled) && (fulfillmentSdd === null || fulfillmentSdd === void 0 ? void 0 : fulfillmentSdd.disabled) && (fulfillmentOnline === null || fulfillmentOnline === void 0 ? void 0 : fulfillmentOnline.disabled)) {
        buttonGreyedOut = true;
        btnAddToCartName = 'UNAVAILABLE';
    }
    if ((fulfillmentBopic === null || fulfillmentBopic === void 0 ? void 0 : fulfillmentBopic.disabled) &&
        (fulfillmentBopic === null || fulfillmentBopic === void 0 ? void 0 : fulfillmentBopic.isWithIn25MilesAvailable) &&
        cartType == 'BOPIC') {
        buttonGreyedOut = false;
        btnAddToCartName = 'SEARCH INVENTORY';
    }
    if (cartMaxItem != 0) {
        btnAddToCartName = 'VIEW CART';
    }
    if (cartType == 'BOPIC' && (fulfillmentBopic === null || fulfillmentBopic === void 0 ? void 0 : fulfillmentBopic.disabled) && !(fulfillmentBopic === null || fulfillmentBopic === void 0 ? void 0 : fulfillmentBopic.isEligible)) {
        btnAddToCartName = 'IN-CLUB PURCHASE';
        buttonGreyedOut = true;
    }
    if (cartType == 'SDD' &&
        cookies[environment.USER_SIGNIN_RESPONSE_COOKIE] &&
        !((fulfillmentSdd === null || fulfillmentSdd === void 0 ? void 0 : fulfillmentSdd.displayItem) &&
            (fulfillmentSdd === null || fulfillmentSdd === void 0 ? void 0 : fulfillmentSdd.disabled) &&
            !(fulfillmentSdd === null || fulfillmentSdd === void 0 ? void 0 : fulfillmentSdd.isInventoryAvailable))) {
        btnAddToCartName = 'ADD TO CART';
    }
    var _y = __read(React.useState(false), 2), iswarranty = _y[0], setWarranty = _y[1];
    var _z = __read(React.useState(false), 2), atcClicked = _z[0], setAtcClicked = _z[1];
    var _0 = __read(React.useState(false), 2), addToCartSuccess = _0[0], setAddToCartSuccess = _0[1];
    var isGiftCardVariant = formattedProductData === null || formattedProductData === void 0 ? void 0 : formattedProductData.isGiftCardVariant;
    var _1 = __read(React.useState(false), 2), isWarrantyChecked = _1[0], setWarrantyChecked = _1[1];
    var updatedZip = cookies[environment.UPDATED_ZIP]
        ? cookies[environment.UPDATED_ZIP]
        : cookies[environment.USER_SIGNIN_RESPONSE_COOKIE] && cookies[environment.ZIP_COOKIE]
            ? cookies[environment.ZIP_COOKIE]
            : cookies[environment.CLUB_ZIP_CODE_COOKIE];
    var inCartBtn = atcClicked && addToCartSuccess ? 'IN CART' : btnAddToCartName;
    var isWeighted = (_j = priceData === null || priceData === void 0 ? void 0 : priceData.weightedRange) === null || _j === void 0 ? void 0 : _j.isWeighted;
    var minPrice = isNaN((_k = priceData === null || priceData === void 0 ? void 0 : priceData.weightedRange) === null || _k === void 0 ? void 0 : _k.minPrice)
        ? 0
        : (_l = priceData === null || priceData === void 0 ? void 0 : priceData.weightedRange) === null || _l === void 0 ? void 0 : _l.minPrice;
    var maxPrice = isNaN((_m = priceData === null || priceData === void 0 ? void 0 : priceData.weightedRange) === null || _m === void 0 ? void 0 : _m.maxPrice)
        ? 0
        : (_o = priceData === null || priceData === void 0 ? void 0 : priceData.weightedRange) === null || _o === void 0 ? void 0 : _o.maxPrice;
    var notEnriched = false;
    if (isWeighted &&
        (isNullOrUndefinedOrEmpty(minPrice) || minPrice == 0) &&
        (isNullOrUndefinedOrEmpty(maxPrice) || maxPrice == 0)) {
        notEnriched = true;
    }
    var isButtonDisabled = count === 0 ||
        btnAddToCartName == 'SOLD OUT' ||
        btnAddToCartName == 'IN-CLUB PURCHASE' ||
        inCartBtn == 'IN CART' ||
        buttonGreyedOut ||
        btnAddToCartName == 'DISABLE' ||
        btnAddToCartName == 'UNAVAILABLE' ||
        notEnriched == true;
    var _2 = __read(React.useState(isButtonDisabled), 2), atcDisabled = _2[0], setAtcDisabled = _2[1];
    var btnName = btnAddToCartName == 'DISABLE' ? 'ADD TO CART' : btnAddToCartName;
    var brand = productDetails === null || productDetails === void 0 ? void 0 : productDetails.manufacturerName;
    var category = productDetails === null || productDetails === void 0 ? void 0 : productDetails.immediateParentName;
    var partNumber = productDetails === null || productDetails === void 0 ? void 0 : productDetails.partNumber;
    var price = priceData === null || priceData === void 0 ? void 0 : priceData.displayPrice;
    useEffect(function () {
        document.addEventListener('atc-disabled', function (event) {
            setAtcDisabled(event.detail.value);
        });
    }, []);
    useEffect(function () {
        setWarrantyChecked(false);
        setCartType(fullFillmentData === null || fullFillmentData === void 0 ? void 0 : fullFillmentData.fullFillmentType);
    }, [fullFillmentData === null || fullFillmentData === void 0 ? void 0 : fullFillmentData.fullFillmentType]);
    useEffect(function () {
        if ((isWarrantyProduct || warrantyToCart) && !isCarousel) {
            setWarrantyChecked(true);
            setWarranty(true);
            if (warrantyToCart && isWarrantyChecked && iswarranty && !noWarranty && isViewCart) {
                onAddToCart();
            }
        }
        else if ((isWarrantyProduct || warrantyToCart) && isCarousel) {
            setWarrantyChecked(false);
            setWarranty(false);
            setCartType(fullFillmentData === null || fullFillmentData === void 0 ? void 0 : fullFillmentData.fullFillmentType);
        }
        else {
            setWarrantyChecked(false);
            setWarranty(false);
            setCartType(fullFillmentData === null || fullFillmentData === void 0 ? void 0 : fullFillmentData.fullFillmentType);
        }
    }, [isWarrantyProduct, iswarranty, warrantyToCart, isCarousel, isViewCart, isWarrantyChecked]);
    useEffect(function () {
        if (fullFillmentData === null || fullFillmentData === void 0 ? void 0 : fullFillmentData.fulfillmentFrequency) {
            setCartType('SUBSCRIPTION');
        }
        else {
            setCartType(fullFillmentData === null || fullFillmentData === void 0 ? void 0 : fullFillmentData.fullFillmentType);
        }
    }, [fullFillmentData === null || fullFillmentData === void 0 ? void 0 : fullFillmentData.fulfillmentFrequency]);
    useEffect(function () {
        setAtcDisabled(isButtonDisabled);
    }, [isButtonDisabled]);
    var onAddToCart = function () { return __awaiter(void 0, void 0, void 0, function () {
        var serviceZipCode, physicalStoreId, catEntryId_1, articleId, productEntitledItems, userSigninResponse, sddAddressId, sddZipCode, sddClubId, payload, items, fulfillmentFrequency, isWarrantyInfo, WarrantyCatEntryId_1, product, warProduct, qofferId, activeCoupons_1, activeCoupons_1_1, ac, addToCartFlag_1, onlineCartInfoCookie_1, salsifyOptions;
        var e_1, _a;
        var _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m;
        return __generator(this, function (_o) {
            atcDisableEvent('disableATC', true);
            if (btnAddToCartName === 'SIGN IN TO ADD') {
                if (!cookies[environment.USER_SIGNIN_RESPONSE_COOKIE]) {
                    setSignInModalVisible(true);
                    atcDisableEvent('disableATC', false);
                }
            }
            if (btnAddToCartName === 'SIGN IN') {
                setOpenSignInModal(true);
                atcDisableEvent('disableATC', false);
            }
            if (btnAddToCartName === 'VIEW CART') {
                window.location.href = '/cart';
            }
            /**
             * On search inventory we need to
             * show modal similar to club change
             * where you can update the clubs
             * updated as part if SP-17188
             */
            if (btnAddToCartName === 'SEARCH INVENTORY') {
                setModalShow(__assign(__assign({}, modalShow), { freePickUp: true }));
            }
            if (btnAddToCartName === 'ADD TO CART') {
                atcDisableEvent('disableATC', true);
                if ((formattedProductData === null || formattedProductData === void 0 ? void 0 : formattedProductData.isVariant) && !isNonBjsGiftCard) {
                    if (!currentVariant) {
                        atcDisableEvent('disableATC', false);
                        dispatch(setVariantOptionsNotSelected(true));
                        return [2 /*return*/];
                    }
                    else {
                        dispatch(setVariantOptionsNotSelected(false));
                    }
                }
                serviceZipCode = cookies[environment.CLUB_ZIP_CODE_COOKIE];
                physicalStoreId = cookies[environment.WC_PICKUP_STORE] || parseCookie(environment.WC_PICKUP_STORE);
                catEntryId_1 = '';
                articleId = '';
                if (isCarousel) {
                    catEntryId_1 = (productDetails === null || productDetails === void 0 ? void 0 : productDetails.ItemCatentryID) || '';
                    articleId = productDetails === null || productDetails === void 0 ? void 0 : productDetails.ID;
                }
                else if (isNonBjsGiftCard) {
                    catEntryId_1 = (_b = productDetails === null || productDetails === void 0 ? void 0 : productDetails.nonBjsGcVariant) === null || _b === void 0 ? void 0 : _b.itemId;
                    productEntitledItems = ((_c = productDetails === null || productDetails === void 0 ? void 0 : productDetails.entitledItems) === null || _c === void 0 ? void 0 : _c.length) > 1
                        ? (_e = (_d = productDetails === null || productDetails === void 0 ? void 0 : productDetails.entitledItems) === null || _d === void 0 ? void 0 : _d.filter(function (item) { return (item === null || item === void 0 ? void 0 : item.itemID) === catEntryId_1; })) === null || _e === void 0 ? void 0 : _e[0]
                        : (_f = productDetails === null || productDetails === void 0 ? void 0 : productDetails.entitledItems) === null || _f === void 0 ? void 0 : _f[0];
                    articleId = productEntitledItems === null || productEntitledItems === void 0 ? void 0 : productEntitledItems.articleId;
                }
                else {
                    catEntryId_1 = currentVariant ? currentVariant === null || currentVariant === void 0 ? void 0 : currentVariant.itemId : (_g = productDetails === null || productDetails === void 0 ? void 0 : productDetails.bjsitems[0]) === null || _g === void 0 ? void 0 : _g.itemId;
                    articleId =
                        currentVariant && !!((_h = currentVariant === null || currentVariant === void 0 ? void 0 : currentVariant.entitleditem) === null || _h === void 0 ? void 0 : _h.articleId)
                            ? (_j = currentVariant === null || currentVariant === void 0 ? void 0 : currentVariant.entitleditem) === null || _j === void 0 ? void 0 : _j.articleId
                            : currentVariant
                                ? getVariantArticleId(productDetails, currentVariant === null || currentVariant === void 0 ? void 0 : currentVariant.itemId)
                                : getItemPartNumberOrArticleId(productDetails);
                }
                userSigninResponse = fetchUpdatedCookie(environment.USER_SIGNIN_RESPONSE_COOKIE);
                sddAddressId = userSigninResponse === null || userSigninResponse === void 0 ? void 0 : userSigninResponse.sddAddressId;
                sddZipCode = userSigninResponse === null || userSigninResponse === void 0 ? void 0 : userSigninResponse.sddZipCode;
                sddClubId = userSigninResponse === null || userSigninResponse === void 0 ? void 0 : userSigninResponse.sddClubId;
                if ((fullFillmentData === null || fullFillmentData === void 0 ? void 0 : fullFillmentData.fullFillmentType) === 'SDD') {
                    if (!sddClubId) {
                        dispatch(setSddAddressNotSelected(true));
                        dispatch(setSddFromAddCart(true));
                        return [2 /*return*/];
                    }
                    else {
                        dispatch(setSddAddressNotSelected(false));
                        dispatch(setSddFromAddCart(false));
                        serviceZipCode = sddZipCode;
                    }
                }
                if ((fullFillmentData === null || fullFillmentData === void 0 ? void 0 : fullFillmentData.fullFillmentType) === 'ONLINE') {
                    serviceZipCode = updatedZip || serviceZipCode;
                }
                if (!sddClubId) {
                    sddClubId = physicalStoreId;
                }
                payload = null;
                items = null;
                fulfillmentFrequency = null;
                if (!iswarranty) {
                    if (cartType === 'BOPIC') {
                        items = {
                            catEntryId: catEntryId_1,
                            articleId: articleId,
                            partNumber: '',
                            physicalStoreId: physicalStoreId,
                            pickUpInStore: 'Y',
                            quantity: count,
                            serviceZipcode: serviceZipCode
                        };
                    }
                    if (cartType === 'SDD') {
                        items = {
                            catEntryId: catEntryId_1,
                            articleId: articleId,
                            partNumber: '',
                            physicalStoreId: sddClubId,
                            pickUpInStore: 'N',
                            quantity: count,
                            sddAddressId: sddAddressId,
                            serviceZipcode: serviceZipCode
                        };
                    }
                    if (cartType === 'ONLINE') {
                        items = {
                            catEntryId: catEntryId_1,
                            articleId: articleId,
                            partNumber: '',
                            physicalStoreId: physicalStoreId,
                            pickUpInStore: 'N',
                            quantity: count,
                            serviceZipcode: serviceZipCode
                        };
                        if ((formattedProductData === null || formattedProductData === void 0 ? void 0 : formattedProductData.isGiftCardVariant) && (currentVariant === null || currentVariant === void 0 ? void 0 : currentVariant.giftCardRequest) != null) {
                            if (currentVariant.isDigital) {
                                items.receipent_name = currentVariant.giftCardRequest.recipientName;
                                items.email = currentVariant.giftCardRequest.recipientemail;
                                items.giftCard_message = currentVariant.giftCardRequest.giftMessageDig;
                            }
                            else {
                                items.receipent_name = currentVariant.giftCardRequest.giftTo;
                                items.senders_Name = '';
                                items.giftCard_message = currentVariant.giftCardRequest.giftMessagePhy;
                            }
                        }
                    }
                    if (cartType === 'SUBSCRIPTION') {
                        items = {
                            catEntryId: catEntryId_1,
                            articleId: articleId,
                            partNumber: '',
                            physicalStoreId: physicalStoreId,
                            pickUpInStore: 'N',
                            quantity: count,
                            serviceZipcode: serviceZipCode
                        };
                        //---should fetch based on fullfillment , currently added static value
                        fulfillmentFrequency = (fullFillmentData === null || fullFillmentData === void 0 ? void 0 : fullFillmentData.fulfillmentFrequency) || null;
                    }
                    //  This change is added because default BOPIC is removed from initialState.
                    // TODO - Refactor the ATC request
                    if (isNonBjsGiftCard) {
                        items = {
                            catEntryId: catEntryId_1,
                            articleId: articleId,
                            partNumber: '',
                            physicalStoreId: physicalStoreId,
                            pickUpInStore: 'N',
                            quantity: count,
                            serviceZipcode: serviceZipCode
                        };
                    }
                    isWarrantyInfo = (productDetails === null || productDetails === void 0 ? void 0 : productDetails.warrantyInformation)
                        ? (_k = productDetails === null || productDetails === void 0 ? void 0 : productDetails.warrantyInformation[catEntryId_1]) === null || _k === void 0 ? void 0 : _k.WarrantyCatEntryId
                        : null;
                    payload = normalizeAddCartRequest(cartType, items, fulfillmentFrequency, isWarrantyInfo);
                }
                WarrantyCatEntryId_1 = (_m = (_l = productDetails === null || productDetails === void 0 ? void 0 : productDetails.warrantyInformation) === null || _l === void 0 ? void 0 : _l[catEntryId_1]) === null || _m === void 0 ? void 0 : _m.WarrantyCatEntryId;
                if (isWarrantyChecked && (isWarrantyProduct || warrantyToCart)) {
                    product = [
                        {
                            catEntryId: catEntryId_1,
                            articleId: articleId,
                            partNumber: '',
                            physicalStoreId: cartType === 'SDD' ? sddClubId : physicalStoreId,
                            pickUpInStore: cartType === 'BOPIC' ? 'Y' : 'N',
                            quantity: count,
                            serviceZipcode: serviceZipCode
                        },
                        {
                            catEntryId: WarrantyCatEntryId_1,
                            partNumber: '',
                            physicalStoreId: physicalStoreId,
                            pickUpInStore: 'N',
                            quantity: count,
                            serviceZipcode: serviceZipCode
                        }
                    ];
                    if (cartType === 'SDD') {
                        product[0]['sddAddressId'] = sddAddressId;
                    }
                    warProduct = [
                        {
                            catEntryId: WarrantyCatEntryId_1,
                            partNumber: '',
                            physicalStoreId: physicalStoreId,
                            pickUpInStore: 'N',
                            quantity: count
                        }
                    ];
                    if (warrantyToCart && isViewCart)
                        payload = normalizeAddCartRequest('WARRENTYCART', warProduct);
                    else
                        payload = normalizeAddCartRequest('WARRENTY', product);
                }
                numberOfItems = payload.itemList[0].quantity;
                qofferId = [];
                try {
                    for (activeCoupons_1 = __values(activeCoupons), activeCoupons_1_1 = activeCoupons_1.next(); !activeCoupons_1_1.done; activeCoupons_1_1 = activeCoupons_1.next()) {
                        ac = activeCoupons_1_1.value;
                        qofferId.push(ac.offerCode);
                    }
                }
                catch (e_1_1) { e_1 = { error: e_1_1 }; }
                finally {
                    try {
                        if (activeCoupons_1_1 && !activeCoupons_1_1.done && (_a = activeCoupons_1.return)) _a.call(activeCoupons_1);
                    }
                    finally { if (e_1) throw e_1.error; }
                }
                payload.qofferId = qofferId;
                try {
                    addToCartFlag_1 = true;
                    onlineCartInfoCookie_1 = parseCookie(environment.CART_INFO_COOKIE);
                    postAddToCart(payload).then(function (res) {
                        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m;
                        atcDisableEvent('disableATC', false);
                        if ((res === null || res === void 0 ? void 0 : res.addToCartSuccess) == 'false' && (res === null || res === void 0 ? void 0 : res.errorCode) == '510') {
                            logInfo && logInfo('log', 'addToCartfailed');
                            var cartMaxItem_1 = res === null || res === void 0 ? void 0 : res.cartMaxItemCountAllowed;
                            dispatch(setCartMaxItem(cartMaxItem_1));
                        }
                        if (((_a = res === null || res === void 0 ? void 0 : res.ItemsAddResponse[0]) === null || _a === void 0 ? void 0 : _a.addToCartSuccess) == 'true') {
                            // show modal
                            setAddCartResponse(res === null || res === void 0 ? void 0 : res.ItemsAddResponse);
                            setAddToCartVisible(addToCartFlag_1);
                            var globalFulFillment = parseCookie(environment.GLOBAL_FULFILLMENT) || '';
                            if (isNullOrUndefinedOrEmpty(globalFulFillment) &&
                                !isNullOrUndefinedOrEmpty(fullFillmentData === null || fullFillmentData === void 0 ? void 0 : fullFillmentData.fullFillmentType)) {
                                globalFulfillmentEvent(environment.GLOBAL_FULFILLMENT, getfulfillmentName(fullFillmentData === null || fullFillmentData === void 0 ? void 0 : fullFillmentData.fullFillmentType));
                            }
                            var itemAddedToCart = (_c = (_b = res === null || res === void 0 ? void 0 : res.ItemsAddResponse) === null || _b === void 0 ? void 0 : _b[0]) === null || _c === void 0 ? void 0 : _c.addedQuantityToCart;
                            var productId = (_e = (_d = productDetails === null || productDetails === void 0 ? void 0 : productDetails.bjsitems) === null || _d === void 0 ? void 0 : _d[0]) === null || _e === void 0 ? void 0 : _e.partNumber;
                            var unitPrice = (_j = (_h = (_g = (_f = productDetails === null || productDetails === void 0 ? void 0 : productDetails.bjsitems) === null || _f === void 0 ? void 0 : _f[0]) === null || _g === void 0 ? void 0 : _g.itemStandardPrice) === null || _h === void 0 ? void 0 : _h.amount) === null || _j === void 0 ? void 0 : _j.toString();
                            // @ts-ignore
                            window.monetateQ = window.monetateQ || [];
                            // @ts-ignore
                            window.monetateQ.push([
                                'addProductDetails',
                                [{ productId: productId, sku: productId }]
                            ]);
                            addMonetateCustomVariables(cookies, kiboClubId);
                            // @ts-ignore
                            window.monetateQ.push(['setPageType', 'PDPATCModal']);
                            // @ts-ignore
                            window.monetateQ.push(['trackData', { nonPageView: true }]);
                            if (isCarousel) {
                                setAtcClicked(true);
                                setAddToCartSuccess(true);
                            }
                            if (itemAddedToCart <= 0) {
                                dispatch(setMaxQtyReachedError(true));
                                addToCartFlag_1 = false;
                            }
                            else {
                                dispatch(setMaxQtyReachedError(false));
                                addToCartFlag_1 = true;
                            }
                            if ((res === null || res === void 0 ? void 0 : res.ItemsAddResponse.length) === 1 &&
                                ((_k = res === null || res === void 0 ? void 0 : res.itemsCheckedInCartResp) === null || _k === void 0 ? void 0 : _k[0]) === WarrantyCatEntryId_1 &&
                                !isCarousel) {
                                dispatch(setNoWarranty(true));
                            }
                            var totalQuantity_1 = parseCookie(environment.CART_INFO_COOKIE) || 0;
                            if (totalQuantity_1) {
                                cartCookieEvent(environment.CART_INFO_COOKIE, totalQuantity_1);
                                if (!isCarousel) {
                                    dispatch(setQtyInCart(itemAddedToCart));
                                }
                                dispatch(setItemMaxQuantity(numberOfItems));
                                var selectedOrderId_1 = (_m = (_l = res.ItemsAddResponse) === null || _l === void 0 ? void 0 : _l[0]) === null || _m === void 0 ? void 0 : _m.orderItemId[0];
                                miniCartStoreId().then(function (res) {
                                    var _a;
                                    if (res) {
                                        var totalPrice = calculateTotalPrice(res);
                                        dispatch(setTotalPriceQuantity({ totalPrice: totalPrice, totalQuantity: totalQuantity_1 }));
                                        dispatch(setTotalQuantityInOrder(res.totalQuantityInOrder));
                                        var selectedMiniCartItem = (_a = res === null || res === void 0 ? void 0 : res.CustomorderItems) === null || _a === void 0 ? void 0 : _a.find(function (item) { var _a; return ((_a = item === null || item === void 0 ? void 0 : item.orderItem) === null || _a === void 0 ? void 0 : _a.orderItemId) === selectedOrderId_1; });
                                        if (!isCarousel) {
                                            dispatch(setMiniCartData(selectedMiniCartItem));
                                        }
                                    }
                                });
                            }
                            // SP-16810
                            if (!isCarousel) {
                                var type = location || 'Non-sticky Button';
                                addToCartAnalyticsEvent(cartType, itemAddedToCart, type, undefined, undefined, onlineCartInfoCookie_1, formattedProductData);
                            }
                            else {
                                ATCFreqBoughtItems(productDetails, cartType, heading, "addToCart");
                            }
                        }
                    });
                }
                catch (err) { }
            }
            salsifyOptions = {
                hasEnhancedContent: false,
                productIdType: 'BJSID',
                productId: partNumber,
                brand: brand,
                category: category,
                price: price,
                quantity: count
            };
            salsify.events.addToCart(salsifyOptions);
            return [2 /*return*/];
        });
    }); };
    var calculateTotalPrice = function (response) {
        var _a, _b, _c;
        var orderItems = response === null || response === void 0 ? void 0 : response.CustomorderItems;
        var totalItemPrice = 0;
        for (var i = 0; i < (orderItems === null || orderItems === void 0 ? void 0 : orderItems.length); i++) {
            if (((_b = (_a = orderItems[i]) === null || _a === void 0 ? void 0 : _a.orderItem) === null || _b === void 0 ? void 0 : _b.lineItemType) != 'DC') {
                var formatted = parseFloat((_c = orderItems[i]) === null || _c === void 0 ? void 0 : _c.FormattedProductTotalAmount).toFixed(2);
                totalItemPrice = totalItemPrice + parseFloat(formatted);
            }
        }
        totalItemPrice = totalItemPrice + (response === null || response === void 0 ? void 0 : response.instantRebate);
        return totalItemPrice;
    };
    var cartCookieEvent = function (name, value) {
        var event = new CustomEvent('cart-event', {
            detail: {
                name: name,
                value: value
            }
        });
        document.dispatchEvent(event);
    };
    return (React.createElement(AddCartStyling, null,
        React.createElement("div", { className: "button-part" }, !isCarousel ? (React.createElement(Button, { disabled: buttonGreyedOut
                ? buttonGreyedOut
                : btnAddToCartName === 'UNAVAILABLE' || notEnriched == true
                    ? isButtonDisabled
                    : atcDisabled, "auto-data": "product_addToCartBtn", ref: addCartRef, type: "submit", variant: btnAddToCartName === 'VIEW CART' || btnAddToCartName === 'SEARCH INVENTORY'
                ? 'outline-secondary'
                : 'primary', "data-cnstrc-btn": btnName == 'ADD TO CART' ? 'add_to_cart' : btnName, onClick: onAddToCart }, btnName)) : (React.createElement(ButtonCarousel, { "auto-data": "product_addToCartBtn", variant: "primary", className: "mt-1 ", disabled: isButtonDisabled, type: "submit", isCarousel: true, inCartBtn: inCartBtn, onClick: onAddToCart, "data-cnstrc-btn": inCartBtn === 'ADD TO CART' ? 'add_to_cart' : inCartBtn }, inCartBtn === 'ADD TO CART' ? (inCartBtn = '+ ADD') : inCartBtn))),
        React.createElement(FreePickupModal, { zipCode: getZipCode(cookies), show: modalShow === null || modalShow === void 0 ? void 0 : modalShow.freePickUp, onHide: function () {
                setModalShow(__assign(__assign({}, modalShow), { freePickUp: false }));
                atcDisableEvent('disableATC', false);
            } })));
};
export default AddToCart;
var templateObject_1, templateObject_2, templateObject_3;
