import { environmentConst } from '../../src/config/environments/constants';
import { parseCookie, parseObjectCookie, fetchUpdatedCookie } from './helper';
import { getKillSwitchForBopicProductLocations } from './storeConfHelper';
export var getProductDetails = function (pdpContext) {
    var _a;
    var productDetails = (_a = pdpContext === null || pdpContext === void 0 ? void 0 : pdpContext.data) === null || _a === void 0 ? void 0 : _a.productDetailsData;
    var item = productDetails === null || productDetails === void 0 ? void 0 : productDetails.entitledItems[0];
    var bjsitem = productDetails === null || productDetails === void 0 ? void 0 : productDetails.bjsitems[0];
    var itemId = item === null || item === void 0 ? void 0 : item.itemID;
    var articleId = bjsitem === null || bjsitem === void 0 ? void 0 : bjsitem.articleId;
    var userData = productDetails === null || productDetails === void 0 ? void 0 : productDetails.userData;
    var descriptiveAttributes = productDetails === null || productDetails === void 0 ? void 0 : productDetails.descriptiveAttributes;
    var killSwitchMicroService = userData === null || userData === void 0 ? void 0 : userData.killSwitchMicroService;
    var partNumber = item === null || item === void 0 ? void 0 : item.partNumber;
    var inventorySwitch = getInventorySwitch(killSwitchMicroService);
    return {
        item: item,
        itemId: itemId,
        articleId: articleId,
        inventorySwitch: inventorySwitch,
        descriptiveAttributes: descriptiveAttributes,
        partNumber: partNumber
    };
};
var getInventorySwitch = function (killSwitchMicroService) {
    if (killSwitchMicroService == 'ON') {
        return 'ON';
    }
    else {
        return 'OFF';
    }
};
export var getZipCode = function (cookies) {
    if (typeof window !== 'undefined') {
        var zipCode = cookies[environmentConst.CLUB_ZIP_CODE_COOKIE];
        if (zipCode) {
            return zipCode;
        }
        else {
            /**
             * Get info from localstorage
             */
            return getClubZipcode();
        }
    }
    return null;
};
export var getClubName = function (cookies) {
    if (typeof window !== 'undefined') {
        var clubName = cookies[environmentConst.CLUB_NAME_COOKIE];
        if (clubName) {
            return clubName;
        }
    }
    return null;
};
export var getClubFullName = function () {
    var _a, _b;
    var clubDetails = getClubDetails();
    if (clubDetails) {
        var clubFullName = ((_b = (_a = clubDetails === null || clubDetails === void 0 ? void 0 : clubDetails.Description) === null || _a === void 0 ? void 0 : _a[0]) === null || _b === void 0 ? void 0 : _b.displayStoreName) + "," + (clubDetails === null || clubDetails === void 0 ? void 0 : clubDetails.stateOrProvinceName);
        return clubFullName !== null && clubFullName !== void 0 ? clubFullName : null;
    }
    return null;
};
/**
 * Use to get zipcode
 * from localstorage of club details
 * Can be used if zipcode is not defined in cookie
 * as alternate method to avoid any breaking functionality
 */
export var getClubZipcode = function () {
    var clubDetails = getClubDetails();
    if (clubDetails) {
        var clubZipCode = clubDetails === null || clubDetails === void 0 ? void 0 : clubDetails.postalCode;
        return clubZipCode !== null && clubZipCode !== void 0 ? clubZipCode : null;
    }
    return null;
};
/**
 *
 * @returns clubDetails info from localstorage
 */
export var getClubDetails = function () {
    if (typeof window !== 'undefined') {
        var clubDetails = localStorage.getItem(environmentConst.CLUB_DETAILS_STORAGE_KEY);
        if (clubDetails) {
            clubDetails = JSON.parse(clubDetails);
            return clubDetails;
        }
        else {
            return null;
        }
    }
    return null;
};
export var getClubInventoryLatLong = function (geoCodeResults) {
    var _a, _b, _c, _d;
    var clubInventoryRequestObj = {};
    clubInventoryRequestObj['latitude'] = (_b = (_a = geoCodeResults === null || geoCodeResults === void 0 ? void 0 : geoCodeResults.geometry) === null || _a === void 0 ? void 0 : _a.location) === null || _b === void 0 ? void 0 : _b.lat();
    clubInventoryRequestObj['longitude'] = (_d = (_c = geoCodeResults === null || geoCodeResults === void 0 ? void 0 : geoCodeResults.geometry) === null || _c === void 0 ? void 0 : _c.location) === null || _d === void 0 ? void 0 : _d.lng();
    return clubInventoryRequestObj;
};
export var getBreadcrumbSchema = function (breadcrumb) {
    var _a, _b;
    var position = 1;
    var breadcrumbList = [
        {
            '@type': 'ListItem',
            position: position++,
            item: {
                '@id': '',
                name: 'Home'
            }
        }
    ];
    for (var product in breadcrumb) {
        if (typeof breadcrumb[product] === 'string' && breadcrumb[product].length > 0) {
            var productId = (_a = breadcrumb[product]) === null || _a === void 0 ? void 0 : _a.split('|')[1];
            var productName = (_b = breadcrumb[product]) === null || _b === void 0 ? void 0 : _b.split('|')[0].trim();
            breadcrumbList.push({
                '@type': 'ListItem',
                position: position++,
                item: {
                    '@id': productId,
                    name: productName
                }
            });
        }
    }
    var breadcrumbSchema = {
        '@context': 'http://schema.org',
        '@type': 'BreadcrumbList',
        itemListElement: [breadcrumbList]
    };
    return breadcrumbSchema;
};
export var isNullOrUndefinedOrEmpty = function (input) {
    if (undefined !== input && null !== input && '' !== input && 'undefined' !== input) {
        return false;
    }
    else {
        return true;
    }
};
export var isNullOrUndefined = function (input) {
    if (undefined !== input && null !== input) {
        return false;
    }
    else {
        return true;
    }
};
export var addMonetateCustomVariables = function (cookies, kiboClubId) {
    var headerClubId = kiboClubId;
    if (!isNullOrUndefined(headerClubId)) {
        var headerClub = "Club" + headerClubId;
        var clubIdData = [
            {
                name: 'ClubID',
                value: headerClub
            }
        ];
        // @ts-ignore
        monetateQ.push(['setCustomVariables', clubIdData]);
    }
    var isUserSignIn = cookies[environmentConst.USER_SIGNIN_RESPONSE_COOKIE];
    if (!isNullOrUndefinedOrEmpty(isUserSignIn)) {
        var memberId = localStorage.getItem('x_MembershipNumber') || (isUserSignIn === null || isUserSignIn === void 0 ? void 0 : isUserSignIn.x_MembershipNumber);
        if (!isNullOrUndefined(memberId)) {
            var memberData = [
                {
                    name: 'MemberID',
                    value: memberId
                }
            ];
            // @ts-ignore
            monetateQ.push(['setCustomVariables', memberData]);
        }
    }
    return;
};
export var getfulfillmentName = function (fulfillmentType) {
    switch (fulfillmentType) {
        case 'BOPIC':
            return 'Pickup';
        case 'SDD':
            return 'Delivery';
        case 'ONLINE':
            return 'Shipping';
    }
};
export var isTrialMember = function () {
    var isMemberTrial = false;
    var userSignInResponse = fetchUpdatedCookie(environmentConst.USER_SIGNIN_RESPONSE_COOKIE);
    if (!isNullOrUndefinedOrEmpty(userSignInResponse)) {
        if (((userSignInResponse === null || userSignInResponse === void 0 ? void 0 : userSignInResponse.x_MemberRole) == 'Register non-member' ||
            (userSignInResponse === null || userSignInResponse === void 0 ? void 0 : userSignInResponse.x_MemberRole) == 'non-member') &&
            !isNullOrUndefined(userSignInResponse === null || userSignInResponse === void 0 ? void 0 : userSignInResponse.isValidTrialMember)) {
            isMemberTrial = true;
        }
    }
    return isMemberTrial;
};
export var checkFeatureFlag = function (segmentName, featureSegments) {
    var isUserSignIn = parseObjectCookie([environmentConst.USER_SIGNIN_RESPONSE_COOKIE]);
    if (featureSegments != null &&
        featureSegments != undefined &&
        !isNullOrUndefinedOrEmpty(isUserSignIn)) {
        if (featureSegments.toString().includes(segmentName)) {
            return true;
        }
    }
    return false;
};
export var getMemberFacingLocations = function (storeConf) {
    if (getKillSwitchForBopicProductLocations(storeConf) &&
        checkFeatureFlag('DISPLAY_PRODUCT_LOCATION_PHASE1_20240124', parseCookie(environmentConst.B2C_MEMBER_SEGMENT))) {
        return true;
    }
    else if (!getKillSwitchForBopicProductLocations(storeConf)) {
        return true;
    }
    else {
        return false;
    }
};
