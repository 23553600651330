export var videoConfig = {
    pdpImageVideo: {
        // Web Collage
        webCollageVideoSrc: '//scontent.webcollage.net/api/v2/product-content',
        // Magic Toolbox Javacript Files
        magicZoomPlusSrc: '../../../../../assets/js/magiczoomplus.js',
        magicScrollSrc: '../../../../../assets/js/magicscroll.js',
        // Adobe Scene7 Source files
        s7MixedMediaViewerSrc: 'https://bjs.scene7.com/s7viewers/html5/js/MixedMediaViewer.js',
        s7VideoViewerSrc: 'https://bjs.scene7.com/s7viewers/html5/js/VideoViewer.js',
        // Adobe Scene7 URLs
        s7ViewerServerUrl: 'https://bjs.scene7.com/is/image/',
        s7ViewerContentUrl: 'https://bjs.scene7.com/is/content/',
        s7VideoServerUrl: 'https://bjs.scene7.com/is/content/',
        s7ContentUrl: 'https://bjs.scene7.com/skins/',
        // Adobe Scene7 Configurations
        s7VideoConfig_Universal_HTML5_Video: 'Scene7SharedAssets/Universal_HTML5_Video',
        s7MixedMediaViewerConfig_InlineMixedMedia_light: 'bjs/InlineMixedMedia_light',
        s7InlineZoomViewerConfig: 'bjs/InlineZoom',
        s7AssetPrefix: 'bjs/',
        s7AssetPrefixStaging: 'bjsstage/',
        s7MixedMediaAssetSuffix: '-mmset',
        s7MixedMediaApiCallSuffix: '?req=imageset,text',
        s7ImageExistsSuffix: '?req=exists,javascript',
        // 'No Image Found' Image Name
        noImageFound: 'no_image_found'
    }
};
