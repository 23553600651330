var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { SharedStyledButton } from '../../../shared/SharedStyledButton';
import StyledError from '../../../shared/StyledError';
import React, { useEffect, useState } from 'react';
import { FormControl } from 'react-bootstrap';
import { getZipCodeValdiation } from '../../../../services/pdpClient';
import styled from 'styled-components';
import { logInfo } from '@bjs/shared-micro-frontend';
var StyledTitle = styled.h4(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  color: #555;\n  font-size: 14px;\n  line-height: 22px;\n  font-weight: normal;\n"], ["\n  color: #555;\n  font-size: 14px;\n  line-height: 22px;\n  font-weight: normal;\n"])));
var StyledButton = styled(SharedStyledButton)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  padding-left: 12px;\n  padding-right: 12px;\n"], ["\n  padding-left: 12px;\n  padding-right: 12px;\n"])));
var StyledInput = styled(FormControl)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  height: 40px;\n  margin-right: 15px;\n  border-top-right-radius: 5px;\n  border-radius: 5px;\n  border: 1px solid #c8c8c8;\n  padding: 10px;\n  font-size: 13px;\n  color: black;\n  -moz-appearance: textfield;\n  &:focus {\n    box-shadow: none;\n    border-color: #c8c8c8;\n    color: black;\n  }\n  &::-webkit-outer-spin-button,\n  &::-webkit-inner-spin-button {\n    -webkit-appearance: none;\n    margin: 0;\n  }\n"], ["\n  height: 40px;\n  margin-right: 15px;\n  border-top-right-radius: 5px;\n  border-radius: 5px;\n  border: 1px solid #c8c8c8;\n  padding: 10px;\n  font-size: 13px;\n  color: black;\n  -moz-appearance: textfield;\n  &:focus {\n    box-shadow: none;\n    border-color: #c8c8c8;\n    color: black;\n  }\n  &::-webkit-outer-spin-button,\n  &::-webkit-inner-spin-button {\n    -webkit-appearance: none;\n    margin: 0;\n  }\n"])));
var StyledPicodeRow = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  display: flex;\n  margin: 6px 0 5px;\n  .pincode-input {\n    width: 100%;\n  }\n  .save-btn {\n    margin-left: 0px;\n    width: 79.82px;\n  }\n"], ["\n  display: flex;\n  margin: 6px 0 5px;\n  .pincode-input {\n    width: 100%;\n  }\n  .save-btn {\n    margin-left: 0px;\n    width: 79.82px;\n  }\n"])));
var ZipCodeForm = function (props) {
    // zip code inherited state and event
    var zipCode = props.zipCode, updateZipCode = props.updateZipCode;
    // zip code local component state
    var _a = __read(useState(), 2), cZip = _a[0], setCZip = _a[1];
    var _b = __read(useState(''), 2), error = _b[0], setError = _b[1];
    var _c = __read(useState(false), 2), submitted = _c[0], setSubmitted = _c[1];
    // update zip code in parent component
    var onSubmit = function (e) { return __awaiter(void 0, void 0, void 0, function () {
        var zipValidationResponse, err_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    e.preventDefault();
                    setSubmitted(true);
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 4, , 5]);
                    if (!cZip) return [3 /*break*/, 3];
                    return [4 /*yield*/, getZipCodeValdiation(cZip)];
                case 2:
                    zipValidationResponse = _a.sent();
                    if ((zipValidationResponse === null || zipValidationResponse === void 0 ? void 0 : zipValidationResponse.validZipCode) === 'true') {
                        updateZipCode(cZip);
                    }
                    else if ((zipValidationResponse === null || zipValidationResponse === void 0 ? void 0 : zipValidationResponse.validZipCode) === 'false') {
                        setError('Zip Code Not Valid');
                    }
                    _a.label = 3;
                case 3: return [3 /*break*/, 5];
                case 4:
                    err_1 = _a.sent();
                    logInfo && logInfo('error', 'zipcodevalidation api failure', err_1);
                    return [3 /*break*/, 5];
                case 5: return [2 /*return*/];
            }
        });
    }); };
    var setZipCode = function (e) {
        var target = e.target;
        if (target.value.length <= 5)
            setCZip(target.value);
    };
    useEffect(function () {
        if (cZip || !submitted)
            setError('');
        else
            setError('Zip Code Not Valid');
    }, [cZip, submitted]);
    return (React.createElement("form", { className: "pb-2", onSubmit: onSubmit, "auto-data": "product_shippingFF_changeZipcodewindow" },
        React.createElement(StyledTitle, null, "Enter another Zip Code to check shipping eligibility:"),
        error && (React.createElement(StyledError, { className: "error mb-2" },
            React.createElement("img", { src: "https://www.bjs.com/assets/images/icons/warning.svg", alt: "error" }),
            error)),
        React.createElement(StyledPicodeRow, null,
            React.createElement(StyledInput, { placeholder: "*Zip Code", type: "number", value: cZip, onChange: setZipCode, className: "pincode-input", "auto-data": "product_shippingFF_zipcodeTxtBox" }),
            React.createElement(StyledButton, { type: "submit", variant: "primary", className: "btn-block m-0 save-btn", "auto-data": "product_shippingFF_saveZipcodeBtn" }, "Save"))));
};
export default ZipCodeForm;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
