var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from 'react';
import styled from 'styled-components';
var StyledHeading = styled.h1.attrs(function () { return ({
    className: 'mt-0'
}); })(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  color: #303030;\n  font-size: 18px;\n  font-family: 'Roboto', sans-serif;\n  font-weight: 700;\n  padding-bottom: 5px;\n  margin: 0px;\n  @media (max-width: 767px) {\n    padding-left: 5px;\n  }\n"], ["\n  color: #303030;\n  font-size: 18px;\n  font-family: 'Roboto', sans-serif;\n  font-weight: 700;\n  padding-bottom: 5px;\n  margin: 0px;\n  @media (max-width: 767px) {\n    padding-left: 5px;\n  }\n"])));
export var ProductTitle = function (_a) {
    var title = _a.title;
    return React.createElement(StyledHeading, null, title && React.createElement("span", { "auto-data": "product_name" }, title));
};
var templateObject_1;
