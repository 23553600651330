import React, { useEffect } from 'react';
var ExternalScript = function (_a) {
    var elementSrc = _a.elementSrc, articleId = _a.articleId, elementId = _a.elementId, elementClass = _a.elementClass;
    useEffect(function () {
        var extScript = document.createElement('script');
        extScript.src = elementSrc;
        extScript.setAttribute('data-id', '8098');
        extScript.setAttribute('data-productid', articleId);
        extScript.setAttribute('data-key', 'aQXYQLxTDL1u0HGawld9a1sFQPBFWujtaVRpPbIh');
        extScript.type = 'text/javascript';
        extScript.async = true;
        document.getElementById(elementId).appendChild(extScript);
        return function () {
            document.getElementById(elementId).removeChild(extScript);
        };
    }, []);
    return React.createElement("div", { style: { width: '100%' }, id: elementId, className: elementClass });
};
export default ExternalScript;
