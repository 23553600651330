var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { useContext } from 'react';
import { Helmet } from 'react-helmet-async';
import { getBreadcrumbSchema, isNullOrUndefined } from '../../utils/pdpUtil';
import { useStore } from '../../store/cart/CartProvider';
import { PdpContext } from '../../store/pdp/PdpContext';
import { getProductIdFromPathname, isMapItem } from '../../utils/helper';
var SeoContainer = function (props) {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j;
    var productData = useContext(PdpContext);
    var productId;
    if (typeof window !== 'undefined') {
        productId = getProductIdFromPathname(window.location.pathname);
    }
    var productDetails = (_a = productData === null || productData === void 0 ? void 0 : productData.data) === null || _a === void 0 ? void 0 : _a.productDetailsData;
    var formattedProduct = productData === null || productData === void 0 ? void 0 : productData.productData;
    var seoData = (_b = productDetails === null || productDetails === void 0 ? void 0 : productDetails.productSeoData) === null || _b === void 0 ? void 0 : _b[0];
    var productImages = productDetails === null || productDetails === void 0 ? void 0 : productDetails.productImages;
    var breadCrumbData = productDetails === null || productDetails === void 0 ? void 0 : productDetails.breadCrumbDetail;
    var description = (seoData === null || seoData === void 0 ? void 0 : seoData.meta_desc) ||
        ((_f = (_e = (_d = (_c = productDetails === null || productDetails === void 0 ? void 0 : productDetails.entitledItems) === null || _c === void 0 ? void 0 : _c[0]) === null || _d === void 0 ? void 0 : _d.description) === null || _e === void 0 ? void 0 : _e.longDescription) === null || _f === void 0 ? void 0 : _f.substring(0, 299));
    var title = (seoData === null || seoData === void 0 ? void 0 : seoData.title) || ((_g = productDetails === null || productDetails === void 0 ? void 0 : productDetails.description) === null || _g === void 0 ? void 0 : _g.name) || '';
    var productImage = (productImages === null || productImages === void 0 ? void 0 : productImages.fullImage) || '';
    var _k = __read(useStore(), 2), state = _k[0], dispatch = _k[1];
    var priceData = state.priceData, fullFillmentData = state.fullFillmentData;
    var offers = [];
    var mapPrice = fetchMapPrice();
    var isMapTypeProduct = isMapItem(productDetails);
    function fetchMapPrice() {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j;
        var mapItem = false;
        mapItem = (_a = productDetails === null || productDetails === void 0 ? void 0 : productDetails.hiddenAttr) === null || _a === void 0 ? void 0 : _a.find(function (d) {
            if (d.name === 'MAP_Type' || d.name == 'ShowPriceCartPostLogin') {
                return true;
            }
        });
        if (mapItem &&
            !isNullOrUndefined((_c = (_b = productDetails === null || productDetails === void 0 ? void 0 : productDetails.bjsitems) === null || _b === void 0 ? void 0 : _b[0]) === null || _c === void 0 ? void 0 : _c.itemStandardPrice) &&
            ((_f = (_e = (_d = productDetails === null || productDetails === void 0 ? void 0 : productDetails.bjsitems) === null || _d === void 0 ? void 0 : _d[0]) === null || _e === void 0 ? void 0 : _e.itemStandardPrice) === null || _f === void 0 ? void 0 : _f.amount) !== 0) {
            return (_j = (_h = (_g = productDetails === null || productDetails === void 0 ? void 0 : productDetails.bjsitems) === null || _g === void 0 ? void 0 : _g[0]) === null || _h === void 0 ? void 0 : _h.itemStandardPrice) === null || _j === void 0 ? void 0 : _j.amount;
        }
        return null;
    }
    var availability = ((_h = productDetails === null || productDetails === void 0 ? void 0 : productDetails.bjsItemsInventory) === null || _h === void 0 ? void 0 : _h[0].availInventory) == false
        ? 'OutOfStock'
        : 'http://schema.org/InStock';
    offers.push({
        '@type': 'Offer',
        availability: availability,
        seller: { '@type': 'Organization', name: "BJ's Wholesale Club" },
        priceCurrency: 'USD',
        price: mapPrice !== null ? mapPrice : priceData === null || priceData === void 0 ? void 0 : priceData.displayPrice
    });
    var productSchema = {
        '@context': 'http://schema.org/',
        '@type': 'Product',
        offers: !isMapTypeProduct && offers.length ? offers : '',
        sku: formattedProduct === null || formattedProduct === void 0 ? void 0 : formattedProduct.articleId,
        mpn: formattedProduct === null || formattedProduct === void 0 ? void 0 : formattedProduct.manufacturerPartNumber,
        image: productImage,
        name: formattedProduct === null || formattedProduct === void 0 ? void 0 : formattedProduct.productName,
        url: (typeof window !== 'undefined' && window.location.href) || '',
        description: formattedProduct === null || formattedProduct === void 0 ? void 0 : formattedProduct.description,
        gtin13: formattedProduct === null || formattedProduct === void 0 ? void 0 : formattedProduct.partNumber,
        model: formattedProduct === null || formattedProduct === void 0 ? void 0 : formattedProduct.model,
        brand: {
            '@type': 'Brand',
            name: (_j = formattedProduct === null || formattedProduct === void 0 ? void 0 : formattedProduct.shopBrand) === null || _j === void 0 ? void 0 : _j.brandName
        },
        aggregateRating: {
            '@type': 'AggregateRating',
            ratingValue: formattedProduct === null || formattedProduct === void 0 ? void 0 : formattedProduct.averageRating,
            reviewCount: formattedProduct === null || formattedProduct === void 0 ? void 0 : formattedProduct.totalReviews
        }
    };
    function getProductUrl() {
        var _a, _b, _c;
        var host = typeof window !== 'undefined' ? window.location.hostname : 'www.bjs.com';
        var link = "https://" + host + ((_c = (_b = (_a = props === null || props === void 0 ? void 0 : props.requestObj) === null || _a === void 0 ? void 0 : _a.pathname) === null || _b === void 0 ? void 0 : _b.split('?')) === null || _c === void 0 ? void 0 : _c[0]);
        return link;
    }
    function renderBreadCrumbJsonScript() {
        if (breadCrumbData && (breadCrumbData === null || breadCrumbData === void 0 ? void 0 : breadCrumbData[0]) !== undefined) {
            return (React.createElement("script", { async: true, defer: true, type: "application/ld+json", "data-testid": "breadcrumb-json", "auto-data": "breadcrumb_json" }, JSON.stringify(getBreadcrumbSchema(breadCrumbData === null || breadCrumbData === void 0 ? void 0 : breadCrumbData[0]))));
        }
        return null;
    }
    return (React.createElement(Helmet, null,
        title && React.createElement("title", null,
            title,
            " | BJ's Wholesale Club"),
        React.createElement("link", { rel: "canonical", href: getProductUrl() }),
        description && React.createElement("meta", { name: "description", content: description }),
        React.createElement("meta", { name: "author", content: "BJ's" }),
        React.createElement("meta", { property: "og:title", content: title }),
        React.createElement("meta", { property: "og:description", content: description }),
        React.createElement("meta", { property: "og:image", content: productImage }),
        React.createElement("meta", { property: "og:url", content: getProductUrl() }),
        React.createElement("meta", { property: "og:site_name", content: "BJ's Wholesale Club" }),
        React.createElement("meta", { name: "twitter:card", content: "summary_large_image" }),
        React.createElement("meta", { name: "twitter:title", content: title }),
        React.createElement("meta", { name: "twitter:description", content: "Buy Now" }),
        React.createElement("meta", { name: "twitter:site", content: "bjs.com" }),
        React.createElement("meta", { name: "twitter:image", content: productImage }),
        React.createElement("script", { type: "application/ld+json" }, JSON.stringify(productSchema)),
        renderBreadCrumbJsonScript()));
};
export default SeoContainer;
