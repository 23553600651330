export var checkFeatureFlag = function (segmentName, featureSegments) {
    var _a;
    if (featureSegments != null && featureSegments != undefined) {
        if ((_a = featureSegments === null || featureSegments === void 0 ? void 0 : featureSegments.toString()) === null || _a === void 0 ? void 0 : _a.includes(segmentName)) {
            return true;
        }
    }
    return false;
};
// check for both bjs and non bjs gift card on pdp
export var PDPHasGCItem = function (productDetails) {
    var merchatId = productDetails === null || productDetails === void 0 ? void 0 : productDetails.merchandiseCatagoryIdentifier;
    var GCMerchCatID = ['402030190', '402030242', '402030191', 'B01010239'];
    if (merchatId != null || merchatId != undefined || merchatId != '') {
        if (GCMerchCatID === null || GCMerchCatID === void 0 ? void 0 : GCMerchCatID.includes(merchatId === null || merchatId === void 0 ? void 0 : merchatId.toString())) {
            return true;
        }
    }
    return false;
};
export var cobrandSavingsAmount = function (productDetails, userSignInResponse) {
    var _a, _b, _c, _d, _e, _f;
    var amount = 0;
    var savings = '5%';
    var cardImage = ' https://bjs.scene7.com/is/image/bjs/2022_BJs_OnePlusCard';
    //Product amount
    if ((productDetails === null || productDetails === void 0 ? void 0 : productDetails.entitledItems) && Array.isArray(productDetails.entitledItems)) {
        amount = (_b = (_a = productDetails === null || productDetails === void 0 ? void 0 : productDetails.entitledItems[0]) === null || _a === void 0 ? void 0 : _a.calculatedContractPrice) === null || _b === void 0 ? void 0 : _b.amount;
    }
    if (amount > 500) {
        if ((userSignInResponse === null || userSignInResponse === void 0 ? void 0 : userSignInResponse.cobrandProductId) === 'BJSONE') {
            savings = '$' + ((_d = (_c = (amount * 0.03)) === null || _c === void 0 ? void 0 : _c.toFixed(0)) === null || _d === void 0 ? void 0 : _d.toString());
            cardImage = 'https://bjs.scene7.com/is/image/bjs/One-Card?fmt=png-alpha';
        }
        else {
            savings = '$' + ((_f = (_e = (amount * 0.05)) === null || _e === void 0 ? void 0 : _e.toFixed(0)) === null || _f === void 0 ? void 0 : _f.toString());
        }
    }
    else {
        if ((userSignInResponse === null || userSignInResponse === void 0 ? void 0 : userSignInResponse.cobrandProductId) === 'BJSONE') {
            savings = '3%';
            cardImage = 'https://bjs.scene7.com/is/image/bjs/One-Card?fmt=png-alpha';
        }
        else {
            savings = '5%';
        }
    }
    return {
        savings: savings,
        cardImage: cardImage
    };
};
export var isLoggedIn = function (userSignInResponse) {
    if (userSignInResponse != '' &&
        userSignInResponse != null &&
        userSignInResponse != undefined &&
        (userSignInResponse === null || userSignInResponse === void 0 ? void 0 : userSignInResponse.ableToCobrand) === 'Y') {
        return true;
    }
    return false;
};
