var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { useContext } from 'react';
import { useCookies } from 'react-cookie';
import { environmentConst, iconLinks } from '../../config/environments/constants';
import { useFulfillmentStore } from '../../store/fulfillment/FulfillmentProvider';
import { FullFillmentType } from '../../utils/types';
import { PdpContext } from '../../store/pdp/PdpContext';
import { getKillSwitchBOPIC, getKillSwitchSDD } from '../../utils/storeConfHelper';
import { checkRestrictedZipcodes, fetchUpdatedCookie } from '../../utils/helper';
var FulfillmentOptions = function (_a) {
    var _b, _c;
    var onHandleSelect = _a.onHandleSelect, selectedFulfillment = _a.selectedFulfillment, isBopicAvailable = _a.isBopicAvailable;
    var _d = __read(useCookies(), 3), cookies = _d[0], setCookie = _d[1], removeCookie = _d[2];
    var storeConfContext = useContext(PdpContext);
    var isProductHeavyItem = (_b = storeConfContext === null || storeConfContext === void 0 ? void 0 : storeConfContext.productData) === null || _b === void 0 ? void 0 : _b.isProductHeavyItem;
    var _e = __read(useFulfillmentStore(), 2), fulfillmentState = _e[0], fulFillmentDispatch = _e[1];
    var fulfillmentBopic = fulfillmentState.fulfillmentBopic, fulfillmentOnline = fulfillmentState.fulfillmentOnline, fulfillmentSdd = fulfillmentState.fulfillmentSdd;
    var pdpZipNotServiceable = fetchUpdatedCookie(environmentConst.PDP_ZIP_NOT_SERVICEABLE);
    var isSddDisabled = (fulfillmentSdd === null || fulfillmentSdd === void 0 ? void 0 : fulfillmentSdd.disabled) || pdpZipNotServiceable === 'true';
    var killSwitchBOPIC = getKillSwitchBOPIC(storeConfContext);
    var killSwitchSDD = getKillSwitchSDD(storeConfContext);
    var hideBopic = !(fulfillmentBopic === null || fulfillmentBopic === void 0 ? void 0 : fulfillmentBopic.isInventoryAvailable) && !(fulfillmentBopic === null || fulfillmentBopic === void 0 ? void 0 : fulfillmentBopic.isEligible);
    var FreepickupICO = iconLinks.FreepickupICO, DeliveryICO = iconLinks.DeliveryICO, ShippingICO = iconLinks.ShippingICO;
    return (React.createElement(React.Fragment, null,
        killSwitchBOPIC == 'ON' && (fulfillmentBopic === null || fulfillmentBopic === void 0 ? void 0 : fulfillmentBopic.displayItem) && !hideBopic && (React.createElement("li", { className: "ff-fp-wrap", "auto-data": "product_freePickupFF_visualBox", onClick: function () {
                return selectedFulfillment == '1'
                    ? null
                    : (fulfillmentBopic === null || fulfillmentBopic === void 0 ? void 0 : fulfillmentBopic.disabled)
                        ? onHandleSelect(FullFillmentType.BOPIC, 'SEARCH INVENTORY', 1)
                        : onHandleSelect(FullFillmentType.BOPIC, 'ADD TO CART', 1);
            } },
            React.createElement("input", { type: "radio", id: "fullfillment-free_pickup", name: "fullfillment-select", checked: selectedFulfillment == '1', value: "1" }),
            React.createElement("label", { htmlFor: "fullfillment-free_pickup", className: "fullfillment-content", "auto-data": "product_freePickupFF_detailsInVisualBox" },
                React.createElement("i", { className: "icon" },
                    React.createElement("img", { src: FreepickupICO })),
                (fulfillmentBopic === null || fulfillmentBopic === void 0 ? void 0 : fulfillmentBopic.isInventoryAvailable) ? (React.createElement(React.Fragment, null,
                    React.createElement("p", { className: "title", style: { fontFamily: 'Roboto,sans-serif' } }, selectedFulfillment == '1' ? React.createElement("b", null, "Pickup") : 'Pickup'),
                    !isBopicAvailable && (React.createElement(React.Fragment, null,
                        React.createElement("p", { className: "sub-title" }, "Purchase"),
                        React.createElement("p", { className: "ff-time" }, "In-Club Only"))))) : (React.createElement(React.Fragment, null,
                    React.createElement("p", { className: "title", style: { fontFamily: 'Roboto,sans-serif' } }, selectedFulfillment == '1' ? React.createElement("b", null, "Pickup") : 'Pickup'),
                    (fulfillmentBopic === null || fulfillmentBopic === void 0 ? void 0 : fulfillmentBopic.isWithIn25MilesAvailable) ? (React.createElement(React.Fragment, null,
                        React.createElement("p", { className: "sub-title" }, "Find within"),
                        React.createElement("p", { className: "ff-time" }, "25 Miles"))) : (React.createElement("p", { className: "ff-time sold-out-pos" },
                        React.createElement("span", { className: "sold-out" },
                            React.createElement("i", { className: "fa fa-ban", "aria-hidden": "true" }),
                            " Sold Out")))))))),
        killSwitchSDD != 'ON' && (fulfillmentSdd === null || fulfillmentSdd === void 0 ? void 0 : fulfillmentSdd.displayItem) && (React.createElement("li", { className: "ff-sdd-wrap", "auto-data": "product_sddFF_visualBox", onClick: function () {
                return selectedFulfillment == '2'
                    ? null
                    : isSddDisabled
                        ? onHandleSelect(FullFillmentType.SDD, 'UNAVAILABLE', 2)
                        : onHandleSelect(FullFillmentType.SDD, 'SIGN IN TO ADD', 2);
            } },
            React.createElement("input", { type: "radio", id: "fullfillment-sdd", name: "fullfillment-select", checked: selectedFulfillment == '2', value: "2" }),
            React.createElement("label", { htmlFor: "fullfillment-sdd", className: "fullfillment-content", "auto-data": "product_sddFF_detailsInVisualBox" },
                React.createElement("i", { className: "icon" },
                    React.createElement("img", { src: DeliveryICO })),
                React.createElement("p", { className: "title", style: { fontFamily: 'Roboto,sans-serif' } }, selectedFulfillment == '2' ? React.createElement("b", null, "Delivery") : 'Delivery'),
                (fulfillmentSdd === null || fulfillmentSdd === void 0 ? void 0 : fulfillmentSdd.isInventoryAvailable) && !isSddDisabled ? (React.createElement(React.Fragment, null,
                    isProductHeavyItem && (React.createElement("p", { className: "sub-title" },
                        "Get it ",
                        React.createElement("span", { className: "ff-time" }, "Today"))),
                    !isProductHeavyItem && (React.createElement(React.Fragment, null,
                        React.createElement("p", { className: "sub-title" }, "In as little as"),
                        React.createElement("p", { className: "ff-time" }, "2 hours"))))) : !(fulfillmentSdd === null || fulfillmentSdd === void 0 ? void 0 : fulfillmentSdd.isInventoryAvailable) ? (React.createElement("p", { className: "ff-time sold-out-pos" },
                    React.createElement("span", { className: "sold-out" },
                        React.createElement("i", { className: "fa fa-ban", "aria-hidden": "true" }),
                        " Sold Out"))) : (isSddDisabled && (React.createElement(React.Fragment, null,
                    React.createElement("span", { className: "sub-title-sdd" }, "In your area"),
                    React.createElement("br", null),
                    React.createElement("span", { className: "unavailable" }, "Unavailable"))))))),
        (fulfillmentOnline === null || fulfillmentOnline === void 0 ? void 0 : fulfillmentOnline.displayItem) && (React.createElement("li", { className: "ff-shipping-wrap", "auto-data": "product_shippingFF_visualBox", onClick: function () {
                return selectedFulfillment == '3'
                    ? null
                    : (fulfillmentOnline === null || fulfillmentOnline === void 0 ? void 0 : fulfillmentOnline.disabled)
                        ? onHandleSelect(FullFillmentType.ONLINE, 'UNAVAILABLE', 3)
                        : onHandleSelect(FullFillmentType.ONLINE, 'ADD TO CART', 3);
            } },
            React.createElement("input", { type: "radio", id: "fullfillment-shipping", name: "fullfillment-select", checked: selectedFulfillment == '3', value: "3" }),
            React.createElement("label", { htmlFor: "fullfillment-shipping", className: "fullfillment-content", "auto-data": "product_shippingFF_ddetailsInVisualBox" },
                React.createElement("i", { className: "icon" },
                    React.createElement("img", { src: ShippingICO })),
                React.createElement("p", { className: "title", style: { fontFamily: 'Roboto,sans-serif' } }, selectedFulfillment == '3' ? (React.createElement("b", null, (fulfillmentOnline === null || fulfillmentOnline === void 0 ? void 0 : fulfillmentOnline.isFreeShipping) ? 'Free Shipping' : 'Shipping')) : (fulfillmentOnline === null || fulfillmentOnline === void 0 ? void 0 : fulfillmentOnline.isFreeShipping) ? ('Free Shipping') : ('Shipping')),
                checkRestrictedZipcodes((_c = storeConfContext === null || storeConfContext === void 0 ? void 0 : storeConfContext.data) === null || _c === void 0 ? void 0 : _c.shippingRestriction) ? (React.createElement(React.Fragment, null,
                    React.createElement("span", { className: "sub-title-sdd" }, "In your area"),
                    React.createElement("p", { className: "ff-time" },
                        React.createElement("span", { className: "sold-out unavailable" }, "Unavailable")))) : (!(fulfillmentOnline === null || fulfillmentOnline === void 0 ? void 0 : fulfillmentOnline.isInventoryAvailable) && (React.createElement("p", { className: "ff-time sold-out-pos" },
                    React.createElement("span", { className: "sold-out" },
                        React.createElement("i", { className: "fa fa-ban", "aria-hidden": "true" }),
                        " Sold Out")))))))));
};
export default FulfillmentOptions;
