import uniqBy from 'lodash/uniqBy';
export var checkIsVariantProduct = function (productDetails) {
    if ((productDetails === null || productDetails === void 0 ? void 0 : productDetails.definingAttributes) && (productDetails === null || productDetails === void 0 ? void 0 : productDetails.definingAttributes.length) > 0) {
        return true;
    }
    return false;
};
export var getProductName = function (productDetails) {
    var _a, _b, _c, _d;
    var productName = '';
    if (checkIsVariantProduct(productDetails)) {
        productName = (_a = productDetails === null || productDetails === void 0 ? void 0 : productDetails.description) === null || _a === void 0 ? void 0 : _a.name;
    }
    else {
        productName = (_d = (_c = (_b = productDetails === null || productDetails === void 0 ? void 0 : productDetails.entitledItems) === null || _b === void 0 ? void 0 : _b[0]) === null || _c === void 0 ? void 0 : _c.description) === null || _d === void 0 ? void 0 : _d.name;
    }
    return productName;
};
export var getItemPartNumberOrArticleId = function (productDetails) {
    var _a, _b;
    var articleId = null;
    if (checkIsVariantProduct(productDetails)) {
        var defaultItemId_1 = productDetails === null || productDetails === void 0 ? void 0 : productDetails.itemPrices.defaultItemId;
        var defaultItem = productDetails === null || productDetails === void 0 ? void 0 : productDetails.entitledItems.find(function (p) { return p.itemID === defaultItemId_1; });
        if (!!defaultItem) {
            articleId = defaultItem === null || defaultItem === void 0 ? void 0 : defaultItem.articleId;
        }
        else {
            articleId = (_a = productDetails === null || productDetails === void 0 ? void 0 : productDetails.entitledItems) === null || _a === void 0 ? void 0 : _a[0].articleId;
        }
    }
    else {
        articleId = (_b = productDetails === null || productDetails === void 0 ? void 0 : productDetails.entitledItems) === null || _b === void 0 ? void 0 : _b[0].articleId;
    }
    return articleId;
};
export var getVariantArticleId = function (productDetails, itemId) {
    var variantItem = productDetails === null || productDetails === void 0 ? void 0 : productDetails.entitledItems.find(function (p) { return p.itemID === itemId; });
    if (variantItem) {
        return variantItem === null || variantItem === void 0 ? void 0 : variantItem.articleId;
    }
};
export var getProductModel = function (productDetails) {
    var _a;
    return (_a = productDetails === null || productDetails === void 0 ? void 0 : productDetails.entitledItems) === null || _a === void 0 ? void 0 : _a[0].manufacturerPartNumber;
};
export var getProductShopBrand = function (productDetails) {
    var _a;
    var shopBrandAttr = (_a = productDetails === null || productDetails === void 0 ? void 0 : productDetails.hiddenAttr) === null || _a === void 0 ? void 0 : _a.find(function (d) { return (d === null || d === void 0 ? void 0 : d.name) == 'ShopBrand'; });
    if (shopBrandAttr) {
        var brandName = productDetails === null || productDetails === void 0 ? void 0 : productDetails.manufacturerName;
        return { brandName: brandName, url: "/search?visualVariant.nonvisualVariant.mfname=" + brandName };
    }
    return null;
};
export var getProductAverageRating = function (productDetails) {
    var _a;
    return (_a = productDetails === null || productDetails === void 0 ? void 0 : productDetails.bjsrating) === null || _a === void 0 ? void 0 : _a.avgOvrlRating;
};
export var getProductTotalReviews = function (productDetails) {
    var _a;
    return (_a = productDetails === null || productDetails === void 0 ? void 0 : productDetails.bjsrating) === null || _a === void 0 ? void 0 : _a.fullReviews;
};
export var getColorVariantProduct = function (productDetails) {
    try {
        if (checkIsVariantProduct(productDetails)) {
            var colorVariantAttributes = productDetails === null || productDetails === void 0 ? void 0 : productDetails.definingAttributes.find(function (d) { var _a; return ((_a = d === null || d === void 0 ? void 0 : d.name) === null || _a === void 0 ? void 0 : _a.toLowerCase()) === 'color'; });
            if (colorVariantAttributes) {
                var colorVariants = colorVariantAttributes === null || colorVariantAttributes === void 0 ? void 0 : colorVariantAttributes.attributeValueDataBeans.map(function (variant) {
                    var _a, _b, _c, _d;
                    if (variant.image1) {
                        variant.displayImage = variant.image1;
                    }
                    else {
                        var entitledItem = productDetails === null || productDetails === void 0 ? void 0 : productDetails.entitledItems.find(function (d) { return d.itemID === variant.itemId; });
                        if ((_a = entitledItem === null || entitledItem === void 0 ? void 0 : entitledItem.description) === null || _a === void 0 ? void 0 : _a.fullImage) {
                            variant.displayImage = (_b = entitledItem === null || entitledItem === void 0 ? void 0 : entitledItem.description) === null || _b === void 0 ? void 0 : _b.fullImage;
                        }
                        else {
                            variant.displayImage =
                                'https://bjs.scene7.com/is/image/bjs/' +
                                    (entitledItem === null || entitledItem === void 0 ? void 0 : entitledItem.partNumber) +
                                    '?$bjs-Initial350$';
                        }
                    }
                    var inventory = productDetails === null || productDetails === void 0 ? void 0 : productDetails.bjsItemsInventory.find(function (d) { return d.itemId === variant.itemId; });
                    if ((((_c = productDetails === null || productDetails === void 0 ? void 0 : productDetails.itemAvailDetails[variant.itemId]) === null || _c === void 0 ? void 0 : _c.itemAvailableOnline) == 'Y' &&
                        (inventory === null || inventory === void 0 ? void 0 : inventory.availInventory) === true) ||
                        ((_d = productDetails === null || productDetails === void 0 ? void 0 : productDetails.itemAvailDetails[variant.itemId]) === null || _d === void 0 ? void 0 : _d.itemAvailableInClub) == 'Y') {
                        variant.isInventoryAvailable = true;
                    }
                    else {
                        variant.isInventoryAvailable = false;
                    }
                    return variant;
                });
                return colorVariants;
            }
        }
    }
    catch (err) {
        console.log(err);
    }
    return null;
};
var getOtherVariantProduct = function (productDetails) {
    var _a, _b, _c;
    if (checkIsVariantProduct(productDetails)) {
        var variantProduct = (_b = (_a = productDetails === null || productDetails === void 0 ? void 0 : productDetails.definingAttributes) === null || _a === void 0 ? void 0 : _a[0]) === null || _b === void 0 ? void 0 : _b.name;
        if (variantProduct !== 'Color' &&
            variantProduct !== 'DeliveryMethod' &&
            variantProduct !== 'NonBJsDelMethodGC') {
            var otherVariantAtrributes = (_c = productDetails === null || productDetails === void 0 ? void 0 : productDetails.definingAttributes) === null || _c === void 0 ? void 0 : _c[0];
            var otherVariants = otherVariantAtrributes === null || otherVariantAtrributes === void 0 ? void 0 : otherVariantAtrributes.attributeValueDataBeans.map(function (variant) {
                var _a;
                var inventory = productDetails === null || productDetails === void 0 ? void 0 : productDetails.bjsItemsInventory.find(function (d) { return d.itemId === variant.itemId; });
                if (((_a = productDetails === null || productDetails === void 0 ? void 0 : productDetails.itemAvailDetails[variant.itemId]) === null || _a === void 0 ? void 0 : _a.itemAvailableOnline) == 'Y' &&
                    (inventory === null || inventory === void 0 ? void 0 : inventory.availInventory) === true) {
                    variant.isInventoryAvailable = true;
                }
                else {
                    variant.isInventoryAvailable = false;
                }
                return variant;
            }).filter(function (variant) { return variant.itemId !== '0'; });
            var otherVariantsFilt = otherVariants.filter(function (variant) {
                return productDetails.entitledItems.some(function (entItem) {
                    return entItem.itemID === variant.itemId;
                });
            });
            var finalVariantList = uniqBy(otherVariantsFilt, 'name');
            return { name: otherVariantAtrributes === null || otherVariantAtrributes === void 0 ? void 0 : otherVariantAtrributes.name, data: finalVariantList };
        }
    }
    return null;
};
var checkForProductVariant = function (product, filterKey) {
    var productAttributes = product === null || product === void 0 ? void 0 : product.definingAttributes;
    var isVariant = false;
    if (productAttributes && productAttributes.length > 0) {
        productAttributes.forEach(function (attribute) {
            if ((attribute === null || attribute === void 0 ? void 0 : attribute.name) == filterKey) {
                isVariant = true;
            }
        });
    }
    return isVariant;
};
export var checkInventoryForOnlineProduct = function (product, itemId) {
    var _a, _b, _c, _d;
    var itemAvailability = (_a = product === null || product === void 0 ? void 0 : product.otherData) === null || _a === void 0 ? void 0 : _a.itemAvailDetails;
    var isInventoryAvailable = false;
    if (itemAvailability && ((_b = itemAvailability[itemId]) === null || _b === void 0 ? void 0 : _b.itemAvailableOnline) == 'Y') {
        (_d = (_c = product === null || product === void 0 ? void 0 : product.otherData) === null || _c === void 0 ? void 0 : _c.bjsItemsInventory) === null || _d === void 0 ? void 0 : _d.forEach(function (item) {
            if ((item === null || item === void 0 ? void 0 : item.itemId) == itemId) {
                isInventoryAvailable = item === null || item === void 0 ? void 0 : item.availInventory;
                return isInventoryAvailable;
            }
        });
    }
    return isInventoryAvailable;
};
var retrieveEntitleditem = function (itemId, productDetails) {
    var entitledItem = null;
    for (var i = 0; i < productDetails.entitledItems.length; i++) {
        if (productDetails.entitledItems[i].itemID === itemId) {
            entitledItem = productDetails.entitledItems[i];
            break;
        }
    }
    return entitledItem;
};
var sortVariants = function (giftCards) {
    if ((giftCards === null || giftCards === void 0 ? void 0 : giftCards.length) > 0) {
        return giftCards.sort(function (a, b) {
            if (a.price < b.price) {
                return -1;
            }
            if (a.price > b.price) {
                return 1;
            }
            return 0;
        });
    }
    return giftCards;
};
var retrievegiftCardVariants = function (productDetails) {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j;
    var allGiftCardVariants = null;
    if (((_a = productDetails === null || productDetails === void 0 ? void 0 : productDetails.definingAttributes) === null || _a === void 0 ? void 0 : _a.length) > 0) {
        for (var i = 0; i < ((_b = productDetails === null || productDetails === void 0 ? void 0 : productDetails.definingAttributes) === null || _b === void 0 ? void 0 : _b.length); i++) {
            if (((_c = productDetails === null || productDetails === void 0 ? void 0 : productDetails.definingAttributes[i]) === null || _c === void 0 ? void 0 : _c.name) === 'DeliveryMethod') {
                allGiftCardVariants = {
                    digitalGiftCardVariants: [],
                    physicalGiftCardVariants: [],
                    digitalAndPhysicalGiftCardVariants: []
                };
                if ((_d = productDetails === null || productDetails === void 0 ? void 0 : productDetails.definingAttributes[i]) === null || _d === void 0 ? void 0 : _d.attributeValueDataBeans) {
                    var data = (_e = productDetails === null || productDetails === void 0 ? void 0 : productDetails.definingAttributes[i]) === null || _e === void 0 ? void 0 : _e.attributeValueDataBeans;
                    for (var j = 0; j < data.length; j++) {
                        if (((_f = data[j]) === null || _f === void 0 ? void 0 : _f.name) === 'Digital' && ((_g = data[j]) === null || _g === void 0 ? void 0 : _g.itemId) !== '0') {
                            if (productDetails.itemPrices) {
                                if (productDetails.itemPrices[data[j].itemId]) {
                                    var entitledItem = retrieveEntitleditem(data[j].itemId, productDetails);
                                    var partNumber = entitledItem === null || entitledItem === void 0 ? void 0 : entitledItem.partNumber;
                                    var giftCardVariantItemDetail = {
                                        itemId: data[j].itemId,
                                        price: productDetails.itemPrices[data[j].itemId].offerPrice,
                                        isDigital: true,
                                        isPhysical: false,
                                        priceWithCurrency: '$ ' + productDetails.itemPrices[data[j].itemId].offerPrice,
                                        entitleditem: entitledItem,
                                        partNumber: partNumber,
                                        giftCardRequest: null
                                    };
                                    allGiftCardVariants.digitalGiftCardVariants.push(giftCardVariantItemDetail);
                                    allGiftCardVariants.digitalAndPhysicalGiftCardVariants.push(giftCardVariantItemDetail);
                                }
                            }
                        }
                        else if (((_h = data[j]) === null || _h === void 0 ? void 0 : _h.name) === 'Physical' && ((_j = data[j]) === null || _j === void 0 ? void 0 : _j.itemId) !== '0') {
                            if (productDetails.itemPrices) {
                                if (productDetails.itemPrices[data[j].itemId]) {
                                    var entitledItem = retrieveEntitleditem(data[j].itemId, productDetails);
                                    var partNumber = entitledItem === null || entitledItem === void 0 ? void 0 : entitledItem.partNumber;
                                    var giftCardVariantItemDetail = {
                                        itemId: data[j].itemId,
                                        price: productDetails.itemPrices[data[j].itemId].offerPrice,
                                        isDigital: false,
                                        isPhysical: true,
                                        priceWithCurrency: '$ ' + productDetails.itemPrices[data[j].itemId].offerPrice,
                                        entitleditem: entitledItem,
                                        partNumber: partNumber,
                                        giftCardRequest: null
                                    };
                                    allGiftCardVariants.physicalGiftCardVariants.push(giftCardVariantItemDetail);
                                    allGiftCardVariants.digitalAndPhysicalGiftCardVariants.push(giftCardVariantItemDetail);
                                }
                            }
                        }
                    }
                }
                allGiftCardVariants.digitalGiftCardVariants = sortVariants(allGiftCardVariants.digitalGiftCardVariants);
                allGiftCardVariants.physicalGiftCardVariants = sortVariants(allGiftCardVariants.physicalGiftCardVariants);
                allGiftCardVariants.digitalAndPhysicalGiftCardVariants = sortVariants(allGiftCardVariants.digitalAndPhysicalGiftCardVariants);
                //filter out duplicates with same price from digitalAndPhysicalGiftcardvariants
                allGiftCardVariants.digitalAndPhysicalGiftCardVariants = allGiftCardVariants.digitalAndPhysicalGiftCardVariants.filter(function (attributes, index, self) {
                    return index === self.findIndex(function (item) { return item.price === attributes.price; });
                });
            }
        }
    }
    return allGiftCardVariants;
};
export var getBreadCrumb = function (productDetails) {
    var _a;
    return (_a = productDetails === null || productDetails === void 0 ? void 0 : productDetails.breadCrumbDetail) === null || _a === void 0 ? void 0 : _a[0];
};
export var getCatEntryId = function (productDetails) {
    var _a, _b;
    return (_b = (_a = productDetails === null || productDetails === void 0 ? void 0 : productDetails.bjsitems) === null || _a === void 0 ? void 0 : _a[0]) === null || _b === void 0 ? void 0 : _b.itemId;
};
export var getProductImage = function (productDetails) {
    var _a;
    return (_a = productDetails === null || productDetails === void 0 ? void 0 : productDetails.productImages) === null || _a === void 0 ? void 0 : _a.fullImage;
};
export var getProductPartNumber = function (productDetails) {
    return productDetails === null || productDetails === void 0 ? void 0 : productDetails.partNumber;
};
export var getImmediateParentName = function (productDetails) {
    return productDetails === null || productDetails === void 0 ? void 0 : productDetails.immediateParentName;
};
export var getOrderType = function (productDetails) {
    var _a, _b, _c, _d, _e, _f;
    if (((_b = (_a = productDetails === null || productDetails === void 0 ? void 0 : productDetails.itemAvailDetailsELIG) === null || _a === void 0 ? void 0 : _a.Eligibility) === null || _b === void 0 ? void 0 : _b.length) > 0) {
        return ((_f = (_e = (_d = (_c = productDetails === null || productDetails === void 0 ? void 0 : productDetails.itemAvailDetailsELIG) === null || _c === void 0 ? void 0 : _c.Eligibility[0]) === null || _d === void 0 ? void 0 : _d.program) === null || _e === void 0 ? void 0 : _e.fresh) === null || _f === void 0 ? void 0 : _f.Y)
            ? 'Bopic Fresh'
            : undefined;
    }
    return;
};
export var getVariant = function (productDetails) {
    var _a, _b;
    return (_b = (_a = productDetails === null || productDetails === void 0 ? void 0 : productDetails.definingAttributes) === null || _a === void 0 ? void 0 : _a[0]) === null || _b === void 0 ? void 0 : _b.attributeValueDataBeans;
};
export var getProductCatentryId = function (productDetails) {
    var _a, _b, _c;
    if ((productDetails === null || productDetails === void 0 ? void 0 : productDetails.entitledItems) && Array.isArray(productDetails.entitledItems)) {
        return (_c = (_b = (_a = productDetails === null || productDetails === void 0 ? void 0 : productDetails.entitledItems) === null || _a === void 0 ? void 0 : _a[0]) === null || _b === void 0 ? void 0 : _b.description) === null || _c === void 0 ? void 0 : _c.itemId;
    }
};
export var getPrice = function (productDetails) {
    var _a, _b, _c;
    if ((productDetails === null || productDetails === void 0 ? void 0 : productDetails.entitledItems) && Array.isArray(productDetails.entitledItems)) {
        return (_c = (_b = (_a = productDetails === null || productDetails === void 0 ? void 0 : productDetails.entitledItems) === null || _a === void 0 ? void 0 : _a[0]) === null || _b === void 0 ? void 0 : _b.calculatedContractPrice) === null || _c === void 0 ? void 0 : _c.amount;
    }
};
export var getItemDescription = function (productDetails) {
    var _a, _b, _c;
    if ((productDetails === null || productDetails === void 0 ? void 0 : productDetails.entitledItems) && Array.isArray(productDetails.entitledItems)) {
        return (_c = (_b = (_a = productDetails === null || productDetails === void 0 ? void 0 : productDetails.entitledItems) === null || _a === void 0 ? void 0 : _a[0]) === null || _b === void 0 ? void 0 : _b.description) === null || _c === void 0 ? void 0 : _c.longDescription;
    }
};
var checkIfProductIsHeavyItem = function (productDetails) {
    var _a;
    var isProductHeavy = false;
    (_a = productDetails === null || productDetails === void 0 ? void 0 : productDetails.hiddenAttr) === null || _a === void 0 ? void 0 : _a.forEach(function (item) {
        var _a, _b, _c, _d;
        if ((item === null || item === void 0 ? void 0 : item.name) === 'heavyItem') {
            var value = (_d = (_c = (_b = (_a = item === null || item === void 0 ? void 0 : item.attributeValueDataBeans) === null || _a === void 0 ? void 0 : _a[0]) === null || _b === void 0 ? void 0 : _b.value) === null || _c === void 0 ? void 0 : _c.toLowerCase()) === null || _d === void 0 ? void 0 : _d.trim();
            if (value == 'true') {
                isProductHeavy = true;
            }
        }
    });
    return isProductHeavy;
};
export var formattedProductData = function (productData) {
    var productDetails = productData === null || productData === void 0 ? void 0 : productData.productDetailsData;
    var product = {
        productName: getProductName(productDetails),
        articleId: getItemPartNumberOrArticleId(productDetails),
        description: getItemDescription(productDetails),
        itemPartNumber: getItemPartNumberOrArticleId(productDetails),
        model: getProductModel(productDetails),
        manufacturerPartNumber: getProductModel(productDetails),
        shopBrand: getProductShopBrand(productDetails),
        averageRating: getProductAverageRating(productDetails),
        totalReviews: getProductTotalReviews(productDetails),
        colorVariants: getColorVariantProduct(productDetails),
        otherVariants: getOtherVariantProduct(productDetails),
        isVariant: checkIsVariantProduct(productDetails),
        isGiftCardVariant: checkForProductVariant(productDetails, 'DeliveryMethod'),
        isNonBjsGiftCard: checkForProductVariant(productDetails, 'NonBJsDelMethodGC'),
        giftCardvariants: retrievegiftCardVariants(productDetails),
        breadCrumbDetail: getBreadCrumb(productDetails),
        catEntryId: getCatEntryId(productDetails),
        productImage: getProductImage(productDetails),
        partNumber: getProductPartNumber(productDetails),
        immediateParentName: getImmediateParentName(productDetails),
        orderType: getOrderType(productDetails),
        variant: getVariant(productDetails),
        productCatentryId: getProductCatentryId(productDetails),
        price: getPrice(productDetails),
        otherData: productDetails,
        isProductHeavyItem: checkIfProductIsHeavyItem(productDetails)
    };
    return product;
};
