var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React from 'react';
import serialize from 'serialize-javascript';
import { getProductIdFromPathname } from '../../utils/helper';
import getInitialData from '../../services/server/getInitialData';
import useUpdateEffect from '../../hooks/useUpdateEffect';
import { getPromotionDetails } from '../../services/pdpClient';
import { PdpContext } from './PdpContext';
import { formattedProductData } from '../../utils/format/product';
import { v4 as uuidv4 } from 'uuid';
import { useCookies } from 'react-cookie';
export default function PdpProvider(props) {
    var _a, _b;
    var getVariantItemfromUrl = function () {
        return new URLSearchParams(window.location.search).get('itemId');
    };
    var getVariantFromItemId = function (pData, currentItem) {
        var _a;
        if (pData) {
            if (pData.isGiftCardVariant) {
                return getGiftcardVariant(pData, currentItem);
            }
            else {
                return (_a = pData === null || pData === void 0 ? void 0 : pData.colorVariants) === null || _a === void 0 ? void 0 : _a.find(function (item) { return item.itemId === currentItem; });
            }
        }
        return null;
    };
    var getFormattedData = function (data) {
        if (data) {
            var pData = formattedProductData(data);
            return pData;
        }
        return null;
    };
    var getGiftcardVariant = function (formattedData, currentItem) {
        var _a, _b, _c, _d;
        var selectedGiftCardVariant = null;
        if (formattedData === null || formattedData === void 0 ? void 0 : formattedData.giftCardvariants) {
            if (currentItem) {
                if (formattedData.giftCardvariants.digitalGiftCardVariants) {
                    selectedGiftCardVariant = formattedData.giftCardvariants.digitalGiftCardVariants.find(function (item) { return item.itemId === currentItem; });
                }
                if (selectedGiftCardVariant == null &&
                    formattedData.giftCardvariants.physicalGiftCardVariants) {
                    selectedGiftCardVariant = formattedData.giftCardvariants.physicalGiftCardVariants.find(function (item) { return item.itemId === currentItem; });
                }
            }
            else {
                if (((_c = (_b = (_a = formattedData.giftCardvariants) === null || _a === void 0 ? void 0 : _a.digitalAndPhysicalGiftCardVariants) === null || _b === void 0 ? void 0 : _b[0]) === null || _c === void 0 ? void 0 : _c.price) > 0) {
                    selectedGiftCardVariant =
                        (_d = formattedData.giftCardvariants.digitalAndPhysicalGiftCardVariants) === null || _d === void 0 ? void 0 : _d[0];
                }
            }
        }
        return selectedGiftCardVariant;
    };
    function getAppData() {
        //@ts-ignore
        if (typeof window !== 'undefined' && window.initialPdpData) {
            //@ts-ignore
            var data_1 = window.initialPdpData;
            var formattedData = getFormattedData(data_1);
            //@ts-ignore
            window.formattedData = formattedData;
            data_1.currentItem = getVariantItemfromUrl();
            data_1.currentVariant = getVariantFromItemId(formattedData, data_1.currentItem);
            return data_1;
        }
    }
    function getStoreConfig() {
        if (typeof window !== 'undefined') {
            //@ts-ignore
            var storeConf_1 = window.storeConf;
            return storeConf_1;
        }
    }
    var _c = __read(React.useState([]), 2), promotionData = _c[0], setPromotionData = _c[1];
    var _d = __read(React.useState(true), 2), loading = _d[0], setLoading = _d[1];
    var children = props.children;
    var _e = __read(React.useState(getAppData() || props.data || undefined), 2), data = _e[0], setData = _e[1];
    var _f = __read(useCookies(), 3), cookies = _f[0], setCookie = _f[1], removeCookie = _f[2];
    var uuID = cookies['uuID'];
    var _g = __read(React.useState(getStoreConfig() || props.data || undefined), 2), storeConf = _g[0], setStoreConf = _g[1];
    var getNewSid = function () {
        return uuidv4();
    };
    React.useEffect(function () {
        function initData() {
            return __awaiter(this, void 0, void 0, function () {
                var sessionID, pathname, productId, data_2, formattedData;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            if (!uuID) {
                                uuID = getNewSid();
                                setCookie('uuID', uuID, { domain: location === null || location === void 0 ? void 0 : location.hostname });
                            }
                            if (!cookies['SessionID']) {
                                sessionID = getNewSid();
                                setCookie('SessionID', sessionID, { domain: location === null || location === void 0 ? void 0 : location.hostname });
                            }
                            if (!!data) return [3 /*break*/, 2];
                            pathname = window.location.pathname;
                            productId = getProductIdFromPathname(pathname);
                            if (!productId) return [3 /*break*/, 2];
                            return [4 /*yield*/, getInitialData(productId)];
                        case 1:
                            data_2 = _a.sent();
                            formattedData = getFormattedData(data_2);
                            data_2.currentItem = getVariantItemfromUrl();
                            data_2.currentVariant = getVariantFromItemId(formattedData, data_2.currentItem);
                            //data.currentGiftCardVariant = getGiftcardVariant(formattedData, data.currentItem);
                            // setLoading(false)
                            setData(data_2);
                            _a.label = 2;
                        case 2: return [2 /*return*/];
                    }
                });
            });
        }
        initData();
    }, [data]);
    React.useEffect(function () {
        function storeInitData() {
            return __awaiter(this, void 0, void 0, function () {
                return __generator(this, function (_a) {
                    if (!storeConf) {
                        setStoreConf(storeConf);
                    }
                    return [2 /*return*/];
                });
            });
        }
        storeInitData();
    }, [storeConf]);
    var setCurrentVariant = function (variant) {
        setData(function (data) { return (__assign(__assign({}, data), { currentVariant: variant })); });
    };
    var setCurrentGiftCardVariant = function (variant) {
        setData(function (data) { return (__assign(__assign({}, data), { currentGiftCardVariant: variant })); });
    };
    var productDetails = data === null || data === void 0 ? void 0 : data.productDetailsData;
    var _h = __read(React.useState((_b = (_a = productDetails === null || productDetails === void 0 ? void 0 : productDetails.bjsitems) === null || _a === void 0 ? void 0 : _a[0]) === null || _b === void 0 ? void 0 : _b.articleId), 2), articleId = _h[0], setArticleId = _h[1];
    var fetchPromotion = function (articleId) {
        getPromotionDetails({ articleId: articleId })
            .then(function (details) {
            (details === null || details === void 0 ? void 0 : details.associatedPromotions)
                ? setPromotionData(details === null || details === void 0 ? void 0 : details.associatedPromotions)
                : setPromotionData([]);
        })
            .catch(function (error) {
            console.log("Promotion Error ", error);
        });
    };
    React.useEffect(function () {
        //APi Call
        fetchPromotion(articleId);
    }, []);
    useUpdateEffect(function () {
        var _a;
        if (data === null || data === void 0 ? void 0 : data.currentVariant) {
            for (var i = 0; i < (productDetails === null || productDetails === void 0 ? void 0 : productDetails.bjsitems.length); i++) {
                if ((productDetails === null || productDetails === void 0 ? void 0 : productDetails.bjsitems[i].itemId) == ((_a = data === null || data === void 0 ? void 0 : data.currentVariant) === null || _a === void 0 ? void 0 : _a.itemId)) {
                    setArticleId(productDetails === null || productDetails === void 0 ? void 0 : productDetails.bjsitems[i].articleId);
                    fetchPromotion(productDetails === null || productDetails === void 0 ? void 0 : productDetails.bjsitems[i].articleId);
                }
            }
        }
    }, [data === null || data === void 0 ? void 0 : data.currentVariant]);
    return (React.createElement(PdpContext.Provider, { value: {
            promotionData: promotionData,
            data: data,
            loading: loading,
            productData: formattedProductData(data),
            storeConf: storeConf,
            helpers: [setCurrentVariant]
        } },
        React.createElement(React.Fragment, null,
            React.createElement("div", { style: { display: 'none' }, id: "pdp-data", dangerouslySetInnerHTML: {
                    __html: "<script>\n        window.initialPdpData=" + serialize(props.data) + "\n        </script>"
                } }),
            children)));
}
