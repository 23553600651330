var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { Button } from 'react-bootstrap';
import styled from 'styled-components';
export var SharedStyledButton = styled(Button)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  font-family: Roboto, Helvetica Neue, Helvetica, Arial, sans-serif;\n  background-color: ", ";\n  border: ", ";\n  color: ", ";\n  height: 40px;\n  font-weight: 700;\n  font-size: 14px;\n  letter-spacing: 0.5px;\n  line-height: 16px;\n  border-radius: 5px;\n  text-transform: uppercase;\n  padding: 0;\n  &:disabled {\n    color: #555;\n    border: 1px solid #ddd !important;\n    background: #ddd;\n    &:hover,\n    &:focus,\n    &:active {\n      color: #555 !important;\n      border: 1px solid #ddd !important;\n      background: #ddd !important;\n    }\n  }\n  &:hover {\n    background-color: ", ";\n    color: ", ";\n  }\n  &:focus,\n  &:active {\n    background-color: ", " !important;\n    box-shadow: none !important;\n    color: ", " !important;\n  }\n"], ["\n  font-family: Roboto, Helvetica Neue, Helvetica, Arial, sans-serif;\n  background-color: ", ";\n  border: ", ";\n  color: ", ";\n  height: 40px;\n  font-weight: 700;\n  font-size: 14px;\n  letter-spacing: 0.5px;\n  line-height: 16px;\n  border-radius: 5px;\n  text-transform: uppercase;\n  padding: 0;\n  &:disabled {\n    color: #555;\n    border: 1px solid #ddd !important;\n    background: #ddd;\n    &:hover,\n    &:focus,\n    &:active {\n      color: #555 !important;\n      border: 1px solid #ddd !important;\n      background: #ddd !important;\n    }\n  }\n  &:hover {\n    background-color: ", ";\n    color: ", ";\n  }\n  &:focus,\n  &:active {\n    background-color: ", " !important;\n    box-shadow: none !important;\n    color: ", " !important;\n  }\n"])), function (props) {
    switch (props.variant) {
        case 'primary':
            return '#c03';
        case 'outline-secondary':
            return 'white';
        case 'dark':
            return '#555';
    }
}, function (props) {
    switch (props.variant) {
        case 'primary':
            return 'none';
        case 'outline-secondary':
            return '1px solid #555';
        case 'dark':
            return 'none';
    }
}, function (props) {
    switch (props.variant) {
        case 'primary':
            return 'white';
        case 'outline-secondary':
            return '#212529';
        case 'dark':
            return 'white';
    }
}, function (props) {
    switch (props.variant) {
        case 'primary':
            return '#a30028';
        case 'outline-secondary':
            return '#ddd';
        case 'dark':
            return '#303030';
    }
}, function (props) {
    switch (props.variant) {
        case 'primary':
            return 'white';
        case 'outline-secondary':
            return '#212529';
        case 'dark':
            return 'white';
    }
}, function (props) {
    switch (props.variant) {
        case 'primary':
            return '#a30028';
        case 'outline-secondary':
            return '#ddd';
        case 'dark':
            return '#303030';
    }
}, function (props) {
    switch (props.variant) {
        case 'primary':
            return 'white';
        case 'outline-secondary':
            return '#212529';
        case 'dark':
            return 'white';
    }
});
var templateObject_1;
